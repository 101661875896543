<template>
  <div class="select-card d-flex flex-column">
    <font-awesome-icon
      id="check-icon"
      icon="check-circle"
    />
    <h5
      id="retirada-text"
      v-text="$t('home.return.success')"
    />
    <p id="warning-price-table">
      <span v-text="$t('home.return.check_out')" />
      <span
        style="margin-left: 10px; text-decoration-line: underline"
        @click="handleShowPriceTable()"
        v-text="$t('home.return.price_table')"
      />
    </p>
    <!-- gif -->
    <div class="d-flex justify-content-center non-clickable">
      <img
        src="@/assets/images/cables/cable_opening_and_reverse.gif"
        alt="cabos na bateria"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'RentSuccess',
  emits: ['scrollToPriceTable'],
  methods: {
    handleShowPriceTable() {
      this.$emit('scrollToPriceTable')
    }
  }
}
</script>

<style scoped lang="scss">
@use '@/main';

#check-icon {
  font-size: 50px;
  color: main.$color-energy-light-blue;
  margin: 30px auto 20px auto;
}

#retirada-text {
  text-align: center;
  margin: 10px auto 30px auto;
}

#warning-price-table {
  display: flex;
  justify-content: center;
}

#app-icon {
  width: 75px;
  margin: 10px;
}

.image {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
