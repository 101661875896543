<template>
  <b-card class="select-card">
    <h3
      class="page-title text-center pt-2"
      v-text="$t('home.demonstration.title')"
    />
    <p
      class="page-text text-center"
      v-html="formatText($t('home.demonstration.text'))"
    />

    <div class="d-flex flex-column justify-content-center align-items-center">
      <div id="machine-container">
        <div id="merchandising">
          <img
            id="qr-code-machine"
            src="@/assets/images/qr_code.png"
            alt="qrcode"
          >
          <img
            id="logo-machine"
            src="@/assets/images/logo.png"
            alt=""
          >
        </div>
        <div class="container-slots">
          <div
            v-for="slot in totalSlots"
            :key="slot"
            class="slot"
            :class="{ 'slot-selected': isSelected(slot) }"
          >
            <img
              v-if="isSelected(slot)"
              class="selected"
              src="@/assets/images/hand_take_battery_2.gif"
              alt="hand"
            >
            <img
              v-else
              src="@/assets/images/slot.png"
              class="img-slot"
              alt="slot"
            >
          </div>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
  export default {
    name: 'TakeDemonstration',
    props: {
      totalSlots: {
        type: Number,
        default: 9
      },
      selectedSlot: {
        type: Number,
        default: 7
      }
    },
    methods: {
      formatText(input) {
        return input.replace(/\b([A-Z]+)\b/g, '<strong>$1</strong>')
      },
      isSelected(slot) {
        return slot == this.selectedSlot
      }
    }
  }
</script>

<style scoped lang="scss">
  @use '@/main.scss' as *;

  #machine-container {
    justify-content: center;
    background-color: transparent;
    background-color: #000;
    border-radius: 10px;
    padding: 30px;
    width: 80%;
  }

  #merchandising {
    margin: 0 10px;
  }

  #qr-code-machine {
    width: 16%;
    margin-right: 10%;
  }

  #logo-machine {
    width: 70%;
    height: auto;
  }

  .container-slots {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    max-width: 1200px;
    margin: 10px auto;
  }

  .slot {
    width: 100%;
    position: relative;
    padding-bottom: 100%;
  }

  .slot img {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  .slot img.selected {
    width: 390%;
    top: -427%;
    z-index: 10;
    position: absolute;
    left: -147%;
  }

  @keyframes blink {
    0%,
    100% {
      background-color: #2388e6; /* Cor verde quando o LED está ligado */
    }
    50% {
      background-color: #f0f0f0; /* Cor cinza quando o LED está desligado */
    }
  }

  .blink-led {
    animation: blink 0.5s infinite; /* Duração de 1 segundo e repete indefinidamente */
  }
</style>
