import {
  MANAGE_PAYMENT,
  SELECT_CABLE_TYPE,
  TOKEN_SELECTION,
  PRICE_TABLE,
  PIX_REGISTRATION,
  PIX_PAYMENT,
  SELECT_PAYMENT_METHOD,
  CREDIT_CARD_PAYMENT,
  CREDIT_CARD_REGISTRATION
} from '@/constants.js'

export default function getPropsBigComponents(component) {
  switch (component) {
    case PRICE_TABLE:
      return {
        showPriceTable: this.showPriceTable
      }
    case CREDIT_CARD_REGISTRATION:
      return {
        user: this.user,
        showBackPaymentButton: this.showBackPaymentButton
      }
    case PIX_REGISTRATION:
      return {
        user: this.user,
        showBackPaymentButton: this.showBackPaymentButton
      }
    case PIX_PAYMENT:
      return {
        transactionId: this.transactionId,
        user: this.user,
        cableType: this.cableType,
        pointId: this.pointId
      }
    case CREDIT_CARD_PAYMENT:
      return {
        transactionId: this.transactionId,
        user: this.user,
        cableType: this.cableType,
        pointId: this.pointId
      }
    case MANAGE_PAYMENT:
      return {
        user: this.user
      }
    case SELECT_CABLE_TYPE:
      return {
        userId: this.user.id
      }
    case TOKEN_SELECTION:
      return {
        confirmButton: () => this.selectBigBoxComponent(),
        cableType: this.cableType,
        allowEdit: this.editTokenSelection,
        saveInvalidTransaction: this.saveInvalidTransaction,
        token: this.token,
        userId: this.user.id,
        pointName: this.pointName
      }
    case SELECT_PAYMENT_METHOD:
      return {
        pixPrice: this.pixPrice,
        hasPaymentMethod: Boolean((this.user && this.user.pix_identification) || (this.user && this.user.default_card))
      }
    default:
      return console.log('Component not found: ' + component)
  }
}
