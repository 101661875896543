<template>
  <div
    v-if="showPriceTable"
    :class="[
      externalPage
        ? 'not-loged d-flex flex-column'
        : 'select-card d-flex flex-column',
    ]"
  >
    <div class="text-center pt-2">
      <div
        class="page-title mb-3"
        style="font-size: large;"
      >
        <span>
          {{ $t('price_table.title') }}
        </span>
        <span v-if="plans && plans.point_name">
          {{ $t('price_table.title_2') }}: <br> {{ plans.point_name }}
        </span>
      </div>
    </div>
    <div
      v-if="plans"
      class="text-center"
    >
      <div class="info-container">
        <div>{{ $t('price_table.text') }}</div>
        <div
          v-if="campaign"
          class="info-box"
        >
          <span v-text="campaignFreeTimeText()" />
          <span
            style="color: green;"
            v-text="$t('price_table.free')"
          />
        </div>
        <div
          v-if="planHourly"
          class="info-box"
          v-text="planText(planHourly)"
        />
        <div
          v-if="planDaily"
          class="info-box"
          v-text="planText(planDaily)"
        />
        <div class="info-text mb-4 mt-1">
          <span>
            {{ $t('price_table.text_2') }}
          </span>
          <span style="font-weight: 600;">
            R$ {{ planDaily.amount.reais }},{{ planDaily.amount.centavos }}
          </span>
          <span>
            {{ $t('price_table.text_3') }}
          </span>
          <span style="font-weight: 600;">
            R$ {{ maxPlan.amount.reais }},{{ maxPlan.amount.centavos }}.
          </span>
        </div>
      </div>
    </div>
    <div
      v-show="!internalPointId || !plans"
      class="container mb-2 mt-3"
    >
      <p class="text-center small fw-bold">
        {{ $t('price_table.help_text_find_token') }}
      </p>
      <form
        class="text-center"
        @submit.prevent="inputToken"
      >
        <span class="info-text d-inline-block">
          <p class="fs-6 lh-1 text-muted">{{ $t('price_table.help_text_digits') }}</p>
          <input
            id="input-token"
            ref="inputToken"
            v-model.trim="token"
            type="text"
            class="form-control mx-auto mb-3"
            maxlength="4"
            style="width: 200px; font-size: 1rem;text-align: center;"
            :name="TOKEN"
            :placeholder="$t('price_table.insert_token')"
          >
          <button
            type="submit"
            class="btn btn-secondary"
            :disabled="loading"
          >
            {{ $t('price_table.consult') }}
          </button>
          <div
            v-if="loading"
            class="d-flex justify-content-center mt-3"
          >
            <b-spinner
              small
              variant="secondary"
              class="align-self-center"
            />
          </div>
        </span>
      </form>
      <div
        v-if="message"
        class="mt-3 text-center"
      >
        <p :class="messageClass">
          {{ message }}
        </p>
      </div>
    </div>

    <div class="container text-center mt-4">
      <ul
        class="small text-muted observations-list"
        style="list-style-type: none; padding: 0;"
      >
        <li>* {{ $t('price_table.obs1') }}.</li>
        <li>* {{ $t('price_table.obs2') }}.</li>
        <li>* {{ $t('price_table.obs3') }}.</li>
        <li v-if="pix_security_deposit">
          * {{ $t('price_table.text_4') }} R$ {{ pix_security_deposit.reais }},{{
            pix_security_deposit.centavos }} {{ $t('price_table.text_5') }}
        </li>
      </ul>
    </div>
    <div
      key="text"
      class="tile-actions d-flex justify-content-end mt-3 mb-1"
    >
      <BButton
        v-if="internalPointId && allowEdit"
        id="new-consult-price-table"
        class="button my-2 me-2"
        variant="secondary"
        @click="clearToken"
      >
        {{ $t('price_table.new_consult') }}
      </BButton>
      <BButton
        id="dismiss-price-table"
        class="button my-2"
        variant="primary"
        @click="closePriceTable"
      >
        {{ $t('price_table.dismiss') }}
      </BButton>
    </div>
  </div>
</template>
<script>
import '@/main.scss';
import API from '@/services/api'
import getPointByToken from '@/services/getPointName'
import getPlans from '@/views/home/service/getPlans'
import { UserEvent, sessionRecorder } from '@/services/sessionRecorder'
import { TOKEN } from '@/constants.js'

const TAG_CONSULT_PRICE_TABLE = 'consult_price_table'

export default {
  name: 'PriceTable',
  props: {
    externalPage: {
      type: Boolean,
      default: false,
    },
    showPriceTable: {
      type: Boolean,
      default: false,
    },
    pointId: {
      type: Number,
      default: null,
    },
    allowEdit: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'handleTokenSelection'],
  data() {
    return {
      TOKEN,
      plans: null,
      plan_4_hours: null,
      plan_daily: null,
      mobile_api_url: import.meta.env.VITE_MOBILE_API,
      pix_price: null,
      planHourly: null,
      planDaily: null,
      maxPlan: null,
      campaign: null,
      campaignFreeTime: null,
      pix_security_deposit: null,
      price_variation: null,
      loading: false,
      token: null,
      message: '',
      messageClass: '',
      internalPointId: null,
    };
  },
  watch: {
    token(newValue) {
      this.$emit(TOKEN, newValue)
    },
  },
  async created() {
    this.pix_security_deposit = await this.getPixPrice();
  },
  async mounted() {
    this.getPointInfo()
  },
  methods: {
    async getPointInfo () {
      this.internalPointId = this.pointId
      if (this.internalPointId !== null) {
        await this.getPlanInfo()
        return
      }
      this.token = localStorage.getItem('terminal_token')
      if (this.token !== null) {
        const pointResponse = await getPointByToken(this.token)
        if (pointResponse.success) {
          this.internalPointId = pointResponse.id
          await this.getPlanInfo()
        }
        return;
      }
    },
    async getPixPrice() {
      try {
        const response = await API.get('payment/pix-price')
        return response.data.pix_security_deposit.security_deposit
      } catch (error) {
        console.log(error)
      }
    },
    async getPlanInfo() {
      if (this.internalPointId === undefined || this.internalPointId === null) {
        return;
      }

      try {
        this.plans = await getPlans(this.internalPointId);

        if (this.plans && this.internalPointId !== undefined && this.internalPointId !== null) {

          this.planHourly = this.plans.price.find(prices => prices.internal_ref === 'four_hours');
          if (this.planHourly && this.planHourly.price_variation) {
            this.planHourly = this.applyPriceVariation(this.planHourly);
          }

          this.planDaily = this.plans.price.find(prices => prices.internal_ref === 'daily');
          if (this.planDaily && this.planDaily.price_variation) {
            this.planDaily = this.applyPriceVariation(this.planDaily);
          }

          this.maxPlan = this.plans.price.find(prices => prices.internal_ref === 'quarterly');

          if (this.plans.campaign && this.plans.campaign.free_time > 1) {
            this.campaign = this.plans.campaign
            this.campaignFreeTime = this.campaign.free_time / 60
          }

        } else {
          this.planHourly = this.plans.on_demand.prices.find(prices => prices.internal_ref === 'four_hours');
          this.planDaily = this.plans.on_demand.prices.find(prices => prices.internal_ref === 'daily');
        }
      } catch (error) {
        console.error('Erro ao obter informações do plano:', error);
        this.message = 'Ocorreu um erro ao carregar as informações do plano. Tente novamente mais tarde.';
        this.messageClass = 'text-danger';
      }
    },
    campaignFreeTimeText() {
      const acumulatedHours = Math.floor(this.campaignFreeTime / 60)
      const acumulatedMinutes = this.campaignFreeTime % 60
      if (acumulatedMinutes === 0) {
        return `${this.$t("price_table.until")} ${acumulatedHours} ${this.$t('price_table.hours')}: `
      } else if (acumulatedHours === 0) {
        return `${this.$t("price_table.until")} ${acumulatedMinutes} minutos: `
      } else {
        return `${this.$t("price_table.until")} ${acumulatedHours} ${this.$t('price_table.hours')} e ${acumulatedMinutes} minutos: `
      }
    },
    planText(planTime) {
      const value = (parseInt(planTime.amount.reais) + parseInt(planTime.amount.centavos) / 100).toLocaleString("pt-BR", {style: 'currency', currency: 'BRL' })
      if (this.campaign) {
        const time = this.acumulatedTime(planTime.hours, this.campaignFreeTime)

        if (time.minutes === 0) {
          return `${ this.$t("price_table.until") } ${time.hours} ${ this.$t('price_table.hours') }: ${value}`
        } else {
          return `${ this.$t("price_table.until") } ${time.hours} ${ this.$t('price_table.hours') } e ${time.minutes} minutos: ${value}`
        }
      }

      return `${ this.$t("price_table.until") } ${planTime.hours } ${ this.$t('price_table.hours') }: ${value}`
    },
    acumulatedTime(hours, campaignFreeTime) {
      const acumulatedHours = Math.floor(campaignFreeTime / 60) + hours
      const acumulatedMinutes = campaignFreeTime % 60
      return {'hours': acumulatedHours, 'minutes': acumulatedMinutes}
    },
    clearToken() {
      this.internalPointId = null;
      this.token = null;
      this.plans = null;

      this.$nextTick(() => {
        if (this.$refs.inputToken) {
          this.$refs.inputToken.focus();
        }
      });
    },
    cleanValues() {
      this.plans = null;
      this.plan_4_hours = null;
      this.plan_daily = null;
      this.pix_price = null;
      this.planHourly = null;
      this.planDaily = null;
      this.maxPlan = null;
      this.campaign = null;
      this.campaignFreeTime = null;
      this.price_variation = null;
    },
    async inputToken() {
      this.cleanValues()
      this.message = '';
      this.messageClass = '';
      sessionRecorder.addUserEvent(new UserEvent(
        TAG_CONSULT_PRICE_TABLE, UserEvent.point_id, UserEvent.transaction_id, { TOKEN: this.token }
      ))
      if (!this.token || this.token.trim() === '') {
        this.message = 'Por favor, insira um token válido.';
        this.messageClass = 'text-danger';
        return;
      }
      try {
        this.loading = true;
        const pointResponse = await getPointByToken(this.token);
        if (!pointResponse.success) {
          this.message = this.$t('price_table.token_not_found');
          this.messageClass = 'text-danger';
          return;
        }
        if (pointResponse.success) {
          this.internalPointId = pointResponse.id;
          localStorage.setItem('terminal_token', this.token);
          await this.getPlanInfo();
        } else {
          this.message = 'Token inválido ou não encontrado.';
          this.messageClass = 'text-danger';
        }
      } catch (error) {
        console.error('Erro ao consultar token:', error);
        this.message = 'Ocorreu um erro ao consultar o token. Tente novamente mais tarde.';
        this.messageClass = 'text-danger';
      } finally {
        this.loading = false;
      }
    },
    closePriceTable() {
      this.$emit('close');
    },
    handleTokenSelection() {
      this.$emit('close');
      this.$emit('handleTokenSelection');
    },
    applyPriceVariation(price) {
      const variationFactor = 1 + price.price_variation / 100;
      const totalCentavos = Math.round(
        (parseInt(price.amount.reais) * 100 + parseInt(price.amount.centavos)) * variationFactor
      );
      price.amount.reais = Math.floor(totalCentavos / 100);
      price.amount.centavos = totalCentavos % 100;
      return price;
    },
  },
};
</script>
<style scoped lang="scss">
@use '@/main';

.stay-same-line {
  overflow: hidden;
  white-space: nowrap;
}

.amount-description-color {
  color: main.$color-energy-orange;
  display: inline-block;
}

.footnote {
  font-size: 12px;
  margin-top: 13px;
}

.info-container {
  margin: 5px 50px;
}

.info-box {
  font-weight: 600;
  font-size: large;
}

.info-text {
  font-size: medium;
}

.observations-list {
  font-size: 0.65em;
}
</style>