import API from '@/services/api'
import axios from 'axios'

export default async function getPlans(pointId) {
  try {

    let response = null
    if (pointId && localStorage.getItem('token')) {
      response = await API.get(import.meta.env.VITE_MOBILE_API + `plans?point_id=${pointId}`)
    } else {
      response = await axios.get(import.meta.env.VITE_MOBILE_API + `plan?point_id=${pointId}`)
    }
    
    if (response.data) {
      return response.data
    }
  } catch (error) {
    console.log(error)
  }
}