<template>
  <div>
    <b-card
      class="select-card"
      :class="{
        'border-danger margin-error': success_val === false
      }"
    >
      <!-- Image transition -->
      <div
        key="image"
        class="d-flex flex-column justify-content-center align-items-center"
      >
        <p
          class="page-title h4 text-uppercase"
          v-text="$t('cable_type.connector')"
        />
      </div>
      <!-- Image transition -->
      <BContainer
        key="text"
        class="bv-example-row mt-2"
      >
        <BRow>
          <BCol
            cols="4"
            class="cable-type"
          >
            <img
              id="cable_lightning_button"
              class="cable-img"
              :class="{
                'blinking-border': cableTypeSelected === CABLE_TYPE_LIGHTNING,
              }"
              src="@/assets/images/cables/apple_2.png"
              alt="imagem conector Apple Lightning"
              @click="selectCable(CABLE_TYPE_LIGHTNING)"
            >
            <div class="h6 page-text">
              Apple Lightning
            </div>
          </BCol> 
          <BCol
            cols="4"
            class="cable-type"
          >
            <img
              id="cable_micro_usb_button"
              class="cable-img"
              :class="{
                'blinking-border': cableTypeSelected === CABLE_TYPE_MICRO
              }"
              src="@/assets/images/cables/micro_2.png"
              alt="imagem conector Micro USB"
              @click="selectCable(CABLE_TYPE_MICRO)"
            >
            <div class="h6 page-text">
              Micro USB
            </div>
          </BCol>
          <BCol
            cols="4"
            class="cable-type"
          >
            <img
              id="cable_type_c_button"
              class="cable-img"
              :class="{ 
                'blinking-border': cableTypeSelected === CABLE_TYPE_USBC
              }"
              src="@/assets/images/cables/c_2.png"
              alt="imagem conector USB tipo C"
              @click="selectCable(CABLE_TYPE_USBC)"
            >
            <div class="h6 page-text">
              <div>USB-C</div>
              <div>*iPhone 16</div>
            </div>
          </BCol>
        </BRow>
      </BContainer>
      <div
        key="image"
        class="d-flex flex-column justify-content-center align-items-center  mt-4 mb-1 font-size-12"
      >
        <p
          class="page-text fw-normal"
          v-text="$t('cable_type.subtitle')"
        />
      </div>
      <!-- gif -->
      <div class="d-flex justify-content-center non-clickable">
        <img
          src="@/assets/images/cables/cable_opening_and_reverse.gif"
          alt="cabos na bateria"
        >
      </div>
        
      <BRow v-if="!success_val">
        <BCol>
          <div class="message-fail">
            <h5>{{ message }}</h5>
          </div>
        </BCol>
      </BRow>
    </b-card>
  </div>
</template>

<script>
import { CABLE_TYPE_LIGHTNING, CABLE_TYPE_MICRO, CABLE_TYPE_USBC, CABLE_TYPE } from '@/constants.js'
import sendGtag from '@/services/sendGtags'
import { sessionRecorder, UserEvent } from '@/services/sessionRecorder'


const GTAG_CABLE_TYPE = 'cable_type_selection'

const APPLE_LIGHTNING_MICRO_USB = 0
const APPLE_LIGHTNING_USB_C = 1

export default {
  name: 'SelectCableType',
  props: {
    userId: {
      type: Number,
      default: null
    }
  },
  emits: [CABLE_TYPE],
  data() {
    return {
      CABLE_TYPE,
      message: '',
      success_val: true,
      cableTypeSelected: null,
      isBlinking: false,
      CABLE_TYPE_LIGHTNING,
      CABLE_TYPE_MICRO,
      CABLE_TYPE_USBC
    }
  },
  methods: {
    selectCable(cable) {    
      this.cableTypeSelected = cable  
      let params = {
          cable_type: cable,
          user_id: this.userId
        }
      sessionRecorder.addUserEvent(new UserEvent(GTAG_CABLE_TYPE, null, null, params))
      sendGtag(GTAG_CABLE_TYPE, params)
      setTimeout(() => {
        this.$emit(CABLE_TYPE, cable);
      }, 1000);
    }
  }
}
</script>

<style scoped lang="scss">
@use '@/main';

.cable-img {
  padding: 3px;
  width: 100%;
  margin-bottom: 20px;
  opacity: 0.70;
}

.cable-type {
  text-align: center !important;
  font-size: 12px;
}

.blinking-border {
  border: 2px solid transparent;
  animation: blink 0.25s 2.5;
}

.transparent {
  opacity: 0.3;
}


@keyframes blink {
  0% {
    opacity: 0.7;
  }

  50% {
    opacity: 0.1;
  }

  100% {
    opacity: 0.7;
  }
}
.margin-error {
  margin-bottom: 35px !important;
  padding-bottom: 35px !important;
}

.message-fail {
  width: 100%;
  padding: 0.2rem 1rem;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: left;
  line-height: 1;
  box-sizing: border-box;
  margin-top: 0.5rem;
  max-height: 2rem;
  overflow: hidden;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: red;
  border-radius: 0 0 5px 5px;
}

.non-clickable {
  opacity: 0.9;
  cursor: default;
}
</style>
