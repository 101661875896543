<template>
  <div class="mytabs">
    <div />
    <div class="select-card card-title">
      <a
        class="btn-close-history"
        @click="$router.go(-1)"
      >
        <font-awesome-icon icon="fa-solid fa-xmark" />
      </a>
      <div
        class="title"
        v-text="$t('history.rent')"
      />
    </div>
    <div>
      <div class="text-center">
        <BSpinner
          v-if="spinner"
          id="loading-spinner"
        />
      </div>
      <div
        v-for="result in results"
        :key="result.id"
        class="select-card subscribe-margin"
      >
        <TransactionInfo
          :result="result"
          :show-transaction-rating="true"
        />
      </div>
    </div>
    <div class="text-center">
      <b-button
        v-if="next_page"
        variant="primary"
        @click="get_history"
      >
        <div v-text="$t('history.load_more')" />
      </b-button>
    </div>
  </div>
</template>

<script>

import API from '@/services/api'
import sendGtag from '@/services/sendGtags'
import handleError from '@/services/handleError'
import { sessionRecorder, UserEvent } from '@/services/sessionRecorder'

const GTAG_HISTORY = 'history'
import TransactionInfo from '@/components/TransactionInfo.vue'

export default {
  name: 'History',
  components: {
    TransactionInfo
  },
  props: [],
  data() {
    return {
      results: [],
      next_page: null,
      spinner: true
    }
  },
  mounted: async function () {
    let c = sessionStorage.getItem('campaign')
    if (c) {
      this.campaign = c
    }
    sessionRecorder.addUserEvent(new UserEvent(GTAG_HISTORY))
    sendGtag(GTAG_HISTORY, {}, true)

    await this.get_history()
  },
  methods: {

    padTo2Digits: function (num) {
      return num.toString().padStart(2, '0')
    },
    formatDate: function (date) {
      return (
        [
          this.padTo2Digits(date.getDate()),
          date.toLocaleString('default', { month: 'short' }),
          date.getFullYear()
        ].join(' de ') +
        ' ' +
        [
          this.padTo2Digits(date.getHours()),
          this.padTo2Digits(date.getMinutes())
        ].join(':')
      )
    },
    get_history: async function async() {
      let url = 'transaction/history'
      if (this.results.length > 0 && this.next_page) {
        url = this.next_page
      }

      try {
        const response = await API.get(url)

        this.history_a = response.data
        this.next_page = this.history_a.meta.next_page
        let results_temp = this.history_a.results
        if (results_temp && results_temp.length > 0) {
          let results = this.modify_api_response(results_temp)
          if (this.results.length > 0 && this.next_page) {
            this.results.push(...results)
          } else {
            this.results = results
          }
        }
        this.spinner = false

      } catch (error) {
        handleError(error)
      }
    },
    modify_api_response: function (arr) {
      arr.forEach((element) => {
        element.take_date = this.formatDate(new Date(element.take_time * 1000))
        if (element.plan_name == '') {
          element.no_plan = true
        }
        if (element.return_time === 0) {
          element.elapsed_time = 'DEVOLUÇÃO PENDENTE'
        } else {
          element.return_date = this.formatDate(new Date(element.return_time * 1000))
          var time = element.elapsed_time
          var hours = Math.floor(time / 3600)
          var min = Math.floor((time - hours * 3600) / 60)

          var hours_pad = this.padTo2Digits(hours)
          var min_pad = this.padTo2Digits(min)

          var hours_str = hours_pad != '00' ? hours_pad + ' h ' + 'e ' : ''
          var min_str = min_pad != '00' ? min_pad + ' min' + ' e ' : ''
          element.elapsed_time = hours_str + min_str
        }
      })
      return arr
      }
    }
  }
</script>

<style scoped lang="scss">
@use '@/main';

.card-title {
  margin: auto;
  margin-bottom: 5px !important;
  margin-top: 10px !important;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.title {
  font-size: 24px;
  font-weight: 500;
  margin: 0 auto;
  flex: 1.6;
}

.btn-close-history {
  color: #666;
  font-size: 30px;
  z-index: 99999;
  cursor: pointer;
  margin-left: 10px;
  flex: 1;
}

#loading-spinner {
  color: main.$color-energy-orange !important;
}

.mytabs {
  overflow: scroll;
}

.subscribe-margin {
  margin-bottom: 15px !important;
}
</style>
