import { MANAGE_PAYMENT, SELECT_CABLE_TYPE, TOKEN_SELECTION, PIX_REGISTRATION, PIX_PAYMENT, SELECT_PAYMENT_METHOD, CREDIT_CARD_PAYMENT, CREDIT_CARD_REGISTRATION } from '@/constants'

export default function getEventsBigComponents(component) {
  switch (component) {
    case CREDIT_CARD_REGISTRATION:
      return {
        user: (user) => {
          this.user = user
          this.selectBigBoxComponent()
        },
        back: () => {
          if (this.withPaymentScreen) {
            this.selectBigBoxComponent()
          } else {
            this.selectBigBoxComponent(SELECT_PAYMENT_METHOD)
          }
        },
        pixRegistration: () => {
          this.selectBigBoxComponent(PIX_REGISTRATION)
        }
      }
    case PIX_REGISTRATION:
      return {
        user: (user) => {
          this.user = user
          this.selectBigBoxComponent()
        },
        back: () => {
          if (this.withPaymentScreen) {
            this.selectBigBoxComponent()
          } else {
            this.selectBigBoxComponent(SELECT_PAYMENT_METHOD)
          }
        },
        creditRegistration: () => {
          this.selectBigBoxComponent(CREDIT_CARD_PAYMENT)
        }
      }
    case PIX_PAYMENT:
      return {
        cancelToPaymentMethod: async (reason) => {
          if (this.transactionId) {
            await this.saveInvalidTransaction(
              reason, this.transactionId, this.token, false
            )
          }
          this.selectBigBoxComponent(SELECT_PAYMENT_METHOD)
        },
        cancel: async (reason) => {
          if (this.transactionId) {
            await this.saveInvalidTransaction(
              reason, this.transactionId, this.token, true
            )
          }
          this.selectBigBoxComponent()
        },
        handleShowPriceTable: () => {
          this.scrollToPriceTable()
        },
        pixPayed: async (campaignOptInStatus) => {
          this.pixPayed = true
          this.campaignOptInStatus = campaignOptInStatus
          this.selectBigBoxComponent()
        }
      }
    case CREDIT_CARD_PAYMENT:
      return {
        cancelToPaymentMethod: async (reason) => {
          if (this.transactionId) {
            await this.saveInvalidTransaction(
              reason, this.transactionId, this.token, false
            )
          }
          this.selectBigBoxComponent(SELECT_PAYMENT_METHOD)
        },
        cancel: async (reason) => {
          if (this.transactionId) {
            await this.saveInvalidTransaction(
              reason, this.transactionId, this.token, true
            )
          }
          this.selectBigBoxComponent()
        },
        handleShowPriceTable: () => {
          this.scrollToPriceTable()
        },
        confirmRentBattery: async (campaignOptInStatus) => {
          this.campaignOptInStatus = campaignOptInStatus
          await this.sendPermission()
        }
      }
    case MANAGE_PAYMENT:
      return {
        addNewPaymentMethod: () => {
          this.showManagePayment = false
          if (this.withPaymentScreen) {
            this.scrollToRef(CREDIT_CARD_PAYMENT)
            this.selectBigBoxComponent(CREDIT_CARD_PAYMENT)
          } else {
            this.scrollToRef(SELECT_PAYMENT_METHOD)
            this.selectBigBoxComponent(SELECT_PAYMENT_METHOD)
          }
        },
        user: (user) => this.user = user,
        close: () => {
          this.showManagePayment = false
          this.scrollToRef()
          this.selectBigBoxComponent()
        }
      }
    case SELECT_CABLE_TYPE:
      return {
        cableType: (cableType) => {
          this.cableType = cableType
          this.selectBigBoxComponent()
        },
      }
    case TOKEN_SELECTION:
      return {
        token: (token) => (this.token = token),
        transactionId: (transactionId) => {
          this.transactionId = transactionId
        },
        pointName: (pointName) => {
          this.pointName = pointName
        },
        pointId: (pointId) => {
          this.pointId = pointId
        },
        takeType: (takeType) => {
          this.takeType = takeType
        },
        handleShowPriceTable: () => {
          this.scrollToPriceTable()
        },
        closePriceTable: () => {
          this.scrollToPriceTable(true)
        },
        closeManagePayment: () => this.showManagePayment = false,
        allowEdit: (allowEdit) => {
          this.allowEdit = allowEdit
        }
      }
    case 'demonstration':
      return {
        handleShowDemonstration: (totalSlots, selectedSlot) => {
          this.totalSlots = totalSlots
          this.selectedSlot = selectedSlot
        }
      }
    case SELECT_PAYMENT_METHOD:
      return {
        pixLink: () => this.selectBigBoxComponent(PIX_REGISTRATION),
        creditCardLink: () => this.selectBigBoxComponent(CREDIT_CARD_PAYMENT),
        back: () => this.selectBigBoxComponent(),
        handleShowPriceTable: () => {
          this.scrollToPriceTable()
        },
        paymentMethodRegistration: () => {
          this.scrollToRef(SELECT_PAYMENT_METHOD)
          this.showManagePayment = !this.showManagePayment
        }
      }
    default:
      return console.log('Component not found')
  }
}
