import UAParser from 'ua-parser-js'
import API from '@/services/api'
import { VERSION } from '@/config'
import * as Sentry from '@sentry/vue'

export default async function sendDeviceInfo(fingerPrintUUID, cableType) {
  let parser = null
  let manufacturer = null
  let model = null
  let browser = null
  let browserVersion = null
  try {
    parser = new UAParser(navigator.userAgent || navigator.vendor || window.opera)
  } catch (error) {
    console.log(error)
  }

  if (parser.getDevice().vendor) {
    manufacturer = parser.getDevice().vendor
  }
  if (parser.getDevice() && parser.getDevice().model) {
    model = parser.getDevice().model
  }

  if (parser.getBrowser() && parser.getBrowser().name && parser.getBrowser().version) {
    browser = parser.getBrowser().name
    browserVersion = parser.getBrowser().version
  }

  try {
    const params = {
      device_id: fingerPrintUUID,
      manufacturer: manufacturer,
      model: model,
      platform: browser,
      platform_version: browserVersion,
      app_version: VERSION,
      carrier_name: null,
      country_code: null,
      phone_number: null,
      sim_serial_number: null,
      cable_type: cableType
    }
    await API.post('customer/device_info', params)
  } catch (error) {
    Sentry.captureException(error)
    console.log(error)
  }
}



