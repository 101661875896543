import { MANAGE_PAYMENT, CABLE_TYPE, TOKEN } from '@/constants.js'

export default function getEventsMiniComponents(component) {
  switch (component) {
    case CABLE_TYPE:
      return {
        clicked: () => {
          this.cableType = null
          sessionStorage.removeItem(CABLE_TYPE)
          this.closePBox()
          return this.selectBigBoxComponent()
        }
      }
    case TOKEN:
      return {
        clicked: () => {
          this.pointName = null
          this.token = null
          this.closePBox()
          localStorage.removeItem('terminal_token')
          return this.selectBigBoxComponent()
        }
      }
    case MANAGE_PAYMENT:
      return {
        clicked: () => {
          this.scrollToRef(MANAGE_PAYMENT)
          this.showManagePayment = !this.showManagePayment
        }
      }
    default:
      return console.log('Component not found')
  }
}
