<!-- eslint-disable vue/valid-v-slot -->
<template>
  <div
    id="home-master-div"
    :style="{ background: 'white', color: '#424242' }"
  >
    <div
      v-if="user && lastTransaction && lastTransaction.status === 'take_battery_success'"
      class="default-container"
    >
      <div class="default-container-start">
        <RentSuccess
          style="margin-bottom: 10px"
          @scroll-to-price-table="scrollToPriceTable"
        />
        <RentCheckout
          :user="user"
          :last-transaction="lastTransaction"
          :campaign="campaign"
        />
      </div>

      <div class="default-container-end">
        <div
          class="adjust-width"
        >
          <UserDefaults
            :sub-text="$t('login.find_us')"
            :arrow-down="true"
            @clicked="$router.push({ name: 'maps' })"
          >
            <template #left-content>
              <font-awesome-icon
                class="fa-2x"
                icon="map-location-dot"
              />
            </template>
          </UserDefaults>
        </div>
        <UserDefaults
          v-if="!showPriceTable"
          :sub-text="$t('home.take.price_table')"
          :arrow-down="!showPriceTable"
          @clicked="scrollToPriceTable"
        >
          <template #left-content>
            <font-awesome-icon
              class="fa-2x"
              icon="money-bill"
            />
          </template>
        </UserDefaults>
        <div :ref="PRICE_TABLE">
          <PriceTable
            v-if="showPriceTable"
            class="select-card"
            :show-price-table="showPriceTable"
            :point-id="pointId"
            @close="scrollToPriceTable"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { MINI_BOX_COMPONENT_LIST, PRICE_TABLE } from '@/constants.js'
import RentCheckout from '@/components/RentCheckout.vue'
import RentSuccess from '@/components/RentSuccess.vue'
import PriceTable from '@/components/PriceTable.vue'
import UserDefaults from '@/components/UserDefaults.vue'
import sendGtag from '@/services/sendGtags'
import { sessionRecorder, UserEvent } from '@/services/sessionRecorder'


const GTAG_PRICE_TABLE = 'open_price_table'

export default {
  name: 'CheckOutScreen',
  components: {
    PriceTable,
    UserDefaults,
    RentCheckout,
    RentSuccess
  },
  props: {
    name: {
      type: String,
      default: null
    },
    email: {
      type: String,
      default: null
    },
    user: {
      type: Object,
      default: null
    },
    lastTransaction: {
      type: Object,
      default: null
    },
    cableType: {
      type: Number,
      default: null
    },
    campaign: {
      type: Object,
      default: null
    },
    pointId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      PRICE_TABLE,
      miniBoxComponentList: MINI_BOX_COMPONENT_LIST,
      showPriceTable: false
    }
  },
  methods: {
    getProps (component) {
      switch (component) {
        case PRICE_TABLE:
          return {
            subText: this.$t('home.take.price_table')
          }
      }
    },
    getEvents(component) {
      switch (component) {
        case PRICE_TABLE:
          return {
            clicked: () => {
              return this.scrollToPriceTable()
            }
          }
      }
    },
    scrollToPriceTable() {
      if (!this.showPriceTable) {
        let params = {}
        if (this.user.id) {
          params['user_id'] = this.user.id
        }
        if (this.lastTransaction.id) {
          params['transaction_id'] = this.lastTransaction.id
        }
        params['link_source'] = 'checkout'
        sessionRecorder.addUserEvent(new UserEvent(GTAG_PRICE_TABLE, this.lastTransaction.id, this.pointId))
        sendGtag(GTAG_PRICE_TABLE, params, true)
      } else {
        this.$scrollTo(0, 500, {
          easing: 'ease'
        })
      }

      this.showPriceTable = !this.showPriceTable
      const element = this.$refs[PRICE_TABLE]
      if (element) {
        this.$scrollTo(element, 500, {
          easing: 'ease'
        })
      }
    },
  }
}
</script>

<style lang="scss">

@use '@/main';

#home-master-div {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  padding-bottom: 20px;
}

.adjust-width{
  width: 100%;
}

</style>
