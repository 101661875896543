<template>
  <div class="text-center">
    <div
      key="image"
      class="d-flex flex-column justify-content-center align-items-center"
      style="width: 100%"
    >
      <p
        class="page-title h4 text-uppercase"
        style="font-size: 16px"
        v-text="$t('manage_payment.title')"
      />
      <p
        class="page-text fw-normal"
        style="font-size: 14px"
        v-text="$t('manage_payment.subtitle')"
      />
    </div>

    <div
      v-if="isPixPaymentAvailable"
      class="flex-row"
      :class="defaultPayment == PIX_DEFAULT_PAYMENT ? 'selected' : ''"
    >
      <label
        class="width-85 flex-row m-2"
        :class="{
          'border-orange': selectedPix,
          unclickable: removePix
        }"
      >
        <input
          v-if="!removePix"
          v-model="defaultPayment"
          class="mx-3"
          type="radio"
          name="payment"
          value="0"
          style="transform: scale(1.2)"
          :disabled="waitRequest"
        >
        <div
          class="font-large width-25"
          :class="{
            'display-none': removePix
          }"
        >
          <font-awesome-icon icon="fa-brands fa-pix" />
        </div>
        <div
          v-if="!removePix"
          class="text-left width-60"
        >
          <div>{{ newUser.pix_identification.name }}</div>
          <div>
            {{ newUser.pix_identification.cpf_or_cnpj.length > 11 ? 'CNPJ' : 'CPF' }}:
            {{ formatCNPJorCPF(newUser.pix_identification.cpf_or_cnpj) }}
          </div>
        </div>

        <div
          v-if="removePix && !confirmRemove"
          class="text-left width-50 text-red"
        >
          <div
            class="text-center"
            v-text="$t('manage_payment.confirm_remove')"
          />
        </div>

        <div
          v-if="confirmRemove"
          class="text-left width-60 text-green"
          v-text="$t('manage_payment.removing')"
        />
      </label>
      <div
        v-if="!removePix"
        class="font-medium width-15"
        @click="removePixIdentification()"
      >
        <font-awesome-icon icon="fa-solid fa-trash" />
      </div>

      <div
        v-if="removePix && !confirmRemove"
        class="button-group width-30"
      >
        <div
          class="width-15"
          @click="confirmRemovePixIdentification()"
        >
          <font-awesome-icon
            style="font-size: 40px"
            icon="fa-solid fa-check"
          />
        </div>
        <div
          class="width-15"
          @click="removePixIdentification()"
        >
          <font-awesome-icon
            style="font-size: 40px"
            icon="fa-solid fa-xmark"
          />
        </div>
      </div>
    </div>

    <div v-if="isCreditCardAvailable">
      <div
        v-for="credit_card in newUser.credit_cards"
        :key="credit_card.id"
      >
        <div
          class="flex-row flex-direction-column justify-content-center align-items-center"
          :class="defaultPayment === credit_card.id ? 'selected' : ''"
        >
          <label class="d-flex width-85 m-2">
            <input
              v-if="!isMarkedToRemove(credit_card) && !confirmedPaymentToRemove(credit_card)"
              v-model="defaultPayment"
              class="mx-3"
              type="radio"
              name="payment"
              :value="credit_card.id"
              style="transform: scale(1.2)"
              :disabled="waitRequest"
            >
            <div class="font-large width-25">
              <font-awesome-icon
                class="fa-xl btn-pix"
                :class="{
                  'display-none': isMarkedToRemove(credit_card) || confirmedPaymentToRemove(credit_card)
                }"
                icon="fa-credit-card"
              />
            </div>
            <div
              v-if="notDefaultPayment(credit_card) && notConfirmedPaymentToRemove(credit_card)"
              class="text-left width-60"
            >
              <div>{{ credit_card.flag.toUpperCase() }}</div>
              <div>final: {{ credit_card.number }}</div>
            </div>
            <div
              v-if="isMarkedToRemove(credit_card)"
              class="text-right width-45 text-red"
              v-text="$t('manage_payment.confirm_remove')"
            />
            <div
              v-if="confirmedPaymentToRemove(credit_card)"
              class="text-left width-60 text-green"
            >
              <div v-text="$t('manage_payment.removing')" />
            </div>
          </label>

          <div
            v-if="notDefaultPayment(credit_card)"
            class="font-medium width-15"
          >
            <font-awesome-icon
              v-if="notConfirmedPaymentToRemove(credit_card)"
              :class="{
                'display-none': defaultPayment === credit_card.id && user.credit_cards.length > 1
              }"
              icon="fa-solid fa-trash"
              @click="removeCrediCardIdentification(credit_card.id)"
            />
          </div>
          <div
            v-else-if="isMarkedToRemove(credit_card)"
            class="button-group width-30"
          >
            <div
              class="width-15"
              @click="confirmRemoveCreditCard(credit_card.id)"
            >
              <font-awesome-icon
                class="font-large"
                icon="fa-solid fa-check"
              />
            </div>
            <div
              class="width-15"
              @click="cancelRemoveCreditCard()"
            >
              <font-awesome-icon
                class="font-large"
                icon="fa-solid fa-xmark"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      key="text"
      class="tile-actions d-flex justify-content-end mt-3"
    >
      <BButton
        id="add-payment-method"
        class="button my-2 mx-2"
        variant="secondary"
        @click="addNewPaymentMethod"
      >
        ADICIONAR
      </BButton>
      <BButton
        id="dismiss-payment"
        class="button my-2"
        variant="primary"
        @click="close"
      >
        {{ $t('manage_payment.dismiss') }}
      </BButton>
    </div>
  </div>
</template>

<script>
  import defaultCard from '@/views/home/service/defaultCard'
  import deleteCard from '@/views/home/service/deleteCard'
  import deletePixIdentification from '@/views/home/service/deletePixIdentification'
  import handleError from '@/services/handleError'

  const PIX_DEFAULT_PAYMENT = 0

  export default {
    name: 'ManagePayment',
    props: {
      user: {
        type: Object,
        required: true
      }
    },
    emits: ['user', 'close', 'addNewPaymentMethod'],
    data() {
      return {
        newUser: null,
        defaultPayment: null,
        PIX_DEFAULT_PAYMENT,
        removePix: false,
        confirmRemove: false,
        removeCreditCard: null,
        removeCreditCardConfirmed: null,
        initialDefaultPayment: null,
        waitRequest: false
      }
    },
    computed: {
      selectedPix() {
        return this.defaultPayment === PIX_DEFAULT_PAYMENT && !this.removePix
      },
      isPixPaymentAvailable() {
        return this.newUser && this.newUser.pix_identification
      },
      isCreditCardAvailable() {
        return this.newUser && this.newUser.credit_cards && this.newUser.credit_cards.length > 0
      },
      notDefaultPayment() {
        return credit_card => this.removeCreditCard !== credit_card.id
      },
      isDefaultPayment() {
        return credit_card => this.defaultPayment === credit_card.id
      },
      notConfirmedPaymentToRemove() {
        return credit_card => this.removeCreditCardConfirmed !== credit_card.id
      },
      confirmedPaymentToRemove() {
        return credit_card => this.removeCreditCardConfirmed === credit_card.id
      },
      isMarkedToRemove() {
        return credit_card => this.removeCreditCard === credit_card.id
      }
    },
    watch: {
      async defaultPayment(newValue, oldValue) {
        if (oldValue === null || newValue === oldValue) {
          this.waitRequest = false
          return
        }
        this.waitRequest = true
        try {
          this.newUser = await defaultCard(this.newUser, newValue)
          if (this.newUser) {
            this.$emit('user', this.newUser)
          }
        } catch (error) {
          handleError(error)
        } finally {
          this.waitRequest = false
        }
      }
    },
    async mounted() {
      this.newUser = await this.defineDefaultIfNotDefined(this.user)
      if (this.newUser && this.newUser.default_card && this.newUser.default_card.id) {
        this.defaultPayment = this.newUser.default_card.id
      } else if (this.newUser && this.newUser.pix_identification) {
        this.defaultPayment = parseInt(PIX_DEFAULT_PAYMENT)
      }
      this.initialDefaultPayment = this.defaultPayment
    },
    methods: {
      addNewPaymentMethod() {
        this.$emit('addNewPaymentMethod')
      },
      removeCrediCardIdentification(card_id) {
        this.removeCreditCard = card_id
      },
      async close() {
        this.$emit('close')
      },
      removePixIdentification() {
        this.removePix = !this.removePix
      },
      async confirmRemovePixIdentification() {
        this.confirmRemove = true
        setTimeout(async () => {
          this.removePix = false
          const tempUser = await deletePixIdentification(this.newUser)
          if (tempUser) {
            this.confirmRemove = false
            this.newUser = await this.defineDefaultIfNotDefined(tempUser)
            this.$emit('user', this.newUser)
          } else {
            this.confirmRemove = false
          }
        }, 2000)
      },
      async confirmRemoveCreditCard(id) {
        this.removeCreditCardConfirmed = id
        this.removeCreditCard = null

        setTimeout(async () => {
          const updatedUser = await deleteCard(this.newUser, id)
          if (updatedUser) {
            this.newUser = await this.defineDefaultIfNotDefined(updatedUser)
            this.$emit('user', this.newUser)
            this.removeCreditCardConfirmed = null
          }
        }, 2000)
      },
      async defineDefaultIfNotDefined(user) {
        if (
          user &&
          !user.pix_identification &&
          !user.default_card &&
          user.credit_cards &&
          user.credit_cards.length > 0
        ) {
          const newUser = await defaultCard(user, user.credit_cards[0].id)
          if (newUser) {
            user.default_card = user.credit_cards[0]
            user.credit_cards[0].default = true
            this.defaultPayment = user.default_card.id
            this.initialDefaultPayment = this.defaultPayment
            return newUser
          }
        } else if (
          user &&
          user.pix_identification &&
          !user.default_card &&
          user.credit_cards &&
          user.credit_cards.length === 0
        ) {
          this.defaultPayment = PIX_DEFAULT_PAYMENT
          this.initialDefaultPayment = this.defaultPayment
        }
        return user
      },
      cancelRemoveCreditCard() {
        this.removeCreditCard = null
      },
      formatCNPJorCPF(value) {
        value = value.toString().replace(/\D/g, '')
        if (value.length === 11) {
          return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.***.***-$4') + ' ' + '(PIX)'
        } else if (value.length === 14) {
          return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.***.***/***-$5') + ' ' + '(PIX)'
        }
        return value
      }
    }
  }
</script>

<style scoped lang="scss">
  @use '@/main';

  .flex-element {
    display: flex;
    justify-content: center;
    flex-direction: row;
    justify-content: space-around;
  }

  .flex-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 80px;
    max-width: 80px;
    font-size: 18px;
  }

  .flex-icon-radio {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 80px;
    max-width: 80px;
    font-size: 24px;
  }

  .flex-description {
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: black;
    width: 40%;
  }

  .line-through {
    text-decoration: line-through;
  }

  .flex-text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: gray;
    font-size: large;
    margin: 0px !important;
    padding: 0px !important;
  }

  .cards-flex {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .radio-invisible {
    opacity: 0;
    position: absolute;
  }

  .btn-pix {
    font-size: 35px;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    align-content: stretch;
  }

  /* Fontes */
  .font-large {
    font-size: 40px;
  }

  .font-medium {
    font-size: 28px;
  }

  /* Largura */
  .width-15 {
    width: 15%;
  }

  .width-25 {
    width: 25%;
  }

  .width-30 {
    width: 30%;
  }

  .width-45 {
    width: 45%;
  }

  .width-50 {
    width: 50%;
  }

  .width-60 {
    width: 60%;
  }

  .width-70 {
    width: 70%;
  }

  .width-85 {
    width: 85%;
  }

  /* Alinhamento de texto */
  .text-left {
    text-align: left;
  }

  .text-right {
    justify-content: flex-end;
    text-align: center;
  }

  .text-red {
    color: red;
  }

  .text-green {
    color: green;
  }

  .button-group {
    display: flex;
    justify-content: space-around;
  }

  .display-none {
    visibility: hidden;
    pointer-events: none;
  }

  .unclickable {
    pointer-events: none;
  }

  .selected {
    border: 2px solid main.$color-energy-orange;
    border-radius: 10px;
  }
</style>
