<template>
  <b-modal
    :ref="PRICE_TABLE"
    hide-header
    hide-footer
    @hide="hidePriceTableModal"
  >
    <PriceTable
      :show-price-table="true"
      :external-page="true"
      @close="hidePriceTableModal"
    />
  </b-modal>
</template>

<script>
import '@/main.scss'

import { sessionRecorder, UserEvent } from '@/services/sessionRecorder'
import { PRICE_TABLE } from '@/constants.js'

const GTAG_PRICE_TABLE = 'open_price_table'

export default {
  name: 'PriceTableModal',
  components: {
    PriceTable: () => import('@/components/PriceTable.vue')
  },
  emits: ['hidePriceTableModal'],
  data() {
    return {
      PRICE_TABLE
    }
  },
  async mounted() {
    this.openPriceTableModal()
  },
  methods: {
    openPriceTableModal() {
      let params = {}
      params['link_source'] = 'not_logged'
      sessionRecorder.addUserEvent(new UserEvent(GTAG_PRICE_TABLE, null, null, params))
      const tryOpenModal = () => {
        try {
          this.$refs.priceTable.show()
        } catch (error) {
          setTimeout(tryOpenModal, 200)
        }
      }
      tryOpenModal()
    },

    hidePriceTableModal() {
      this.$emit('hidePriceTableModal')
    },
  }
}
</script>

<style scoped lang="scss">
@use '@/main';

.stay-same-line {
  overflow: hidden;
  white-space: nowrap;
}

.amount-description-color {
  color: main.$color-energy-orange;
  display: inline-block;
}

.footnote {
  font-size: 12px;
  margin-top: 13px;
}
</style>