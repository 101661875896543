<template>
  <Transition
    name="fade-box"
    mode="out-in"
  >
    <div
      v-if="true"
      class="select-card select-card d-flex flex-column"
      :class="{ 'border-danger': success_val === false }"
    >
      <!-- QR scanner-off block begin -->
      <div v-if="!qrCodeOn">
        <!-- Titles -->
        <h3
          class="page-title text-center pt-2 qr-title"
          v-text="$t('home.take.qr_code_title_1')"
        />

        <div v-if="!(showPoint() || showAlternativeTerminal())">
          <p
            class="page-text text-center my-2 mx-2"
            v-text="$t('home.take.qr_code_text')"
          />

          <!-- OR code image and insert qr code input button -->
          <div class="text-center mt-3">
            <BButton
              v-if="!qrCodeOn"
              :class="{
                'btn btn-scan token_buttons': success_val !== null,
                'btn btn-scan token_buttons': success_val === null,
                'disabled-button': !allowEditToken || showSpinner
              }"
              variant="primary"
              @click="scanQr()"
            >
              <div class="qr-button">
                <img
                  src="../assets/images/qr_code.png"
                  alt="QR Code"
                  class="img-button"
                >
                <div class="qr-text-button">
                  <p class="qr-text">
                    <strong>{{ $t('home.take.scan_qr') }}</strong>
                  </p>
                  <p class="qr-text">
                    <strong>{{ $t('home.take.scan_qr2') }}</strong>
                  </p>
                </div>
              </div>
            </BButton>
          </div>
          <p
            class="page-text text-center mt-3 mb-0"
            v-text="$t('token_selection.insert_token')"
          />
        </div>

        <div v-if="showPoint()">
          <ListElement
            :col-widths="[2, 9, 1]"
            :icon-opacity="100"
          >
            <template #left>
              <font-awesome-icon
                style="color: '#424242';"
                class="fa-2x"
                icon="location-dot"
              />
            </template>
            <template #center>
              <div class="d-flex flex-column p-3">
                <div
                  class="row text-center justify-content-center"
                  style="font-weight: bold;"
                >
                  {{ newPointName }} ({{ token_input }})
                </div>
              </div>
            </template>
          </ListElement>
        </div>

        <div
          v-else-if="showAlternativeTerminal()"
          class="d-flex flex-column text-center mt-3"
        >
          <!-- Error message -->
          <ListElement
            :col-widths="[2, 9, 1]"
            background-color="red"
            :icon-opacity="100"
          >
            <template #left>
              <font-awesome-icon
                class="fa-2x"
                style="color: white;"
                icon="fa-solid fa-triangle-exclamation"
              />
            </template>
            <template #center>
              <div style="color: white; font-weight: 900;">
                {{ validationError }}
              </div>
            </template>
          </ListElement>
          
          <div
            v-if="retryTerminal"
            class="sub-title"
            v-text="$t('token_selection.try_again')"
          />

          <!-- Current Terminal -->
          <ListElement
            :col-widths="[2, 6, 4]"
            :icon-opacity="100"
          >
            <template #left>
              <div
                style="font-weight: 900;"
                class="small"
                v-text="$t('token_selection.current')"
              />
            </template>
            <template #center>
              <div class="col">
                <div
                  class="row text-center justify-content-center small"
                  style="font-weight: bold;"
                >
                  {{ retryTerminal.point_name }} ({{ retryTerminal.token }})
                </div>
                <div class="row text-center justify-content-center small">
                  {{ `${$t("token_selection.address")}: ${retryTerminal.address}` }}
                </div>
              </div>
            </template>
            <template #right>
              <BButton
                variant="primary"
                class="btn-primary"
                style="max-width: 150px; display: inline-block;"
                @click="token_input = retryTerminal.token"
              >
                <span
                  class="small"
                  v-text="$t('token_selection.retry')"
                />
              </BButton>
            </template>
          </ListElement>

          <!-- Same campaign terminal -->
          <div
            v-if="terminalsOnSameCampaign.length > 1"
            class="sub-title"
            v-text="$t('token_selection.next_point')"
          />
          <div
            v-for="terminal in terminalsOnSameCampaign"
            :key="terminal.token"
          >
            <ListElement
              v-if="!terminal.original"
              :icon-opacity="100"
            >
              <template #left>
                <div style="font-weight: 900;">
                  {{ terminal.distance > 1000 ? parseInt(terminal.distance) / 1000 + ' km' : parseInt(terminal.distance)
                    + ' m' }}
                </div>
              </template>
              <template #center>
                <div class="col">
                  <div
                    class="row text-center justify-content-center small"
                    style="font-weight: bold;"
                  >
                    {{ terminal.point_name }}
                  </div>
                  <span class="row text-center justify-content-center small">
                    {{ `${$t('token_selection.address')}: ${terminal.address}` }}
                  </span>
                </div>
              </template>
              <template #right>
                <BButton
                  variant="primary"
                  class="btn-primary"
                  style="max-width: 150px; display: inline-block;"
                  @click="goToTerminalOnMap(terminal)"
                >
                  <span
                    class="small"
                    v-text="$t('token_selection.view_on_map')"
                  />
                </BButton>
              </template>
            </ListElement>
          </div>

          <div
            class="sub-title"
            v-text="$t('token_selection.go_to_map')"
          />

          <ListElement>
            <template #left>
              <font-awesome-icon
                style="color: '#424242'; margin: 15px;"
                class="fa-2x"
                icon="location-dot"
              />
            </template>
            <template #center>
              <span
                class="small"
                v-text="$t('token_selection.go_to_map_2')"
              />
            </template>
            <template #right>
              <BButton
                variant="primary"
                class="btn-primary"
                style="max-width: 150px; display: inline-block;"
                @click="goToMap()"
              >
                <span
                  class="small"
                  v-text="$t('token_selection.view_map')"
                />
              </BButton>
            </template>
          </ListElement>

          <div
            class="sub-title"
            @click="clearPoint"
          >
            <u v-text="$t('token_selection.back')" />
          </div>
        </div>
      </div>

      <div
        v-if="qrCodeOn && !showPoint()"
        id="qr_div"
      >
        <!--Title -->
        <h3
          class="page-title text-center pt-2 qr-title"
          v-text="$t('home.take.qr_code_title_2')"
        />
        <qrcode-stream
          @detect="onQrDecode"
          @init="onQrInit"
        >
          <!--Scanner -->
          <img
            id="qr-scanner"
            src="../assets/images/qr_fundo.png"
            alt=""
          >
        </qrcode-stream>
        <!-- Close button -->
        <div @click="closeScanQr">
          <font-awesome-icon
            class="btn-close-qr"
            :icon="['fas', 'rectangle-xmark']"
          />
        </div>
        <p
          class="page-text text-center my-2 mx-2 qr-text"
          v-text="$t('home.take.type_token')"
        />
      </div>

      <b-form-group
        v-if="!showAlternativeTerminal()"
        class="mb-3"
      >
        <div class="position-relative">
          <b-form-input
            v-model="token_input"
            :class="['token_input_2', 'mt-3', { 'border-danger': success_val === false }]"
            style="font-size: 16px;"
            autocomplete="off"
            type="text"
            maxlength="4"
            :placeholder="$t('home.take.terminal_token')"
            :disabled="!allowEditToken || showSpinner"
            @focus="showDropdown = true"
            @input="onInput"
            @blur="hideDropdown"
          />
          <ul
            v-if="showDropdown && filteredTokens.length"
            class="dropdown-menu d-block text-center custom-dropdown centralize"
            style="width: 150px !important;"
          >
            <li
              v-for="(tokenItem, index) in filteredTokens"
              :key="index"
            >
              <a
                href="#"
                class="dropdown-item"
                @mousedown.prevent="selectToken(tokenItem)"
              >
                {{ tokenItem }}
              </a>
            </li>
          </ul>
        </div>
      </b-form-group>


      <p
        v-if="errorMessage"
        class="error-message"
      >
        {{ errorMessage }}
      </p>

      <!-- Take battery button -->
      <div
        v-if="showPoint()"
        class="text-center"
      >
        <BButton
          variant="primary"
          :class="{
            'token_buttons btn-primary mt-2 my-3': success_val !== null,
            'token_buttons btn-primary mt-2': success_val === null,
            'disabled-button': !token_input || !allowEditToken || showSpinner || disableButton
          }"
          :disabled="!token_input || !allowEditToken || disableButton"
          @click="validateToken()"
        >
          <span class="small">
            {{ `${$t('token_selection.confirm')} ${counter}` }}
          </span>
        </BButton>
        <div class="text-center mt-3">
          <div class="mt-3 mb-3">
            <u
              class="small"
              :class="{
                'disabled-button': !token_input || !allowEditToken || showSpinner || disableButton
              }"
              :disabled="!token_input || !allowEditToken || disableButton"
              @click="clearPoint"
              v-text="$t('token_selection.choose_other_terminal')"
            />
          </div>
        </div>
      </div>



      <!-- spinner -->
      <div
        v-if="showSpinner"
        class="text-center"
      >
        <b-spinner id="loading-spinner" />
      </div>


      <!-- agreed price terms -->
      <div class="agreement-container mt-2">
        <p
          class="terms-text"
          v-text="$t('home.take.agreed')"
        />
        <router-link
          to="#"
          class="terms-text mx-1"
          @:click="handleShowTablePrice"
        >
          <a v-text="$t('home.take.price_table')" />
        </router-link>
      </div>

      <!-- errors -->
      <div v-if="success_val !== null">
        <div class="message-fail">
          <p>{{ validationError }}</p>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import * as Sentry from '@sentry/vue'
import { SELECT_CABLE_TYPE, VALIDATION_SUCCESS, CABLE_TYPE, TOKEN } from '@/constants.js'
import { QrcodeStream } from 'vue-qrcode-reader'
import getPointByToken from '@/services/getPointName'
import sendGtag from '@/services/sendGtags'
import handleError from '@/services/handleError'
import validateTerminalToken from '@/views/home/service/validateTerminalToken'
import ErrorCodes from '@/errors'

import { UserEvent, sessionRecorder } from '@/services/sessionRecorder'
import ListElement from './ListElement.vue'

const GTAG_TAKE_BATTERY = 'take_battery'
const GTAG_TOKEN_SELECTION = 'token_selection'
const GTAG_TERMINAL_OFFLINE = 'terminal_offline'
const GTAG_NO_BATTERY_WITH_CABLE = 'no_battery_with_cable'

export default {
  name: 'TokenSelection',
  components: {
    QrcodeStream,
    ListElement
  },
  props: {
    cableType: {
      type: Number,
      default: null
    },
    confirmButton: {
      type: Function,
      default: () => { }
    },
    allowEdit: {
      type: Boolean,
      default: true
    },
    token: {
      type: String,
      default: null
    },
    pointName: {
      type: String,
      default: null
    },
    saveInvalidTransaction: {
      type: Function,
      default: () => { }
    },
    userId: {
      type: Number,
      default: null
    }
  },
  emits: [
    TOKEN,
    'handleShowPriceTable',
    'transactionId',
    'pointName',
    'takeType',
    'totalSlots',
    'selectedSlot',
    'pointId',
    'closePriceTable',
    'closeManagePayment',
    'allowEdit',
    CABLE_TYPE
    
  ],
  data() {
    return {
      CABLE_TYPE,
      token_input: null,
      token_campaign: null,
      qrCodeOn: false,
      validationError: null,
      success_val: null,
      showSpinner: false,
      takeType: 'terminal',
      disableButton: false,
      errorMessage: '',
      typeTokenSelection: 'input',
      counter: '',
      allowEditToken: null,
      // Dropdown related data
      showDropdown: false,
      tokenList: [],
      newPointName: null,
      offlineTerminal: false,
      terminalsOnSameCampaign: [],
      retryTerminal: null,
    }
  },
  computed: {
    displayToken() {
      return this.token || this.$t('home.take.terminal_token')
    },
    filteredTokens() {
      if (!this.token_input) return this.tokenList
      return this.tokenList.filter(token => token.startsWith(this.token_input))
    },
  },
  watch: {
    async token_input(newValue, oldValue) {
      this.qrCodeOn = false
      this.$emit(TOKEN, newValue)
      this.success_val = null
      this.disableButton = false
      if (newValue && newValue.length === 4) {
        this.getPointName(newValue)
        this.validationError = null
      } else
        if (oldValue && newValue && oldValue.length === 4 && newValue.length < 4) {
          this.closePriceTable()
          this.terminalsOnSameCampaign = []
          this.offlineTerminal = false
        }
    }
  },

  mounted() {
    if (this.pointName) {
      this.newPointName = this.pointName
    }

    this.allowEditToken = this.allowEdit
    try {
      this.token_campaign = sessionStorage.getItem('tokenTerminal')
      if (this.token_campaign) {
        this.token_input = this.token_campaign
      } else {
        let terminalToken = localStorage.getItem('terminal_token')
        if (terminalToken) {
          this.token_input = terminalToken
          localStorage.removeItem('terminal_token')
        } else {
          this.token_input = this.token
        }
      }
    } catch (error) {
      Sentry.captureException('Fail to access local storage with error: ', error)
      this.token_input = this.token
    }

  },
  methods: {
    showPoint() {
      return Boolean(
        this.token_input && this.token_input.length === 4 && this.newPointName
      )
    },
    showAlternativeTerminal() {
      return Boolean(
        this.offlineTerminal && this.validationError
      )
    },
    goToTerminalOnMap(terminal) {
      this.routerLink = `/maps?point_id=${terminal.point_id}`
      this.$router.push(this.routerLink)
      this.clearPoint()
      localStorage.removeItem('terminal_token')
    },
    goToMap() {
      this.routerLink = '/maps'
      this.$router.push(this.routerLink)
      this.clearPoint()
      localStorage.removeItem('terminal_token')
    },
    clearPoint() {
      this.offlineTerminal = false
      this.terminalsOnSameCampaign = []
      this.validationError = null
      this.newPointName = null
      this.token_input = null
    },
    onInput() {
      this.showDropdown = true
    },
    hideDropdown() {
      setTimeout(() => {
        this.showDropdown = false
      }, 150)
    },
    selectToken(token) {
      this.token_input = token
      this.showDropdown = false
    },
    onQrDecode: function (results) {
      for (const dectected of results) {
        if (dectected && dectected.rawValue && dectected.rawValue.split('qr/')[1]) {
          const tempToken = dectected.rawValue.split('qr/')[1]
          const error = this.localValidationToken(tempToken)
          if (!error) {
            this.validateResponse(tempToken)
          } else {
            this.closeScanQr()
            this.showErrorMessage('Código QR inválido. Tente novamente.')
          }
          return
        }
      }
    },
    showErrorMessage(message) {
      this.errorMessage = message
      setTimeout(() => {
        this.errorMessage = ''
      }, 3000)
    },
    validateResponse(tempToken) {
      this.token_input = tempToken
      this.qrCodeOn = false
      this.typeTokenSelection = 'qrcode'
    },
    scanQr: function () {
      this.qrCodeOn = true
    },
    closeScanQr: function () {
      this.qrCodeOn = false
    },
    onQrInit: async function (promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          handleError('ERROR: you need to grant camera access permisson')
        } else if (error.name === 'NotFoundError') {
          handleError('ERROR: no camera on device')
        } else if (error.name === 'NotSupportedError') {
          handleError('ERROR: secure context required (HTTPS, localhost)')
        } else if (error.name === 'NotReadableError') {
          handleError('ERROR: is the camera already in use?')
        } else if (error.name === 'OverconstrainedError') {
          handleError('ERROR: installed cameras are not suitable')
        } else if (error.name === 'StreamApiNotSupportedError') {
          handleError('ERROR: Stream API is not supported in this browser')
        }
      }
    },
    handleShowTablePrice() {
      this.$emit('handleShowPriceTable')
    },

    isAlphabetic(str) {
      const result = [...str]
        .every(char => (char >= 'a' && char <= 'z') ||
          (char >= 'A' && char <= 'Z'))
      return result
    },
    isNumeric(str) {
      const result = [...str]
        .every(char => (char >= '0' && char <= '9'))
      return result
    },

    localValidationToken(token) {
      if (token === null || token === undefined) {
        return this.$t('home.take.qr_scan_fail_backend')
      } else if (token.length === 0) {
        return this.$t('home.take.inform_token')
      } else if (
        !this.isAlphabetic(token) &&
        !this.isNumeric(token)
      ) {
        return this.$t('home.take.token_contain')
      } else if (token.length !== 4) {
        return this.$t('home.take.token_size')
      } else if (this.isAlphabetic(token)) {
        console.log('charger')
        this.takeType = 'charger'
        return false
      } else {
        return false
      }
    },

    handleCableSelection() {
      this.$emit(SELECT_CABLE_TYPE)
    },

    async getPointName(token) {
      try {
        const response = await getPointByToken(token)
        if (response && response.name && response.success) {
          this.newPointName = response.name
          return
        }

        if (response && !response.success) {
          console.log(response.error)
          this.validationError = response.error
        } else {
          this.validationError = 'Erro ao buscar Terminal, tente novamente'
        }
        this.success_val = false
        this.newPointName = null
        return

      } catch (error) {
        console.error(error)
        this.validationError = 'Erro ao buscar Terminal, tente novamente'
        this.success_val = false
      }
    },

    async validateToken() {

      this.closePriceTable()
      this.closeManagePayment()
      this.$emit('allowEdit', false)

      this.showSpinner = true
      this.disableButton = true
      this.success_val = null
      this.validationError = null
      const error = this.localValidationToken(this.token_input)
      let params = {
        user_id: this.userId,
        token: this.token_input,
        type_token_selection: this.typeTokenSelection
      }

      sessionRecorder.addUserEvent(new UserEvent(GTAG_TOKEN_SELECTION, UserEvent.transaction_id, UserEvent.point_id, params))
      sendGtag(GTAG_TOKEN_SELECTION, params)

      if (error) {
        console.log(error)
        this.$emit('allowEdit', true)
        let response = null
        try {
          response = await this.saveInvalidTransaction(error, null, this.token_input)
          this.validationError = error
        } catch (invalidTransactionError) {
          this.validationError = error
          localStorage.removeItem('terminal_token')
          Sentry.captureException(error + '. ' + invalidTransactionError)
        }
        this.success_val = false
        this.showSpinner = false
        this.disableButton = true
        this.token_input = null
        return response
      }
      localStorage.setItem('terminal_token', this.token_input)
      this.disableButton = false

      try {
        const response = await validateTerminalToken(this.token_input, this.cableType)

        if (!response || !response?.data) {
          this.showSpinner = false
          this.success_val = false
          this.validationError = 'Erro genérico!'
        }

        if (!response?.data?.success || !response?.data?.transaction_id) {
          this.$emit('allowEdit', true)
          this.success_val = false
          if (response.data.code === ErrorCodes.CANNOT_REMOVE_BATTERY) {
            window.location.reload()
          }
          if (response?.data?.error) {
            this.showSpinner = false
            this.disableButton = true
            handleError(response.data.error)
            this.validationError = response.data.error
            if (
              response.data.code === ErrorCodes.NO_BATTERY_WITH_CABLE || response.data.code === ErrorCodes.NO_CHARGED_BATTERY
            ) {
              sessionRecorder.addUserEvent(new UserEvent(GTAG_NO_BATTERY_WITH_CABLE, UserEvent.transaction_id, UserEvent.point_id, params))
              this.disableButtonWithTimer(6, false)
              this.$emit('allowEdit', true)
              return
            } else if (
              response.data.code === ErrorCodes.NO_BATTERY_FOR_PHONE ||
              response.data.code === ErrorCodes.NO_ONE_CHARGED_BATTERY
            ) {
              sessionRecorder.addUserEvent(new UserEvent(GTAG_NO_BATTERY_WITH_CABLE, UserEvent.transaction_id, UserEvent.point_id, params))
              this.token_input = ""
              if (response.data.other_valid_terminals_on_point) {
                this.terminalsOnSameCampaign = response.data.other_valid_terminals_on_point
              }
              if (response.data.extra?.terminal) {
                this.retryTerminal = response.data.extra.terminal
              }
              this.offlineTerminal = true
              this.$emit('allowEdit', true)
              return
            }            
            else if (response.data.code === ErrorCodes.TERMINAL_OFFLINE) {
              sessionRecorder.addUserEvent(new UserEvent(GTAG_TERMINAL_OFFLINE, UserEvent.transaction_id, UserEvent.point_id, params))
              this.token_input = ''
              if (response.data.other_valid_terminals_on_point) {
                this.terminalsOnSameCampaign = response.data.other_valid_terminals_on_point
              }
              if (response.data.extra?.terminal) {
                this.retryTerminal = response.data.extra.terminal
              }
              this.offlineTerminal = true
              this.$emit('allowEdit', true)
              return

            } else {
              this.allowEditToken = false
              this.disableButtonWithTimer(6, false)
              this.$emit('allowEdit', true)
              return
            }

          } else {
            this.validationError = 'Erro ao se comunicar com o Terminal Energy2go'
            this.showSpinner = false
            sessionRecorder.addUserEvent(new UserEvent(GTAG_NO_BATTERY_WITH_CABLE, UserEvent.transaction_id, UserEvent.point_id, params))
            this.disableButtonWithTimer(6, false)
            this.$emit('allowEdit', true)
            return
          }
        }

        this.$emit('transactionId', response.data.transaction_id)
        this.$emit('pointName', response.data.point_name)
        this.newPointName = response.data.point_name
        this.$emit('takeType', this.takeType)
        try {
          if (response.data.success && response.data.status === VALIDATION_SUCCESS) {
            try {
              const pointIdTokenSelection = await getPointByToken(this.token_input)
              sessionStorage.setItem('point_id_token_selection', pointIdTokenSelection.id)
              this.$emit('pointId', pointIdTokenSelection.id)
            } catch (error) {
              console.log(error)
              Sentry.captureException(error)
            }
            let params = {
              user_id: this.userId,
              token: this.token_input,
              transaction_id: response.data.transaction_id
            }
            sessionRecorder.addUserEvent(new UserEvent(GTAG_TAKE_BATTERY, params.transaction_id, UserEvent.point_id, params))
            sendGtag(GTAG_TAKE_BATTERY, params)
            this.showSpinner = false
            this.confirmButton()
          } else {
            const error = 'Transaction not validated'
            console.log(error)
            await this.saveInvalidTransaction(error, response.data.transaction_id, this.token_input)
            this.showSpinner = false
            this.$emit('allowEdit', true)
          }
        } catch (error) {
          handleError(error)
          await this.saveInvalidTransaction('Erro', response.data.transaction_id, this.token_input)
          this.showSpinner = false
          this.$emit('allowEdit', true)
        }
      } catch (error) {
        handleError(error)
        this.validationError =
          'Problema de comunicação com a estação de baterias. Tente novamente em instantes ou utilize outra estação.'

        await this.saveInvalidTransaction("Erro na validação da transação", this.token_input)
        this.showSpinner = false
        this.$emit('allowEdit', true)
        return
      }
      this.showSpinner = false
    },
    closePriceTable() {
      this.$emit('closePriceTable')
    },
    closeManagePayment() {
      this.$emit('closeManagePayment')
    },
    disableButtonWithTimer(countdown, allowStop = true) {
      this.allowEditToken = false
      this.disableButton = true
      this.counter = `(${countdown.toString()})`
      countdown--
      const interval = setInterval(() => {
        this.counter = `(${countdown.toString()})`
        countdown--
        if (countdown < 0 || (allowStop && this.success_val === null)) {
          clearInterval(interval);
          this.allowEditToken = true
          this.disableButton = false
          this.counter = ''
          this.validationError = ''
          this.success_val = null
        }
      }, 1000)
    }
  }
}
</script>

<style scoped lang="scss">
@use '@/main';

.token_buttons {
  max-width: 150px;
  width: 100%;
  display: block;
  margin: 0 auto;
  white-space: nowrap;
}

.btn-scan {
  background-color: main.$color-energy-light-blue !important;
  max-width: 230px;
  align-items: center;
  margin: 5px auto;
  box-shadow: 0.1rem 0.2rem 0.2rem 0.1rem rgba(0, 0, 0, 0.2) !important
}

#qr-image-back {
  width: 100%;
  height: 100%;
}

#qr_div {
  margin-bottom: 10px;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
}

#qr-scanner {
  width: 95%;
  height: 100%;
  padding: 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  position: relative;
}

.btn-close-qr {
  font-size: 2rem;
  z-index: 1000;
  /* Ensure it's on top of other elements */
  color: #fff;
  position: absolute;
  top: 2rem;
  right: 1rem;
  cursor: pointer;
  padding: 5px;
  /* Optional: add padding for a larger clickable area */
  // border-radius: 50%; /* Optional: make it circular */
}

.qr-title {
  font-weight: bold;
  color: #000000;
}

.qr-text {
  color: #000000;
  width: 95%;
}

.qr-input-container {
  background-color: main.$color-energy-orange;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  border-radius: 3%;
  max-width: 50%;
  margin: auto;
}

.token_input_2 {
  max-width: 150px;
  margin: 0 auto;
  text-align: left;
  border: 1px solid #000000;
  box-shadow: #333;
  font-size: 0.875rem;
  text-align: center;
}

.agreement-container {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: 0.9em;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 0.3rem;

  /* Optional: Adds some space at the bottom */
  p {
    margin: 0;
    /* Reset margin */
  }
}

.message-fail {
  width: calc(100% + 1rem);
  position: relative;
  bottom: -0.5rem;
  left: -0.5rem;
  text-align: left;
  line-height: 1;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: red;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  border-radius: 0 0 5px 5 px;

  p {
    margin: 0;
  }
}

.token-display {
  font-size: 2rem;
  text-align: center;
  color: white;
  font-weight: bold;

  p {
    margin: 0;
    /* Reset margin */
  }
}

.disabled-button {
  background-color: grey !important;
  cursor: not-allowed;
  opacity: 0.6;
  pointer-events: none;
}

.img-button {
  width: 80px;
  height: 80px;
  border: 5px solid main.$color-energy-orange;
  margin: 1px 10px 1px 1px;
  border-radius: 8px;
}

.qr-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: space-around;
}

.qr-text-button {
  display: flex;
  flex-direction: column;
  align-content: space-around;
  justify-content: center;
  font-size: 25px;
}

.qr-text {
  margin: 2px;
  font-weight: 900;
}

.token_input_2::placeholder {
  color: #999;
  /* Light gray color */
  opacity: 0.7;
  /* Slightly transparent */
}

#loading-spinner {
  color: main.$color-energy-orange !important;
  margin: 10px;
}

.error-message {
  color: red;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
}

.centralize {
  border-radius: 10px !important;
  box-shadow: 0.1rem 0.3rem 0.3rem 0.2rem rgba(0, 0, 0, 0.2) !important;
  margin: auto;
  position: relative;
}

.terms-text {
  font-size: 14px;
}

.sub-title {
  max-width: 80%;
  align-self: center;
  font-weight: 600;
  font-size: medium;
  margin-top: 1.5rem;

}
</style>
