export const TAKE_BATTERY_SUCCESS = 'take_battery_success'
export const TAKE_BATTERY_ERROR = 'take_battery_error'
export const VALIDATION_SUCCESS = 19
export const VALIDATION_CODE_LENGTH = 6
export const CABLE_TYPE_LIGHTNING = 0
export const CABLE_TYPE_MICRO = 1
export const CABLE_TYPE_USBC = 2
export const SUCCESS_MSG = 'Cartão cadastrado com sucesso'
export const FAIL_MSG = 'An error occured'

export const MANAGE_PAYMENT = 'managePayment'
export const SELECT_CABLE_TYPE = 'selectCableType'
export const TOKEN_SELECTION = 'tokenSelection'
export const TAKE_DEMONSTRATION = 'takeDemonstration'
export const PRICE_TABLE = 'priceTable'
export const PIX_REGISTRATION = 'pixRegistration'
export const PIX_PAYMENT = 'pixPayment'
export const SELECT_PAYMENT_METHOD = 'selectPaymentMethod'
export const CREDIT_CARD_PAYMENT = 'creditCardPayment'
export const CREDIT_CARD_REGISTRATION = 'creditCardRegistration'
export const CABLE_TYPE = 'cableType'
export const TOKEN = 'token'

export const BIG_BOX_COMPONENT_LIST = [
  { id: 3, name: 'CreditCardRegistration', reference: CREDIT_CARD_REGISTRATION },
  { id: 4, name: 'ManagePayment', reference: MANAGE_PAYMENT },
  { id: 5, name: 'SelectCableType', reference: SELECT_CABLE_TYPE },
  { id: 6, name: 'TokenSelection', reference: TOKEN_SELECTION },
  { id: 8, name: 'TakeDemonstration', reference: TAKE_DEMONSTRATION },
  { id: 9, name: 'PixRegistration', reference: PIX_REGISTRATION },
  { id: 10, name: 'PixPayment', reference: PIX_PAYMENT },
  { id: 11, name: 'SelectPaymentMethod', reference: SELECT_PAYMENT_METHOD },
  { id: 12, name: 'CreditCardPayment', reference: CREDIT_CARD_PAYMENT },
]
export const MINI_BOX_COMPONENT_LIST = [
  { id: 3, name: 'UserDefaults', reference: MANAGE_PAYMENT },
  { id: 4, name: 'UserDefaults', reference: CABLE_TYPE },
  { id: 5, name: 'UserDefaults', reference: TOKEN }
]

export const EXPERIMENTS = {
  paymentMethod: {
    experiment: 'payment_method_selection',
    variants: {
      defaultPaymentMethod: 'default_card_payment_method'
    }
  }
}

export const RETURN_SUCCESS_LIST = [
  'return_battery_success',
  'return_by_panel_success',
  'return_battery_unlocked'
]
