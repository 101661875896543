<template>
  <div
    class="select-card text-center"
    :class="{
      'border-danger': showErrorMessage
    }"
  >
    <h5
      class="page-title text-lighter m-3"
      style="font-size: large"
      v-text="$t('pix_payment.title')"
    />

    <div v-if="loading">
      <h6
        class="mt-3"
        v-text="$t('pix_payment.loading')"
      />
      <b-spinner id="loading-spinner" />
    </div>
    <div
      v-if="plans && plans.point_name && primePaymentPlan.type"
      class="text-center"
    >
      <div class="info-container">
        <div
          v-if="primePaymentPlan.type === 'campaign' && showFreeTimeCheckbox"
          class="info-box"
        >
          <span v-text="campaignFreeTimeText()" />
          <span style="color: green">
            {{
              primePaymentPlan.type === 'campaign' && primePaymentPlan.optIn
                ? $t('price_table.free_star')
                : $t('price_table.free')
            }}
          </span>
        </div>

        <div
          v-if="primePaymentPlan.type === 'campaign' && primePaymentPlan.optIn && showFreeTimeCheckbox"
          class="alert alert-warning p-1 mb-1"
        >
          <b-form-checkbox
            id="checkbox-1"
            v-model="campaignOptInStatus"
            class="mx-4"
          >
            <div
              class="info-text-small"
              v-text="$t('home.take.campaign')"
            />
          </b-form-checkbox>
        </div>
        <div
          v-if="showFreeTimeCheckbox"
          class="info-text mb-4 mt-1 mx-3"
        >
          <span v-text="$t('credit_card_payment.agree')" />
          <span>
            <router-link
              to="#"
              @:click="handleShowTablePrice"
            >
              <a v-text="$t('credit_card_payment.price_table')" />
            </router-link>
          </span>
        </div>

        <div class="info-text mb-4 mt-1 mx-3">
          <span>Precisa devolver em outro lugar? Ver pontos no </span>
          <span>
            <router-link to="/maps">
              <a>mapa</a>
            </router-link>
          </span>
        </div>

        <div
          v-if="pixSecurityDeposit"
          class="info-box"
          v-text="pixValue()"
        />
        <div
          class="info-text mx-4"
          data-text="Ver tabela de preços"
        >
          {{ $t('price_table.text_5') }}
        </div>
      </div>
    </div>
    <input
      v-if="showQRCodeAndPixPayment"
      v-model="copyPastePix"
      type="text"
      readonly
      class="my-3 selectable-input"
    >

    <div v-if="showQRCodeAndPixPayment">
      <button
        id="pix-copy-to-clipboard"
        class="btn btn-md btn-pix-copy-paste"
        @click="copyToClipboard"
      >
        <span
          style="margin-right: 15px"
          v-text="buttonText"
        />
        <font-awesome-icon
          class="fa-xl"
          icon="fa-copy"
        />
      </button>

      <span
        class="alert alert-warning d-block my-3"
        role="alert"
      >
        {{ $t('pix_payment.warning').split('<b>')[0] }}
        <strong>{{ $t('pix_payment.warning').split('<b>')[1].split('</b>')[0] }}</strong>
        {{ $t('pix_payment.warning').split('</b>')[1] }}
      </span>
    </div>
    <div v-else-if="showButtonTryAgain">
      <div class="text-center mt-2">
        <button
          style="font-size: large"
          class="btn btn-md"
          @click="makePixPaymentNewTry"
        >
          <u>
            <span
              style="margin-right: 10px; margin-bottom: 40px"
              v-text="$t('pix_payment.try_again')"
            />
          </u>
          <font-awesome-icon
            class="fa-xl btn-pix"
            icon="fa-brands fa-pix"
          />
        </button>
      </div>
    </div>
    <img
      id="image"
      alt="Generated Image"
      :class="{
        'image-qrcode-none': !showQRCodeAndPixPayment,
        'image-qrcode': showQRCodeAndPixPayment
      }"
    >

    <div
      v-if="loadingPixPrice && !showQRCodeAndPixPayment"
      class="mt-4"
    >
      <button
        class="btn btn-primary"
        @click="makePixPayment"
        v-text="createPixButtonText"
      />
    </div>

    <b-form-group class="text-center mt-2">
      <button
        id="pix-change-payment-method"
        style="font-size: large"
        class="btn btn-md"
        @click="handleCancelWithPaymentMethodChange"
      >
        <u>
          <span
            style="margin-bottom: 40px"
            v-text="$t('pix_payment.change_payment')"
          />
        </u>
      </button>
    </b-form-group>

    <b-form-group
      class="text-center mt-2"
      :class="{
        'error-padding': showErrorMessage
      }"
    >
      <button
        style="font-size: large"
        :class="{
          'btn btn-md': !showErrorMessage,
          'btn btn-md mb-4': showErrorMessage
        }"
        @click="handleCancel"
      >
        <u>
          <span
            style="margin-bottom: 40px"
            v-text="$t('pix_payment.cancel')"
          />
        </u>
      </button>
    </b-form-group>
    <BRow v-if="showErrorMessage">
      <BCol>
        <div class="message-fail">
          <h5>{{ errorMessage }}</h5>
        </div>
      </BCol>
    </BRow>
  </div>
</template>

<script>
  import API from '@/services/api'
  import handleError from '@/services/handleError'
  import axios from 'axios'
  import getPlans from '@/views/home/service/getPlans'

  const PENDING = 10
  const APPROVED = 2

  export default {
    name: 'PixPayment',
    props: {
      transactionId: {
        type: Number,
        required: false,
        default: null
      },
      cableType: {
        type: Number,
        required: false,
        default: null
      },
      user: {
        type: Object,
        required: true
      },
      pointId: {
        type: Number,
        required: true
      }
    },
    emits: ['cancel', 'handleShowPriceTable', 'pixPayed', 'cancelToPaymentMethod'],
    data() {
      return {
        pixCreated: false,
        buttonTextInit: 'Pix Copia e Cola',
        buttonText: '',
        copyPastePix: null,
        base64Code: null,
        loading: false,
        loadingPixPrice: false,
        amount: {
          unit: null,
          cents: null
        },
        errorMessage: '',
        showButtonTryAgain: false,
        showQRCodeAndPixPayment: false,
        showErrorMessage: false,
        paymentId: null,
        timeoutId: null,
        plans: null,
        planHourly: null,
        planDaily: null,
        maxPlan: null,
        campaign: null,
        pixSecurityDeposit: null,
        campaignOptInStatus: false,
        primePaymentPlan: null,
        showFreeTimeCheckbox: true
      }
    },
    computed: {
      createPixButtonText() {
        if (this.primePaymentPlan.type === 'campaign' && this.primePaymentPlan.optIn) {
          return (
            this.$t('pix_payment.create_pix') +
            ' ' +
            (this.campaignOptInStatus
              ? this.$t('pix_payment.create_pix_opt_in')
              : this.$t('pix_payment.create_pix_opt_out'))
          )
        } else {
          return this.$t('pix_payment.create_pix')
        }
      }
    },
    async mounted() {
      this.buttonText = this.buttonTextInit
      await this.pointPlan()
      this.loadingPixPrice
      this.showQRCodeAndPixPayment
    },
    methods: {
      pixValue() {
        return `${this.$t('price_table.text_4_2')} ${(parseInt(this.pixSecurityDeposit.reais) + parseInt(this.pixSecurityDeposit.centavos) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`
      },
      campaignFreeTimeText() {
        const acumulatedHours = Math.floor(this.campaign.free_time / 60 / 60)
        const acumulatedMinutes = (this.campaign.free_time / 60) % 60
        if (acumulatedMinutes === 0) {
          return `${this.$t('price_table.until')} ${acumulatedHours} ${this.$t('price_table.hours')}: `
        } else if (acumulatedHours === 0) {
          return `${this.$t('price_table.until')} ${acumulatedMinutes} minutos: `
        } else {
          return `${this.$t('price_table.until')} ${acumulatedHours} ${this.$t('price_table.hours')} e ${acumulatedMinutes} minutos: `
        }
      },
      async pointPlan() {
        this.plans = await getPlans(this.pointId)

        if (this.plans && this.pointId !== undefined && this.pointId !== null) {
          this.planHourly = this.plans.price.find(prices => prices.internal_ref === 'four_hours')
          if (this.planHourly && this.planHourly.price_variation) {
            this.planHourly = this.applyPriceVariation(this.planHourly)
          }

          this.planDaily = this.plans.price.find(prices => prices.internal_ref === 'daily')
          if (this.planDaily && this.planDaily.price_variation) {
            this.planDaily = this.applyPriceVariation(this.planDaily)
          }
          this.maxPlan = this.plans.price.find(prices => prices.internal_ref === 'quarterly')

          if (this.plans.campaign) {
            this.campaign = this.plans.campaign
            this.campaign.optIn = this.plans.campaign.need_opt_in
          }
          this.pixSecurityDeposit = this.plans.pix_security_deposit.security_deposit

          if (this.campaign) {
            this.primePaymentPlan = this.campaign
            this.primePaymentPlan.type = 'campaign'
          } else if (this.planHourly) {
            this.primePaymentPlan = this.planHourly
            this.primePaymentPlan.type = 'hourly'
          } else if (this.planDaily) {
            this.primePaymentPlan = this.planDaily
            this.primePaymentPlan.type = 'daily'
          } else {
            this.primePaymentPlan = this.planHourly
            this.primePaymentPlan.type = 'hourly'
          }

          this.loadingPixPrice = true
        }
      },
      applyPriceVariation(price) {
        const variationFactor = 1 + price.price_variation / 100
        const totalCentavos = Math.round(
          (parseInt(price.amount.reais) * 100 + parseInt(price.amount.centavos)) * variationFactor
        )
        price.amount.reais = Math.floor(totalCentavos / 100)
        price.amount.centavos = totalCentavos % 100
        return price
      },
      copyToClipboard() {
        console.log('copy to clipboard')

        navigator.clipboard
          .writeText(this.copyPastePix)
          .then(() => {
            this.buttonText = 'Copiado!'

            setTimeout(() => {
              this.buttonText = this.buttonTextInit
            }, 2000)
          })
          .catch(err => {
            console.error('Failed to copy: ', err)
          })
      },
      async makePixPayment() {
        this.showFreeTimeCheckbox = false
        this.loading = true
        this.showQRCodeAndPixPayment = false
        this.showErrorMessage = false
        this.errorMessage = ''

        const retreiveQrCode = Boolean(
          this.user.waiting_for_pix_payment &&
            this.user.waiting_for_pix_payment.pix_data &&
            this.user.waiting_for_pix_payment.pix_data.qr_code &&
            this.user.waiting_for_pix_payment.pix_data.qr_code.image &&
            this.user.waiting_for_pix_payment.pix_data.qr_code.code &&
            this.user.waiting_for_pix_payment.pix_data.amount !== null &&
            this.user.waiting_for_pix_payment.pix_data.amount !== undefined &&
            this.user.waiting_for_pix_payment.transaction_id == this.transactionId
        )

        if (retreiveQrCode) {
          this.pixVariables(this.user.waiting_for_pix_payment.pix_data)
        } else {
          const isValid = await this.createPixPayment()
          if (!isValid) {
            this.loading = false
            this.showButtonTryAgain = true
            return
          }
        }

        this.timeoutId = null
        await this.startGetPixStatus()
      },
      async createPixPayment() {
        try {
          const response = await API.post('payment/pix', {
            transaction_id: this.transactionId
          })

          if (response.data && response.data.success && response.data.status === PENDING) {
            this.pixVariables(response.data)

            try {
              await API.post('transaction/validation/pix', {
                cable_type: this.cableType,
                transaction_id: this.transactionId
              })
            } catch (error) {
              handleError(error)
            }

            return true
          } else if (response.data && !response.data.success && response.data.error) {
            this.handleErrorMessage(response.data.error)
            return false
          } else {
            this.handleErrorMessage('Error ao gerar QR Code')
            handleError(error)
            return false
          }
        } catch (error) {
          this.handleErrorMessage('Error ao gerar QR Code')
          handleError(error)
          return false
        }
      },
      makePixPaymentNewTry() {
        this.showButtonTryAgain = false
        this.makePixPayment()
      },
      pixVariables(data) {
        if (data.qr_code.image) {
          this.base64Code = data.qr_code.image
          this.showQrCode()
          this.showQRCodeAndPixPayment = true
        }
        if (data.qr_code.code) {
          this.copyPastePix = data.qr_code.code
          this.showQRCodeAndPixPayment = true
        }

        if (data.amount && data.amount > 0) {
          const [unit, cents] = parseFloat(data.amount).toFixed(2).split('.')
          this.amount = {
            unit: unit.toString(),
            cents: cents.toString()
          }
          this.showQRCodeAndPixPayment = true
        }

        this.paymentId = data.payment_id
      },
      showQrCode() {
        const base64Image = `data:image/png;base64,${this.base64Code}`
        const imgElement = document.getElementById('image')
        if (imgElement) {
          imgElement.src = base64Image
        }
        this.loading = false
      },
      handleErrorMessage(message) {
        this.errorMessage = message + ". Tente novamente ou utilize a opção Cartão de Crédito em: 'Forma de pagamento'."
        this.showErrorMessage = true
        setTimeout(() => {
          this.errorMessage = ''
          this.showErrorMessage = false
        }, 5000)
      },
      handleShowTablePrice() {
        this.$emit('handleShowPriceTable')
      },
      async getPixStatus() {
        if (!this.paymentId || !this.timeoutId) {
          this.cancelTimeout()
          return
        }
        try {
          const response = await API.get(`payment/status?pre_payment=${this.paymentId}`)

          if (!response || (response && !response.data)) {
            console.log('Não foi possível obter o status do pagamento')
            this.startGetPixStatus()
            return
          }
          const payed = response.data && response.data.success && response.data.status === APPROVED
          const pending = response.data && response.data.success && response.data.status === PENDING
          if (pending) {
            console.log('Aguardando pagamento')
          } else if (payed) {
            console.log('Pagamento efetuado')
            this.cancelTimeout()
            this.$emit('pixPayed', this.campaignOptInStatus)
            return
          } else {
            handleError(error)
          }
        } catch (error) {
          if (axios.isAxiosError(error)) {
            if (error.code === 'ECONNABORTED') {
              console.error('Timeout error:', error.message)
              handleError({
                status: 408,
                response,
                message: 'Timeout verificado PIX: ' + this.paymentId
              })
            }
          }
          handleError(error)
        }
        this.startGetPixStatus()
      },
      async startGetPixStatus() {
        this.timeoutId = setTimeout(async () => {
          await this.getPixStatus()
        }, 3000)
      },
      cancelTimeout() {
        if (this.timeoutId) {
          clearTimeout()
          this.timeoutId = null
        }
      },
      async handleCancel() {
        this.cancelTimeout()
        this.$emit('cancel', 'O pagamento pelo QR Code foi cancelado')
      },
      async handleCancelWithPaymentMethodChange() {
        this.cancelTimeout()
        this.$emit('cancelToPaymentMethod', 'A forma de pagamento foi alterada')
      }
    }
  }
</script>

<style scoped lang="scss">
  @use '@/main';

  .btn-pix-copy-paste {
    margin-bottom: 20px;
    background-color: main.$color-energy-blue !important;
    color: white !important;
  }

  .image-qrcode {
    border: 3px solid main.$color-energy-blue !important;
    border-radius: 10px;
    margin: 20px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 200px;
    min-width: 60%;
  }

  .image-qrcode-none {
    display: none;
  }

  #loading-spinner {
    display: block;
    margin: 20px auto;
    color: main.$color-energy-orange;
  }

  .display-none {
    display: none;
  }

  .btn-pix {
    color: main.$color-energy-blue !important;
    background-color: white !important;
  }

  .error-message {
    color: red;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
  }

  .message-fail {
    width: 100%;
    padding: 0.2rem 1rem;
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: left;
    box-sizing: border-box;
    margin-top: 0.5rem;
    overflow: hidden;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background-color: red;
    border-radius: 0 0 5px 5px !important;
  }

  .border-danger {
    border: 2px solid red;
  }

  .error-padding {
    padding: 10px 10px 50px 10px !important;
  }

  .selectable-input {
    font-size: large;
    padding: 5px;
    user-select: text;
    cursor: text;
  }

  .default-link {
    color: #0066cc;
    text-decoration: underline;
    cursor: pointer;
  }

  .default-link:hover {
    color: #003399;
  }

  .info-container {
    margin: 5px 0px;
  }

  .info-box {
    font-weight: 600;
    font-size: large;
  }

  .info-text {
    font-size: medium;
  }

  .info-text-small {
    font-size: small;
    text-align: justify;
    font-weight: 400;
  }

  .checkbox-container {
    background-color: cornsilk;
    padding: 2px 10px;
    border-radius: 3px;
  }
</style>
