<template>
  <b-card class="select-card text-center">
    <div
      class="d-flex flex-column justify-content-center align-items-center"
    >
      <p
        class="page-title h4 text-uppercase"
        v-text="$t('select_payment_method.title')"
      />
      <p
        class="page-text fw-normal"
        v-text="$t('select_payment_method.subtitle')"
      />
    </div>
        
    <div class="container p-0">
      <ListElement>
        <template #left>
          <font-awesome-icon
            class="fa-2x"
            icon="fa-credit-card"
          />
        </template>
        <template #center>
          <span v-text="$t('select_payment_method.type_1') + ':'" />
        </template>
        <template #right>
          <BButton
            id="register-credit-card"
            variant="primary"
            class="btn"
            @click="creditCardLink"
          >
            <span class="d-inline-flex align-items-center text-nowrap">
              <span class="small">
                {{ $t('select_payment_method.register') }}
              </span>
            </span>
          </BButton>
        </template>
      </ListElement>
      <ListElement>
        <template #left>
          <font-awesome-icon
            class="fa-2x"
            icon="fa-brands fa-pix"
          />
        </template>
        <template #center>
          <span v-text="$t('select_payment_method.type_2') + ': '" />
          <i class="deposit_warn">
            ({{ $t('select_payment_method.deposit') }} R${{ parseFloat(pixPrice).toFixed(2).replace('.', ',') }})
          </i>
        </template>
        <template #right>
          <BButton
            id="register-pix"
            variant="primary"
            class="btn"
            @click="pixLink"
          >
            <span class="d-inline-flex align-items-center text-nowrap">
              <span class="small">
                {{ $t('select_payment_method.register') }}
              </span>
            </span>
          </BButton>
        </template>
      </ListElement>
      <div v-if="hasPaymentMethod">
        <div class="row">
          <div
            class="d-flex justify-content-center align-items-center mt-3 mb-1"
            style="font-size: 15px;"
          >
            ou:
          </div>
        </div>
        <ListElement>
          <template #left>
            <font-awesome-icon
              class="fa-2x"
              icon="fa-dollar-sign"
            />
          </template>
          <template #center>
            <div
              class="small"
              v-text="$t('select_payment_method.change')"
            />
          </template>
          <template #right>
            <BButton
              id="modify-payment-method"
              variant="primary"
              class="btn"
              @click="paymentMethodRegistration"
            >
              <span
                class="small"
                v-text="$t('select_payment_method.modify')"
              />
            </BButton>
          </template>
        </ListElement>
      </div>
    </div>

    <div
      style="font-size: 16px; margin: 10px;"
      class="text-center m-4"
    >
      <router-link
        to="#"
        @click="handleShowTablePrice"
      >
        <a
          id="payment-method-price-table"
          v-text="$t('home.take.price_table')"
        />
      </router-link>
    </div>        

    <b-form-group class="text-center mt-3 m-auto">
      <button
        id="payment-method-back"
        style="font-size: large;"
        class="btn btn-md"
        @click="back"
      >
        <span
          style="margin-right: 10px; text-decoration: underline;"
          v-text="$t('select_payment_method.back')"
        />
      </button>
    </b-form-group>
  </b-card>
</template>

<script>
import { sessionRecorder, UserEvent } from '@/services/sessionRecorder'
import ListElement from '@/components/ListElement.vue'

const TAG_SELECT_PAYMENT_METHOD = 'select_payment_method'

export default {
    name: 'SelectPaymentMethod',
    components: {
        ListElement
    },
    props: {
        pixPrice: {
            type: String,
            default: '0,00'
        },
        hasPaymentMethod: {
            type: Boolean,
            default: false
        }
    },
    emits: ['pixLink', 'creditCardLink', 'back', 'handleShowTablePrice', 'paymentMethodRegistration', 'handleShowPriceTable'],
    data() {
        return {
            success_val: true,
            mobile_api_url: import.meta.env.VITE_MOBILE_API
        };
    },
    mounted() {
        sessionRecorder.addUserEvent(new UserEvent(TAG_SELECT_PAYMENT_METHOD))
    },
    methods: {
        creditCardLink() {
            this.$emit('creditCardLink')
        },
        pixLink() {
            this.$emit('pixLink')
        },
        back() {
            this.$emit('back')
        },
        handleShowTablePrice() {
            this.$emit('handleShowPriceTable')
        },
        paymentMethodRegistration () {
            this.$emit('paymentMethodRegistration')
        }
    }
}
</script>

<style scoped lang="scss">
@use '@/main';

.deposit_warn {
    font-size: 0.85em;
}

</style>