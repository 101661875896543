<template>
  <b-card
    class="select-card text-center"
    :class="{ 'border-danger': success_val !== null }"
  >
    <h5
      class="page-title"
      v-text="$t('pix_registration.title')"
    />
    <p
      key="image"
      class="page-text"
      v-text="$t('pix_registration.subtitle')"
    />

    <BContainer
      key="text"
      class="bv-example-row"
    >
      <BRow>
        <BCol>
          <b-form-group>
            <b-form-input
              id="card_number_input"
              v-model="pixData.name"
              type="name"
              :placeholder="$t('pix_registration.full_name')"
              :class="{ 'border-danger': errors.name }"
            />
          </b-form-group>
        </BCol>
      </BRow>
      <b-form-input
        v-model="pixData.cpfCnpj"
        v-mask="cpfCnpjMask"
        type="tel"
        placeholder="CPF/CNPJ"
        :class="{ 'border-danger': errors.cpfCnpj }"
      />
      <BRow
        v-if="loading"
        class="d-flex justify-content-center"
      >
        <b-spinner id="loading-spinner" />
      </BRow>
      <b-form-group class="text-center">
        <button
          id="register-pix-identification"
          class="btn btn-md btn-primary mt-3"
          :disabled="loading || success_val === false"
          @click="registerPixIdentification"
          v-text="$t('pix_registration.button')"
        />
      </b-form-group>
      <BRow v-if="success_val === false">
        <div class="message-fail">
          <h5>{{ message }}</h5>
        </div>
      </BRow>
      <BRow v-if="!withPaymentScreen || (withPaymentScreen && showBackPaymentButton)">
        <BCol>
          <b-form-group class="text-center">
            <button
              id="pix-registration-back"
              class="btn btn-md mt-3"
              :disabled="loading"
              style="text-decoration: underline;"
              @click="back"
              v-text="$t('pix_registration.back')"
            />
          </b-form-group>
        </BCol>
      </BRow>
      <div v-if="withPaymentScreen">
        <hr style="margin-bottom: 30px; margin-top: 30px;">
        <BRow>
          <BCol>
            <b-form-group class="text-center">
              <button
                id="pix-registration-back"
                class="btn btn-md"
                :disabled="loading"
                style="text-decoration: underline;"
                @click="creditRegistration"
              >
                <span class="d-flex justify-content-center align-items-center">
                  <span
                    style="opacity: 70%; margin-right: 7px;"
                  >
                    <font-awesome-icon
                      class="fa-2x"
                      icon="fa-credit-card"
                    />
                  </span>
                  <span v-text="$t('pix_registration.credit_card_registration')" />
                </span>
              </button>
            </b-form-group>
          </BCol>
        </BRow>
      </div>
    </BContainer>
  </b-card>
</template>

<script>

import { cpf, cnpj } from 'cpf-cnpj-validator';
import createPixIdentification from '@/views/home/service/createPixIdentification'
import handleError from '@/services/handleError'
import { sessionRecorder, UserEvent } from '@/services/sessionRecorder'
import { EXPERIMENTS } from "@/constants"
import { experimentInstance } from '@/utils/experiments'

const TAG_PIX_REGISTRATION = 'pix_registration'

export default {
  name: 'PixRegistration',
  props: {
    user: {
      type: Object,
      required: true
    },
    showBackPaymentButton: {
      type: Boolean,
      default: true
    }
  },
  emits: ['user', 'back', 'creditRegistration'],
  data() {
    return {
      message: '',
      success_val: null,
      pixData: {
        name: '',
        cpfCnpj: ''
      },
      errors: {
        name: false,
        cpfCnpj: false
      },
      loading: false,
      cpfCnpjClean: null,
      withPaymentScreen: experimentInstance.isExperimentEnabled(EXPERIMENTS.paymentMethod.experiment, 
                                                                EXPERIMENTS.paymentMethod.variants.defaultPaymentMethod)
    }
  },
  computed: {
    cpfCnpjMask() {
      if (this.pixData.cpfCnpj.replace(/\D/g, '').length <= 11) {
        return '###.###.###-##';
      } else {
        return '##.###.###/####-##';
      }
    }
  },
  watch: {
    pixData: {
      handler() {
        this.success_val = null
        this.message = ''
        this.errors.name = false
        this.errors.cpfCnpj = false
      },
      deep: true
    }
  },
  mounted() {
    sessionRecorder.addUserEvent(new UserEvent(TAG_PIX_REGISTRATION))
  },
  methods: {
    validatePixData() {
      this.loading = true

      if (this.pixData.name === '') {
        this.success_val = false
        this.message = 'Campo Nome Completo está vazio'
        this.errors.name = true
        this.loading = false
        return false
      } else if (this.pixData.name.split(' ').length < 2) {
        this.success_val = false
        this.message = 'Nome deve estar completo'
        this.errors.name = true
        this.loading = false
        return false
      }

      if (this.cpfCnpjClean.length == 0) {
        this.success_val = false
        this.message = 'O campo CPF/CNPJ é obrigatório'
        this.errors.cpfCnpj = true
        this.loading = false
        return false
      } else if (this.cpfCnpjClean.length == 11) {
        if (!cpf.isValid(this.cpfCnpjClean)) {
          this.success_val = false
          this.message = 'CPF inválido'
          this.errors.cpfCnpj = true
          this.loading = false
          return false
        }
      } else if (this.cpfCnpjClean.length === 14) {
        if (!cnpj.isValid(this.cpfCnpjClean)) {
          this.success_val = false
          this.message = 'CNPJ inválido'
          this.errors.cpfCnpj = true
          this.loading = false
          return false
        }
      } else {
        this.success_val = false
        this.message = 'CPF/CNPJ com tamanho inválido'
        this.errors.cpfCnpj = true
        this.loading = false
        return false
      }
      
      return true
    },
    async registerPixIdentification () {

      this.cpfCnpjClean = this.pixData.cpfCnpj.replace(/\D/g, '')
      const isValidData =this.validatePixData()
      if (!isValidData) {
        return
      }
      
      try {
        const user = await createPixIdentification(this.user, {
          name: this.pixData.name,
          cpf_or_cnpj: `${this.cpfCnpjClean}`
        })

        if (user) {
          this.$emit('user', user)
        } else {
          this.success_val = false
          this.message = "Erro ao registrar identificação PIX"
        }

      } catch (error) {
        this.loading = false
        this.success_val = false
        this.message = "Erro ao registrar identificação PIX"
        handleError(error)
      } finally {
        this.loading = false
      }
    },
    back() {
      this.$emit('back')
    },
    creditRegistration() {
      this.$emit('creditRegistration')
    }
  }
}
</script>

<style scoped lang="scss">
@use '@/main';

.form-control {
  margin: 6px 0;
  padding: 10px;
  border: 1px #000 solid;

  &::placeholder {
    color: black;
    opacity: 0.5;
  }
}

.message-fail {
  width: 100%;
  padding: 0.2rem 1rem;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: left;
  line-height: 1;
  box-sizing: border-box;
  margin-top: 0.5rem;
  max-height: 2rem;
  overflow: hidden;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: red;
  border-radius: 0 0 5px 5px;
}

#loading-spinner {
  display: block;
  margin: 20px auto;
  color: main.$color-energy-orange;
}

.btn-credit-card {
  background-color: main.$color-energy-blue !important;
  color: white !important;
  margin: 20px;
}

.btn-pix {
  color: main.$color-energy-blue !important;
  background-color: white !important;
}
</style>
