<script>
import getPointByToken from '@/services/getPointName'
import { UserEvent, sessionRecorder } from '@/services/sessionRecorder'


const TAG_TOKEN_REDIRECT = 'token_redirect'

export default {
    name: 'TokenRedirect',
    async mounted() {
        const token = this.$route.params.token
        if (token) {
            let point = null
            if (token) {
                point = await getPointByToken(token)
                localStorage.setItem('terminal_token', token)
            }
            if (point && point.success) {
                try {
                    localStorage.setItem('terminal_token', token)
                    localStorage.setItem('point_name', point.name)
                    sessionStorage.setItem('point_id_qr', point.id)
                    localStorage.setItem('point_id', point.id)
                    sessionRecorder.addUserEvent(new UserEvent(
                        TAG_TOKEN_REDIRECT, null, point.id, { "token": token }
                    ))
                } catch (error) {
                    Sentry.captureException("Fail to access local storage with error: ", error)
                }
            }
            this.$router.push({ name: 'app' })
        }
    },
    template: '<div></div>'
}

</script>