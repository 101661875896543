<template>
  <div
    class="select-card text-center"
    :class="{
      'border-danger': showErrorMessage
    }"
  >
    <h5
      class="page-title m-3"
      v-text="$t('credit_card_payment.title')"
    />
    <div v-if="loading">
      <h6
        class="mt-3"
        v-text="$t('credit_card_payment.loading')"
      />
      <b-spinner id="loading-spinner" />
    </div>
    <div
      v-if="plans && plans.point_name && primePaymentPlan.type"
      class="text-center"
    >
      <div class="info-container">
        <div
          v-if="primePaymentPlan.type == 'campaign'"
          class="info-box"
        >
          <span v-text="campaignFreeTimeText()" />
          <span style="color: green">
            {{
              primePaymentPlan.type === 'campaign' && primePaymentPlan.optIn
                ? $t('price_table.free_star')
                : $t('price_table.free')
            }}
          </span>
        </div>

        <div
          v-if="primePaymentPlan.type === 'campaign' && primePaymentPlan.optIn"
          class="checkbox-container"
        >
          <b-form-checkbox
            id="checkbox-1"
            v-model="campaignOptInStatus"
            class="mx-4"
          >
            <div
              class="info-text-small"
              v-text="$t('home.take.campaign')"
            />
          </b-form-checkbox>
        </div>
        <div class="info-text mb-4 mt-1 mx-2">
          <span v-text="$t('credit_card_payment.agree')" />
          <span>
            <router-link
              to="#"
              @click="handleShowTablePrice"
            >
              <a v-text="$t('credit_card_payment.price_table')" />
            </router-link>
          </span>
        </div>

        <div class="info-text mb-4 mt-1 mx-3">
          <span v-text="$t('credit_card_payment.link_map')" />
          <span>
            <router-link to="/maps">
              <a v-text="$t('credit_card_payment.map')" />
            </router-link>
          </span>
        </div>
      </div>

      <div class="mt-4">
        <button
          class="btn btn-primary"
          @click="confirmPayment"
          v-text="createPixButtonText"
        />
      </div>
    </div>
    <b-form-group class="text-center mt-2">
      <button
        style="font-size: large"
        class="btn btn-md"
        @click="handleCancelWithPaymentMethodChange"
      >
        <u>
          <span
            style="margin-bottom: 40px"
            v-text="$t('credit_card_payment.change_payment')"
          />
        </u>
      </button>
    </b-form-group>

    <b-form-group
      class="text-center mt-2"
      :class="{
        'error-padding': showErrorMessage
      }"
    >
      <button
        style="font-size: large"
        :class="{
          'btn btn-md': !showErrorMessage,
          'btn btn-md mb-4': showErrorMessage
        }"
        @click="handleCancel"
      >
        <u>
          <span
            style="margin-bottom: 40px"
            v-text="$t('credit_card_payment.cancel')"
          />
        </u>
      </button>
    </b-form-group>
    <BRow v-if="showErrorMessage">
      <BCol>
        <div class="message-fail">
          <h5>{{ errorMessage }}</h5>
        </div>
      </BCol>
    </BRow>
  </div>
</template>

<script>
  import getPlans from '@/views/home/service/getPlans'

  export default {
    name: 'CreditCardPayment',
    props: {
      transactionId: {
        type: Number,
        required: false,
        default: null
      },
      cableType: {
        type: Number,
        required: false,
        default: null
      },
      user: {
        type: Object,
        required: true
      },
      pointId: {
        type: Number,
        required: true
      }
    },
    emits: ['cancel', 'handleShowPriceTable', 'confirmRentBattery', 'cancelToPaymentMethod'],
    data() {
      return {
        loading: false,
        errorMessage: '',
        showErrorMessage: false,
        plans: null,
        planHourly: null,
        planDaily: null,
        maxPlan: null,
        campaign: null,
        creditCardSecurityDeposit: null,
        campaignOptInStatus: false,
        primePaymentPlan: null
      }
    },
    computed: {
      createPixButtonText() {
        if (this.primePaymentPlan.type === 'campaign' && this.primePaymentPlan.optIn) {
          return (
            this.$t('credit_card_payment.create_pix') +
            ' ' +
            (this.campaignOptInStatus
              ? this.$t('credit_card_payment.create_pix_opt_in')
              : this.$t('credit_card_payment.create_pix_opt_out'))
          )
        } else {
          return this.$t('credit_card_payment.create_pix')
        }
      }
    },
    async mounted() {
      try {
        await this.pointPlan()
      } catch (error) {
        console.log(error)
      }
    },
    methods: {
      async pointPlan() {
        this.plans = await getPlans(this.pointId)

        if (this.plans && this.pointId !== undefined && this.pointId !== null) {
          this.planHourly = this.plans.price.find(prices => prices.internal_ref === 'four_hours')
          if (this.planHourly && this.planHourly.price_variation) {
            this.planHourly = this.applyPriceVariation(this.planHourly)
          }

          this.planDaily = this.plans.price.find(prices => prices.internal_ref === 'daily')
          if (this.planDaily && this.planDaily.price_variation) {
            this.planDaily = this.applyPriceVariation(this.planDaily)
          }
          this.maxPlan = this.plans.price.find(prices => prices.internal_ref === 'quarterly')

          if (this.plans.campaign) {
            this.campaign = this.plans.campaign
            this.campaign.optIn = this.plans.campaign.need_opt_in
          }
          this.creditCardSecurityDeposit = this.planDaily.amount

          if (this.campaign) {
            this.primePaymentPlan = this.campaign
            this.primePaymentPlan.type = 'campaign'
          } else if (this.planHourly) {
            this.primePaymentPlan = this.planHourly
            this.primePaymentPlan.type = 'hourly'
          } else if (this.planDaily) {
            this.primePaymentPlan = this.planDaily
            this.primePaymentPlan.type = 'daily'
          } else {
            this.primePaymentPlan = this.planHourly
            this.primePaymentPlan.type = 'hourly'
          }
        }
      },
      applyPriceVariation(price) {
        const variationFactor = 1 + price.price_variation / 100
        const totalCentavos = Math.round(
          (parseInt(price.amount.reais) * 100 + parseInt(price.amount.centavos)) * variationFactor
        )
        price.amount.reais = Math.floor(totalCentavos / 100)
        price.amount.centavos = totalCentavos % 100
        return price
      },
      handleShowTablePrice() {
        this.$emit('handleShowPriceTable')
      },
      confirmPayment() {
        this.$emit('confirmRentBattery', this.campaignOptInStatus)
      },
      async handleCancel() {
        this.$emit('cancel', 'O pagamento pelo cartão de crédito foi cancelado')
      },
      async handleCancelWithPaymentMethodChange() {
        this.$emit('cancelToPaymentMethod', 'A forma de pagamento foi alterada')
      },
      campaignFreeTimeText() {
        const acumulatedHours = Math.floor(this.campaign.free_time / 60 / 60)
        const acumulatedMinutes = (this.campaign.free_time / 60) % 60
        if (acumulatedMinutes === 0) {
          return `${this.$t('price_table.until')} ${acumulatedHours} ${this.$t('price_table.hours')}: `
        } else if (acumulatedHours === 0) {
          return `${this.$t('price_table.until')} ${acumulatedMinutes} minutos: `
        } else {
          return `${this.$t('price_table.until')} ${acumulatedHours} ${this.$t('price_table.hours')} e ${acumulatedMinutes} minutos: `
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  @use '@/main';

  .btn-pix-copy-paste {
    margin-bottom: 20px;
    background-color: main.$color-energy-blue !important;
    color: white !important;
  }

  .image-qrcode {
    border: 3px solid main.$color-energy-blue !important;
    border-radius: 10px;
    margin: 20px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 200px;
    min-width: 60%;
  }

  .image-qrcode-none {
    display: none;
  }

  #loading-spinner {
    display: block;
    margin: 20px auto;
    color: main.$color-energy-orange;
  }

  .display-none {
    display: none;
  }

  .btn-pix {
    color: main.$color-energy-blue !important;
    background-color: white !important;
  }

  .error-message {
    color: red;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
  }

  .message-fail {
    width: 100%;
    padding: 0.2rem 1rem;
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: left;
    box-sizing: border-box;
    margin-top: 0.5rem;
    overflow: hidden;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background-color: red;
    border-radius: 0 0 5px 5px !important;
  }

  .border-danger {
    border: 2px solid red;
  }

  .error-padding {
    padding: 10px 10px 50px 10px !important;
  }

  .selectable-input {
    font-size: large;
    padding: 5px;
    user-select: text;
    cursor: text;
  }

  .default-link {
    color: #0066cc;
    text-decoration: underline;
    cursor: pointer;
  }

  .default-link:hover {
    color: #003399;
  }

  .info-container {
    margin: 5px 0px;
  }

  .info-box {
    font-weight: 600;
    font-size: large;
  }

  .info-text {
    font-size: medium;
  }

  .info-text-small {
    font-size: small;
    text-align: justify;
    font-weight: 400;
  }

  .checkbox-container {
    background-color: cornsilk;
    padding: 2px 10px;
    border-radius: 3px;
  }
</style>
