<template>
  <router-view />
</template>

<script>
import axios from 'axios'
import API from '@/services/api'

import * as Sentry from '@sentry/vue'
import fingerPrint from '@/services/fingerPrint'

export default {
  name: 'App',
  data() {
    return {
      language: 'pt-BR',
      external: false,
      token: null,
      terminalToken: null,
      pointName: null,
      pathname: null,
      search: null
    }
  },


  async created() {
    try {
      const fingerPrintUUID = await fingerPrint()
      API.defaults.headers.common['X-Trace-ID'] = fingerPrintUUID
    } catch (error) {
      Sentry.captureException('X-Trace-ID on header', error)
    }

    if (localStorage.getItem('token')) {
      try {
        axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')
      } catch (error) {
        Sentry.captureException('Fail to access local storage with error: ', error)
      }
    }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: main;
  src: url('@/assets/fonts/main.otf');
}

html,
body {
  margin: 0 !important;
  font-family: main;
  letter-spacing: 1px;
}
</style>
