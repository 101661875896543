import API from '@/services/api'
import handleError from '@/services/handleError.js'

export default async function defaultCard (user, cardId) {
    try {
      const response = await API.put('credit_card/set_default/' + cardId)

      if (response && response.data && response.data.success) {
        user.credit_cards.map(credit_card => {
          if (credit_card.id === cardId) {
            credit_card.default = true
          } else {
            credit_card.default = false
          }
          return credit_card
        })
      
      user.default_card = user.credit_cards.find((creditCard) => creditCard.id === cardId)
        return user
      } else {
        handleError('Error to set default card')
      }
        
    } catch (error) {
        handleError(error)
    }
}