import axios from 'axios'

axios.defaults.baseURL = import.meta.env.VITE_MOBILE_API

axios.defaults.headers.common['Content-Type'] = 'application/json'

const token = localStorage.getItem('token')

if (token) {
    axios.defaults.headers.common['Authorization'] = token
    axios.defaults.headers.common['withCredentials'] = true
}


const API = axios
export default API