import { TOKEN } from '@/constants.js'

export default {
  rentProcessingStart() {
    this.cleanPBoxProps()
    this.showPBox = true
    this.pBoxTitle = this.$t('home.take.take_battery')
    this.pBoxText = this.$t('rent_processing.start.text')
    this.showPBoxSpinner = true
  },
  rentProcessingVerifyTerminal() {
    this.cleanPBoxProps()
    this.showPBox = true
    this.pBoxTitle = this.$t('home.take.take_battery')
    this.pBoxText = this.$t('rent_processing.verify.text')
    this.showPBoxSpinner = true
  },
  rentProcessingConfirmation() {
    this.cleanPBoxProps()
    this.showPBox = true
    this.pBoxTitle = this.$t('home.take.take_battery')
    this.pBoxText = this.$t('rent_processing.confirmation.text')
    this.showPBoxCancelButton = false
    this.pBoxCancelButtonText = this.$t('home.take.cancel')
    this.showPBoxConfirmButton = true
    this.pBoxConfirmButtonText = this.$t('home.take.confirm')
    this.pBoxButtonConfirmation = async () => await this.sendPermission()
    this.pBoxButtonCancel = async () => {
      const confirmation = confirm(this.$t('rent_processing.confirmation.cancel_confirmation'))
      if (!confirmation) {
        return
      }
      const pix_payment = Boolean(this.user.pix_identification && !this.user.default_card)
      if (pix_payment) {
        alert(this.$t('rent_processing.confirmation.cancel'))
      }

      this.removeFromMiniBoxComponentList(TOKEN)
      await this.saveInvalidTransaction(this.$t('Usuário não confirmou a retirada'), this.transactionId, this.token, true)
      this.handleClosePBox()
    }
  },
  rentProcessingPayment() {
    this.cleanPBoxProps()
    this.showPBox = true
    this.pBoxTitle = this.$t('home.take.take_battery')
    this.pBoxText = this.$t('rent_processing.payment.text')
    this.showPBoxSpinner = true
  },
  rentProcessing() {
    this.cleanPBoxProps()
    this.showDemonstration = true
  },
  rentProcessingError(error) {
    this.cleanPBoxProps()
    this.showPBox = true
    this.pBoxTitle = ''
    this.pBoxText = `<p><b>${this.$t('rent_processing.error.text')}</b>${error || ''}</p>`
    this.showPBoxConfirmButton = true
    this.pBoxConfirmButtonText = this.$t('home.take.understand')
    this.pBoxButtonConfirmation = this.handleClosePBox
  },
  rentProcessingSuccess(message) {
    this.cleanPBoxProps()
    this.showPBox = true
    this.pBoxTitle = this.$t('home.take.take_battery')
    this.pBoxText = message
    this.showPBoxConfirmButton = true
    this.pBoxConfirmButtonText = this.$t('home.take.checkout')
    this.pBoxButtonConfirmation = this.handleTakeBatterySuccess
  },
  cleanPBoxProps() {
    this.showPBoxCancelButton = false
    this.showDemonstration = false
    this.pBoxCancelButtonText = ''
    this.showPBoxConfirmButton = false
    this.pBoxConfirmButtonText = ''
    this.showCheckboxPBox = false
    this.pBoxCheckBoxText = ''
    this.showPBoxSpinner = false
    this.pBoxSecondsToClose = 0
    this.pBoxShowLink = false
    this.handleOpenTable = null
    this.showTags = false
    this.pBoxCheckBoxValue = null
    this.pBoxButtonConfirmation = null
    this.pBoxButtonCancel = null
    this.pBoxClose = null
    this.pBoxClassification = false
  },

  closePBox() {
    this.showPBox = false
    this.cleanPBoxProps()
  }
}
