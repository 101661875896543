<template>
  <div
    class="select-card d-flex flex-column"
  >
    <h3
      class="page-title text-center pt-2"
    >
      {{ `${$t("rating_transaction.title")} ${pointName} ${token}` }}
    </h3>

    <div
      v-if="!showThanksMessage"
      class="text-center"
    >
      <div
        class="flex-star text-center"
        style="justify-content: center; margin: 10px;"
      >
        <div
          v-for="star in 5"
          :key="star"
        >
          <font-awesome-icon
            icon="fa-solid fa-star"
            :class="[
              star <= rating ?
                'fa-minor flex-star-on' :
                'fa-minor flex-star-off'
            ]"
            class="fa "
            @click="rating = star"
          />
        </div>
      </div>
      <textarea
        v-model="feedback"
        :placeholder="$t('rating_transaction.feedback')"
        style="width: 80%; height: 100px; align-self: center; border: 1px solid #ccc; border-radius: 5px; margin-top: 10px; padding: 10px;"
      />
      <div
        class="tile-actions d-flex justify-content-end"
      >
        <BButton
          class="button my-2"
          variant="primary"
          :disabled="feedback.length === 0 && rating === null"
          @click="confirmAction"
        >
          {{ $t('rating_transaction.send') }}
        </BButton>
      </div>
    </div>
    <div v-else>
      <p
        class="text-center"
        style="margin: 10px; font-size: 16px;"
      >
        {{ $t('evaluation_box.text_2') }}
      </p>
    </div>
  </div>
</template>

<script>

import API from '@/services/api'
import handleError from '@/services/handleError'

export default {
  name: 'RatingTransaction',
  props: {
    pointName: {
      type: String,
      default: ''
    },
    token: {
      type: String,
      default: ''
    },
    transactionId: {
      type: Number,
      default: 0
    }
  },
  emits: ['confirmAction'],
  data: function () {
    return {
      rating: null,
      showThanksMessage: false,
      feedback: ''
    }
  },
  methods: {
    confirmAction() {
      this.showThanksMessage = true
      this.sendRating()
      setTimeout(() => {
        this.$emit('confirmAction')
      }, 3000)
    },
    async sendRating() {
      let params = {
        transaction_id: this.transactionId,
        rating: this.rating,
        feedback: this.feedback
      }
      try {
        const response = await API.post('/transaction/evaluation', params)
        console.log(response)
      } catch (error) {
        handleError(error)
      }
    }
  }
}

</script>

<style scoped lang="scss">

@use "@/main.scss" as main;

.fa {
  font-size: 30px;
  margin-bottom: 1px;
  margin-right: 2px;
}

.flex-title-star {
  display: flex;
  justify-content: center;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 5px;
  align-self: center;
  font-size: large;

}

.flex-star {
  display: flex;
  justify-content: center;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 5px;
  align-self: center;
  font-size: x-large;

}

.flex-star-on {
  color: gold;
}

.flex-star-off {
  color: rgb(202, 202, 202);
}
  
</style>
