<template>
  <div v-if="result">
    <div class="my-3">
      <div class="flex-history-element">
        <div class="flex-history-icon">
          <font-awesome-icon
            icon="fa-solid fa-arrow-right-to-bracket"
            class="fa fa-rotate-90"
          />
        </div>
        <div class="flex-history-description">
          <div class="flex-history-title">
            Retirada:
          </div>
          <div
            v-if="result.take_point && result.take_point.name && result.take_point.address"
            class="flex-history-text"
          >
            {{ result.take_point.name }}, {{ result.take_point.address }}
          </div>
          <div class="flex-history-text">
            {{ result.take_date }}
          </div>
        </div>
      </div>
    </div>

    <div class="my-3">
      <div class="flex-history-element">
        <div class="flex-history-icon">
          <font-awesome-icon
            icon="fa-solid fa-clock-rotate-left"
            class="fa mirror"
          />
        </div>
        <div class="flex-history-description">
          <div
            class="flex-history-title"
            v-text="$t('transaction_info.time')"
          />
          <div class="flex-history-text">
            {{ rentTime(result) }}
          </div>
        </div>
      </div>
    </div>

    <div class="my-3">
      <div class="flex-history-element">
        <div class="flex-history-icon">
          <font-awesome-icon
            icon="fa-solid fa-arrow-up-from-bracket"
            class="fa"
          />
        </div>
        <div class="flex-history-description">
          <div
            class="flex-history-title"
            v-text="$t('transaction_info.return')"
          />
          <div class="flex-history-text">
            {{ returnBattery(result) }}
          </div>
          <div
            v-if="result.status !== 2"
            class="flex-history-text"
          >
            {{ result.return_date }}
          </div>
        </div>
      </div>
    </div>

    <div class="my-3">
      <div class="flex-history-element">
        <div class="flex-history-icon">
          <font-awesome-icon
            icon="fa-solid fa-dollar-sign"
            class="fa"
          />
        </div>
        <div class="flex-history-description">
          <div
            v-if="result.status === 2"
            class="flex-history-title"
            v-text="$t('transaction_info.reserve')"
          />
          <div
            v-else
            class="flex-history-title"
            v-text="$t('transaction_info.payment')"
          />
          <span>
            <span
              class="flex-history-text"
              :class="{
                'line-through': isRefunded(result)
              }"
              style="display: inline-block"
              v-html="price(result) + refundPrice(result)"
            />&nbsp
            <span
              v-if="isRefunded(result)"
              class="flex-history-text"
              style="display: inline-block"
            >
              {{ $t('transaction_info.refund') }}
            </span>
          </span>
            
          <div class="flex-history-text">
            {{ paymentType(result) }} 
          </div>
        </div>
      </div>
    </div>
  
    <!-- Classificação -->
    <div
      v-if="showTransactionRating"
      class="my-3"
    >
      <div class="flex-history-element">
        <div class="flex-history-icon">
          <font-awesome-icon
            icon="fa-solid fa-star"
            class="fa"
          />
        </div>
        <div class="flex-history-description">
          <div class="flex-history-title">
            Classificação:
          </div>
          <div
            v-if="result.rating"
            class="flex-history-star"
          >
            <div
              v-for="star in 5"
              :key="star"
            >
              <font-awesome-icon
                icon="fa-solid fa-star"
                :class="[star <= result.rating ? 'fa-minor flex-history-star-on' : 'fa-minor flex-history-star-off']"
                class="fa"
              />
            </div>
          </div>
          <div
            v-else
            class="flex-history-text"
          >
            Sem classificação
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { formatToBrazilianReal } from '@/utils/format'


const CREDIT_CARD_PAYMENT = 1
const PIX_PAYMENT = 3

const PRE_PAYMENY_APROVED = 2
const PRE_PAYMENT_REFUNDED = 6
const PAYMENT_REFUNDED_STATUS = "Refunded"

export default {
  name: 'TransactionDetail',
  props: {
    result: {
      type: Object,
      default: null
    },
    showTransactionRating: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    price (result) {
      if (result.free_time_type === "Signature") {
        return "Grátis pela assinatura"
      }
      if (result.current_price !== null && result.current_price !== undefined && result.current_price !== 0) {
        return "R$ " + parseFloat(result.current_price).toFixed(2).replace('.', ',')
      }
      
      const isCreditCardTransaction = result.payment && result.payment.type === "credit_card"
      const isTransactionCaptured = result.payment && ['Approved', 'Captured'].includes(result.payment.status)
      const isPrePaymentCaptured = result.last_pre_payment && result.last_pre_payment.status === PRE_PAYMENY_APROVED
      const isPixPayment = result.payment && result.payment.type === "pix"
      const isPixPrePayment = result.last_pre_payment && result.last_pre_payment.type === PIX_PAYMENT
      const isPartialCaptured = result.payment && result.payment.status === 'Partial'
      const hasAmount = result.payment && result.payment.amount
      const hasAmountPrePayment = result.last_pre_payment && result.last_pre_payment.amount

      const isCreditCardPrePaymentRefunded = result.last_pre_payment && result.last_pre_payment.status === PRE_PAYMENT_REFUNDED

      const isPrePaymentAuthorized = result && result.last_pre_payment && result.last_pre_payment.status === 11

      if ((isCreditCardTransaction || isPixPayment ) && (isTransactionCaptured || isPartialCaptured ) && hasAmount) {
        return formatToBrazilianReal(result.payment.amount)
      }

      if ((isCreditCardPrePaymentRefunded || isPixPrePayment || isPrePaymentCaptured) && hasAmountPrePayment) {
        return formatToBrazilianReal(result.last_pre_payment.amount)
      }

      if (isPrePaymentAuthorized && hasAmountPrePayment) {
        return formatToBrazilianReal(result.last_pre_payment.amount) + " (caução)"
      }
      return formatToBrazilianReal(0)
  },
  refundPrice (result) {
    const isPaymentApproved = result.payment && result.payment.status === 'Approved'
    const paymentAmount = result.payment && result.payment.amount
    const prePaymentAmount = result.last_pre_payment && result.last_pre_payment.amount
    const isPartialRefund = paymentAmount && prePaymentAmount && prePaymentAmount > paymentAmount
    if (isPaymentApproved && isPartialRefund) {
      const prePaymentFormatted = parseFloat(prePaymentAmount).toFixed(2).replace('.', ',');
      const refundAmountFormatted = parseFloat(prePaymentAmount - paymentAmount).toFixed(2).replace('.', ',');
      return ` (R$ ${prePaymentFormatted} caução e R$ <s>${refundAmountFormatted}</s> devolvido)`;
    }
    return ''
  },
  isRefunded (result) {
    if (result.payment.type === "pix") {
      return result?.payment?.status === PAYMENT_REFUNDED_STATUS || result?.payment?.status === PRE_PAYMENT_REFUNDED
    }
    return result?.last_pre_payment?.status === PRE_PAYMENT_REFUNDED && result?.payment?.status !== 'Approved'
  },
  paymentType (result) {
    const isCreditCardPayment = result.payment && result.payment.type === "credit_card"
    const isPixPayment = result.payment && result.payment.type === "pix"
    const isCreditCardPrePayment = result.last_pre_payment && result.last_pre_payment.type === CREDIT_CARD_PAYMENT
    const isPixPrePayment = result.last_pre_payment && result.last_pre_payment.type === PIX_PAYMENT
    if (isCreditCardPayment || isCreditCardPrePayment) {
      return`Cartão de crédito final: ${result.credit_card.number}`
    } else if (isPixPayment || isPixPrePayment) {
      return`PIX`
    }
  },
  rentTime(result) {
    return result.status === PRE_PAYMENY_APROVED
      ? result.cron_time
      : result.rent_time.all
  },
  returnBattery(result) {
    return result.status !== PRE_PAYMENY_APROVED
      ? `${result.return_point.name }, ${ result.return_point.address }`
      : "Bateria com o cliente"
    }
  }
}
  
</script>
  
<style scoped lang="scss">
  @use '@/main';
  
  .fa-rotate-90 {
    transform: rotate(90deg);
  }
  
  .flex-history-element {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  
  .flex-history-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 80px;
    max-width: 80px;
  }
  
  .flex-history-description {
    display: flex;
    flex-direction: column;
    color: black;
  }
  
  .flex-history-title {
    margin: 0 !important;
    padding: 0 !important;
  }
  
  .flex-history-text {
    color: gray;
    font-size: large;
    margin: 0 !important;
    padding: 0 !important;
  }
  
  .flex-history-star {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 5px;
  }
  
  .flex-history-star-on {
    color: rgb(107, 107, 107);
  }
  
  .flex-history-star-off {
    color: rgb(202, 202, 202);
  }
  
  .mirror {
    transform: scaleX(-1);
  }
  
  .fa {
    font-size: 30px;
    margin-bottom: 1px;
    margin-right: 2px;
  }
  
  .fa-minor {
    font-size: 30px;
  }

  .line-through {
    text-decoration: line-through;
  }
</style>
  