const ErrorCodes = Object.freeze({
  INTERNAL_SERVER_ERROR: 1000,
  TERMINAL_OFFLINE: 1001,
  INVALID_TOKEN: 1002,
  CANNOT_REMOVE_BATTERY: 1003,
  NO_BATTERY_WITH_CABLE: 1004,
  NO_CHARGED_BATTERY: 1005,
  NO_PAYMENT_TYPE: 1006,
  TERMINAL_NOT_PARTICIPATING: 1007,
  POINT_NOT_FOUND: 1008,
  NO_CHARGED_BATTERIES: 1009,
  NO_CREDIT_CARD: 1010,
  PAYMENT_PROCESSING_ERROR: 1011,
  PENDING_PAYMENT: 1012,
  REQUEST_IN_PROGRESS: 1013,
  TRANSACTION_NOT_FOUND: 1014,
  TRANSACTION_NOT_VALIDATED: 1015,
  SELECT_CABLE: 1016,
  INVALID_CABLE_TYPE: 1017,
  CANCELED_TRANSACTION: 1018,
  NO_DEVICE_ID: 1019,
  INVALID_ACTION: 1020,
  UNAUTHORIZED_PAYMENT: 1021,
  PAYMENT_TIMEOUT: 1022,
  INVALID_METHOD: 1023,
  BATTERY_STATUS_ERROR: 1024,
  ERROR_DELETING_CREDITCARD: 1025,
  ERROR_REGISTERING_CREDITCARD: 1026,
  VALIDATION_ERROR: 1027,
  TRANSACTION_ONGOING: 1028,
  ERROR_SENDING_COMMAND: 1029,
  INVALID_PARAMETERS: 1030,
  PLAN_ALREADY_EXISTS: 1031,
  PENDING_RENEWAL_PAYMENT: 1032,
  NOT_APROVED_PAYMENT: 1033,
  PIX_PAYMENT_NOT_VALIDATED: 1034,
  SESSION_CREATE_ERROR: 1035,
  SESSION_INVALID_PARAMETER: 1036,
  INVALID_EVENT_FORMAT: 1037,
  SESSION_USER_DIFFERENT: 1038,
  SESSION_ALREADY_LOGGED: 1039,
  PAYMENT_API_FAILED: 1040,
  PAYMENT_WITHOUT_PRICE: 1041,
  REGISTER_FIELDS_ERROR: 1042,
  ERROR_REGISTER_CUSTOMER: 1043,
  REGISTER_CODE_VALIDATION: 1044,
  REGISTER_CODE_INVALID: 1045,
  PIX_REGISTRATION_ERROR: 1046,
  ERROR_UPDATE_PIX_REGISTRATION: 1047,
  TERMINAL_NOT_FOUND: 1048,
  INVALID_TOKEN_SIZE: 1049,
  BATTERY_NOT_AVAILABLE_TO_SWAP: 1050,
  START_DATE_RESERVATION_ERROR: 1051,
  END_DATE_RESERVATION_ERROR: 1052,
  START_END_DATE_RESERVATION_ERROR: 1053,
  NEGATIVE_KWH_VALUE: 1054,
  EMAIL_ALREADY_REGISTERED: 1055,
  INVALID_EMAIL: 1056,
  INVALID_PASSWORD: 1057,
  TOO_SHORT_PASSWORD: 1058,
  START_DATE_RESERVATION_IN_PAST_ERROR: 1059,
  END_DATE_RESERVATION_IN_PAST_ERROR: 1060,
  DATE_RANGE_ALREADY_RESERVED: 1061,
  RESERVATION_FAILED: 1062,
  RESERVATION_NOT_FOUND: 1063,
  RESERVATION_FORBIDEN_TO_DELETE: 1064,
  INVALID_SWAP_TRANSACTION_ID: 1065,
  SWAP_TRANSACTION_NOT_ALLOWED: 1066,
  SWAP_TRANSACTION_ALREADY_USED: 1067,
  SWAP_TRANSACTION_OTHER_USER: 1068,
  NO_BATTERY_FOR_PHONE: 1069,
  NO_ONE_CHARGED_BATTERY: 1070
})

export default ErrorCodes
