import i18n from '../i18n'

export function formatToBrazilianReal(value) {
    if (!value) {
        value = 0
    }
    return value.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    })
}
export function convertMinutesToHours(totalSeconds, showSeconds = false) {
  const { t } = i18n.global

  const formatValue = (value, unitKey, pad = false) => {
    if (value === 0 && unitKey === 'minute') {
      return ''
    }
    return value > 0 ? `${pad ? String(value).padStart(2, '0') : value} ${t(`time.${unitKey}`, value)}` : ''
  }

  if (totalSeconds <= 0) {
    return '0 ' + t('time.minute', 0)
  }

  const days = Math.floor(totalSeconds / 86400)
  const remainingSecondsAfterDays = totalSeconds % 86400

  const hours = Math.floor(remainingSecondsAfterDays / 3600)
  const remainingSecondsAfterHours = remainingSecondsAfterDays % 3600
  const minutes = Math.floor(remainingSecondsAfterHours / 60)
  const seconds = remainingSecondsAfterHours % 60

  const formattedDays = formatValue(days, days > 1 ? 'days' : 'day')
  const formattedHours = formatValue(hours, hours > 1 ? 'hours' : 'hour', true)
  const formattedMinutes = formatValue(minutes, minutes > 1 ? 'minutes' : 'minute')
  const formattedSeconds = formatValue(seconds, seconds > 1 ? 'seconds' : 'second', true)

  if (minutes === 0 && !showSeconds) {
    return formattedSeconds
  }

  if (!showSeconds) {
    return `${formattedDays} ${formattedHours} ${formattedMinutes}`
  }

  return `${formattedDays} ${formattedHours} ${formattedMinutes} ${formattedSeconds}`
}