import { CABLE_TYPE_LIGHTNING, CABLE_TYPE_MICRO, CABLE_TYPE_USBC, MANAGE_PAYMENT, SELECT_CABLE_TYPE, CABLE_TYPE, TOKEN } from '@/constants.js'

export default function getPropsMiniComponents(component) {
  switch (component) {
    case MANAGE_PAYMENT: {
      const CARD_NUMBER_LENGTH = 4
      if (this.user && this.user.default_card && this.user.default_card.number.length === CARD_NUMBER_LENGTH) {
        return {
          subText:
            this.$t('home.take.card_ending') +
            ' ' +
            (this.user.default_card && this.user.default_card.number
              ? this.user.default_card.number + ' ' + this.$t('select_payment_method.credit')
              : ''),
          allowEdit: !(this.showPBox || !this.allowEdit),
          arrowDown: !(this.selectedBigBoxComponent && this.selectedBigBoxComponent.reference === MANAGE_PAYMENT)
        }
      } else if (this.user && this.user.pix_identification) {
        return {
          subText:
            this.user && this.user.pix_identification && this.user.pix_identification.cpf_or_cnpj
              ? formatCNPJorCPF(this.user.pix_identification.cpf_or_cnpj)
              : 'PIX',
          allowEdit: !(this.showPBox || !this.allowEdit)
        }
      } else {
        return {
          subText: this.$t('manage_payment.none.subtitle'),
          allowEdit: true
        }
      }
    }
    case CABLE_TYPE:
      return {
        subText: defaultCableText(this.cableType, this),
        allowEdit: !(this.showPBox || !this.allowEdit),
        arrowDown: !(this.selectedBigBoxComponent && this.selectedBigBoxComponent.reference === SELECT_CABLE_TYPE)
      }
    case TOKEN:
      return {
        subText: `${this.$t('token_selection.local')} (${this.pointName} - ${this.token})`,
        allowEdit: !(this.showPBox || !this.allowEdit)
      }
    default:
      return console.log('Component not found')
  }
}

function defaultCableText(cableType, context) {
  if (cableType === CABLE_TYPE_LIGHTNING) {
    return 'Apple Lightning' + ' ' + context.$t('cable_type.mini_box.cable_title')
  } else if (cableType === CABLE_TYPE_MICRO) {
    return 'Micro USB' + ' ' + context.$t('cable_type.mini_box.cable_title')
  } else if (cableType === CABLE_TYPE_USBC) {
    return 'USB-C' + ' ' + context.$t('cable_type.mini_box.cable_title')
  }
  return null
}

function formatCNPJorCPF(value) {
  value = value.toString().replace(/\D/g, '')
  const CPF_LENGTH = 11
  const CNPJ_LENGTH = 14
  if (value.length === CPF_LENGTH) {
    return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.***.***-$4') + ' ' + '(PIX)'
  } else if (value.length === CNPJ_LENGTH) {
    return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.***.***/***-$5') + ' ' + '(PIX)'
  }
  return value
}
