import axios from 'axios'

import { sessionRecorder } from '@/services/sessionRecorder'

const LogoutComponent = {
  name: 'logout-component',
  components: {},
  mounted: function () {
    this.logout()
  },
  methods: {
    logout: function () {
      localStorage.removeItem('token')
      sessionStorage.removeItem('campaign')
      sessionStorage.removeItem('campaignUrl')
      localStorage.removeItem('terminal_token')
      sessionRecorder.stopRecordingSession()
      axios.defaults.headers.common['Authorization'] = null
      this.$router.push({
        name: 'app', // Update to login-social later
        params: {}
      })
    }
  }
}

export default LogoutComponent
