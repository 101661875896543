<template>
  <!-- Box transition -->
  <div
    class="indv-card select-card"
    :style="backgroundStyle"
  >
    <div class="bv-example-row">
      <BRow @click="handleClick">
        <!-- Left Image -->
        <BCol
          key="image"
          cols="2"
          class="d-flex justify-content-center align-items-center left-content"
        >
          <!-- Contêiner restrito -->
          <div class="image-wrapper">
            <slot name="left-content" />
          </div>
        </BCol>

        <!-- Middle Text -->
        <BCol
          key="text"
          :cols="allowEdit ? 8 : 10"
          class="d-flex justify-content-left align-items-center"
        >
          <div class="fw-normal text-lighter text-container">
            <p
              class="page-title fw-bold d-inline-block text-lighter no-margin"
              v-text="subText"
            />
          </div>
        </BCol>

        <!-- Right Button -->
        <BCol
          v-if="allowEdit"
          key="text"
          cols="2"
          class="d-flex justify-content-end"
        >
          <div class="btn d-flex justify-content-end align-items-center arrow">
            <font-awesome-icon
              v-if="arrowDown"
              class="btn-icon-arrow fa-2x"
              icon="fa-solid fa-chevron-down"
            />
            <font-awesome-icon
              v-else
              class="btn-icon-arrow fa-2x"
              icon="fa-solid fa-chevron-up"
            />
          </div>
        </BCol>
      </BRow>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DefaultTileHome',
  props: {
    mainText: {
      type: String,
      required: false,
      default: ''
    },
    subText: {
      type: String,
      required: false,
      default: ''
    },
    termsText: {
      type: String,
      required: false,
      default: ''
    },
    backgroundImage: {
      type: String,
      required: false,
      default: ''
    },
    allowEdit: {
      type: Boolean,
      default: true
    },
    arrowDown: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    backgroundStyle() {
      return this.backgroundImage ? { backgroundImage: `url(${this.backgroundImage})` } : {}
    }
  },
  methods: {
    handleClick() {
      if (this.allowEdit) {
        this.$emit('clicked')
      }
    }
  }
}
</script>

<style scoped lang="scss">
@use '@/main';

.indv-card {
  justify-content: center;
  height: 60px !important;
  margin: 5px auto !important;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.text-container {
  padding: 0;
  margin: 0;
}

.btn-icon-arrow {
  margin: 0;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.row>.col-4,
.row>.col-6,
.row>.col-2 {
  flex: 1 1 auto;
}

.arrow {
  outline: none;
  border: none;
}

.arrow:focus {
  outline: none;
}

.arrow:active {
  outline: none;
  border: none;
}

.left-content {
  position: relative;
}

.image-wrapper {
  max-width: 100%;
  overflow: hidden;
}

.image-wrapper img {
  position: absolute;
  max-width: none;
  height: auto;
}
</style>
