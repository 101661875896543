<div
  id="home-master-div"
  :style="{ background: 'white', color: '#424242' }"
>
  <SideBar
    :name="user && user.name"
    :email="user && user.email"
    @priceTable="scrollToPriceTable"
  />

  <div
    class="d-flex justify-content-center"
    v-if="!user"
  >
    <BSpinner class="loading-spinner mt-5" />
  </div>

  <CheckOutScreen
    v-if="user && lastTransaction && lastTransactionStatus === 'take_battery_success'"
    :name="user.name"
    :email="user.email"
    :user="user"
    :lastTransaction="lastTransaction"
    :campaign="campaign"
    :cableType="cableType"
    :showPriceTable="showPriceTable"
    :pointId="pointId"
    @scrollToPriceTable="scrollToPriceTable"
  />

  <div
    v-else
    class="default-container"
  >
    <div class="default-container-start">
      <EvaluationBox
        v-if="showEvaluationTags"
        :transactionId="transactionId"
        @close="() => {
          this.showEvaluationTags = false
          this.selectBigBoxComponent()
        }"
      />
      <RatingTransaction
        v-if="lastTransaction && showRatingTransaction"
        :pointName="lastTransaction.return_point.name"
        :token="lastTransaction.token"
        :transactionId="lastTransaction.id"
        @confirmAction="handleShowRatingTransaction"
      />
      <LastTransactionInfo
        v-if="lastTransaction && showLastTransactionInfo"
        :lastTransaction="lastTransaction"
        @confirmAction="handleCloseLastTransactionInfo"
      />

      <DefaultTile
        :showDefaultTile="showPBox"
        :title="pBoxTitle"
        :text="pBoxText"
        :showCancelButton="showPBoxCancelButton"
        :cancelButtonText="pBoxCancelButtonText"
        :showConfirmButton="showPBoxConfirmButton"
        :confirmButtonText="pBoxConfirmButtonText"
        :showCheckBox="showCheckboxPBox"
        :checkBoxText="pBoxCheckBoxText"
        :showSpinner="showPBoxSpinner"
        :secondsToClose="pBoxSecondsToClose"
        :showLink="pBoxShowLink"
        @handleOpenTable="scrollToPriceTable"
        @checkBoxValue="pBoxCheckBoxValue"
        @confirm="pBoxButtonConfirmation"
        @cancel="pBoxButtonCancel"
        @close="handleClosePBox"
      >
        <template #text>
          <div v-html="pBoxText"></div>
        </template>
      </DefaultTile>
      <div
        :ref="selectedBigBoxComponent.reference"
        v-if="showBigBoxComponent && selectedBigBoxComponent"
      >
        <component
          :is="selectedBigBoxComponent.name"
          v-bind="getPropsBigComponents(selectedBigBoxComponent.reference)"
          v-on="getEventsBigComponents(selectedBigBoxComponent.reference)"
        />
      </div>

      <TakeDemonstration
        v-if="showDemonstration"
        :totalSlots="totalSlots"
        :selectedSlot="selectedSlot"
      />

      <div :ref="PRICE_TABLE">
        <PriceTable
          v-if="showPriceTable"
          class="select-card margin-important"
          :showPriceTable="showPriceTable"
          :pointId="pointId"
          :allowEdit="allowEdit"
          @close="scrollToPriceTable"
          @handleTokenSelection="selectBigBoxComponent(TOKEN_SELECTION)"
        />
      </div>

      <!-- Mini Box -->
      <component
        v-for="component in showMiniBoxComponentList"
        :key="component.id"
        :is="component.name"
        :ref="component.reference"
        v-bind="getPropsMiniComponents(component.reference)"
        v-on="getEventsMiniComponents(component.reference)"
      >
        <template #left-content>
          <div class="left-content-wrapper">
            <!-- Condições para imagens -->
            <img
              v-if="
                cableType === 0 &&
                component.reference === CABLE_TYPE
              "
              class="cable-img"
              src="@/assets/images/cables/apple_2.png"
              alt="imagem conector USB tipo C"
            />
            <img
              v-if="
              cableType === 1 &&
              component.reference === CABLE_TYPE
            "
              class="cable-img"
              src="@/assets/images/cables/micro_2.png"
              alt="imagem conector USB tipo C"
            />
            <img
              v-if="
              cableType === 2 &&
              component.reference === CABLE_TYPE
            "
              class="cable-img"
              src="@/assets/images/cables/c_2.png"
              alt="imagem conector USB tipo C"
            />

            <!-- Condição para ícone -->
            <font-awesome-icon
              v-if="component.reference === TOKEN"
              :style="{color: '#424242'}"
              class="fa-2x"
              icon="location-dot"
            />
          </div>
        </template>
      </component>

      <!-- Management Payment -->
      <UserDefaults
        v-if="!showManagePayment"
        :ref="MANAGE_PAYMENT"
        v-bind="getPropsMiniComponents(MANAGE_PAYMENT)"
        v-on="getEventsMiniComponents(MANAGE_PAYMENT)"
      >
        <template #left-content>
          <font-awesome-icon
            v-if="user && user.default_card"
            class="fa-2x"
            icon="fa-credit-card"
          />
          <font-awesome-icon
            v-else-if="user && user.pix_identification"
            class="fa-2x"
            icon="fa-brands fa-pix"
          />
          <font-awesome-icon
            v-else
            class="fa-2x"
            icon="fa-dollar-sign"
          />
        </template>
      </UserDefaults>
      <div
        :ref="MANAGE_PAYMENT"
        class="select-card margin-important-2"
        :class="{ 'hide': !showManagePayment }"
      >
        <ManagePayment
          v-if="showManagePayment"
          user="user"
          v-bind="getPropsBigComponents(MANAGE_PAYMENT)"
          v-on="getEventsBigComponents(MANAGE_PAYMENT)"
        />
      </div>
    </div>
  </div>
</div>
