import { createRouter, createWebHistory } from 'vue-router'
import app from '@/App.vue'
import loginSocial from '@/views/login-social/login-social.vue'
import home from '@/views/home/home.vue'
import signUp from '@/views/sign-up/sign-up.vue'
import codeValidation from '@/views/code-validation/CodeValidation.vue'
import logout from '@/views/logout/logout-session.vue'
import confirmAccount from '@/views/confirm_account/confirm_account.vue'
import history from '@/views/history/history.vue'
import campaign from '@/views/campaign/campaign-component.vue'
import TokenRedirect from '@/components/TokenRedirect.vue'

import maps from '@/views/maps/maps.vue'

function isAuthenticated() {
  return !!localStorage.getItem('token')
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'app',
      component: app,
      meta: {
        public: true
      }
    },
    {
      path: '/home',
      name: 'home',
      component: home,
      meta: {
        public: false
      }
    },
    {
      path: '/sign-up',
      name: 'sign_up',
      component: signUp,
      meta: {
        public: true
      }
    },
    {
      path: '/login-social',
      name: 'login_social',
      component: loginSocial,
      meta: {
        public: true
      }
    },
    {
      path: '/code-validation',
      name: 'code_validation',
      component: codeValidation,
      meta: {
        public: true
      }
    },
    {
      path: '/logout',
      name: 'logout',
      component: logout,
      meta: {
        public: true
      }
    },
    {
      path: '/confirm-account/:code',
      name: 'confirm_account',
      component: confirmAccount,
      meta: {
        public: true
      }
    },
    {
      path: '/vw/:token?',
      name: 'campaign',
      component: campaign,
      meta: {
        public: true
      }
    },
    {
      path: '/maps',
      name: 'maps',
      component: maps,
      meta: {
        public: true
      }
    },
    {
      path: '/history',
      name: 'history',
      component: history,
      meta: {
        public: false
      }
    },
    {
      path: '/qr/:token',
      name: 'qr',
      component: TokenRedirect,
      meta: {
        public: true
      }
    }
  ]
})


router.beforeEach((to, from, next) => {
  const isAuth = isAuthenticated()

  if (to.name === 'app' && isAuth) {
    next({ name: 'home' })
    return
  }

  if (to.name === 'app' && !isAuth) {
    next({ name: 'login_social' })
    return
  }

  if (!to.meta.public && !isAuth) {
    next({ name: 'login_social' })
    return
  }

  if (to.name === 'login_social' && isAuth) {
    next({ name: 'home' })
    return
  }

  next()
})

export default router
