import './assets/main.scss'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'

import * as Sentry from '@sentry/vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faClock,
  faBatteryQuarter,
  faCheckCircle,
  faMapLocationDot,
  faRectangleXmark,
  faBars,
  faPlug,
  faCircleCheck,
  faMoneyBill,
  faAngleDown,
  faAngleUp,
  faXmark,
  faArrowRightToBracket,
  faArrowUpFromBracket,
  faClockRotateLeft,
  faDollarSign,
  faCopy,
  faCreditCard,
  faTrash,
  faCheck,
  faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
import {
  faStar
} from '@fortawesome/free-regular-svg-icons'
import {
  faFacebook,
  faGoogle,
  faWhatsapp,
  faCcMastercard,
  faPix
} from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import FBSignInButton from 'vue-facebook-signin-button'
import VueTheMask from 'vue-the-mask'
import { createBootstrap } from 'bootstrap-vue-next'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import { HFaceBookLogin } from '@healerlab/vue3-facebook-login'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import VueScrollTo from 'vue-scrollto'
import vue3GoogleLogin from 'vue3-google-login'
import VueGtag from 'vue-gtag'
import OneSignalVuePlugin from '@onesignal/onesignal-vue3'
import { formatToBrazilianReal, convertMinutesToHours } from '@/utils/format'
import { sessionRecorder, UserEvent } from './services/sessionRecorder'
import { experimentInstance }from '@/utils/experiments'


const app = createApp(App)

app.use(createBootstrap())
app.use(i18n)
app.use(router)
app.use(FBSignInButton)
app.use(VueTheMask)
app.use(VueScrollTo)

app.use(OneSignalVuePlugin, {
  appId: import.meta.env.VITE_ONE_SIGNAL_APP_ID,
  allowLocalhostAsSecureOrigin: true,
  requiresUserPrivacyConsent: true,
})

app.use(VueGtag, {
  config: { id: import.meta.env.VITE_GOOGLE_GTAG_ID }
})

library.add(
  fas,
  faCheckCircle,
  faClock,
  faBatteryQuarter,
  faFacebook,
  faGoogle,
  faWhatsapp,
  faMapLocationDot,
  faRectangleXmark,
  faBars,
  faCircleCheck,
  faCcMastercard,
  faMoneyBill,
  faAngleDown,
  faAngleUp,
  faPlug,
  faXmark,
  faArrowRightToBracket,
  faArrowUpFromBracket,
  faClockRotateLeft,
  faDollarSign,
  faStar,
  faPix,
  faCopy,
  faCreditCard,
  faTrash,
  faCheck,
  faTriangleExclamation
)

if (import.meta.env.VITE_GOOGLE_MAPS_API_KEY) {
  app.use(VueGoogleMaps, {
    load: {
      key: import.meta.env.VITE_GOOGLE_MAPS_API_KEY
    }
  })
}

if (import.meta.env.VITE_GOOGLE_CLIENT_ID) {
  app.use(vue3GoogleLogin, {
    clientId: import.meta.env.VITE_GOOGLE_CLIENT_ID
  })
}

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  })
}


(async () => {
  try {
      const sessionId = sessionStorage.getItem('session_id')
      sessionRecorder.startSession()
      if (!sessionId) {
        sessionRecorder.addUserEvent(new UserEvent('start_session', null, null, null))
      } else {
        sessionRecorder.addUserEvent(new UserEvent('restart_session', null, null, null))
      }
      await sessionRecorder.startRecording()
      await experimentInstance.fetch(sessionRecorder.sessionId)
  } catch (error) {
    console.error('Erro:', error)
  }
  app.component('FontAwesomeIcon', FontAwesomeIcon)
  app.component('HFaceBookLogin', HFaceBookLogin)
  app.config.globalProperties.formatToBrazilianReal = formatToBrazilianReal
  app.config.globalProperties.convertMinutesToHours = convertMinutesToHours
  app.mount('#app')
})()
