import API from '@/services/api'
import handleError from '@/services/handleError'

export default async function getUser(position = null) {
  let url
  if (position && position.lat && position.lng) {
    url = `customer/me/${position.lat}/${position.lng}`
  } else {
    url = 'customer/me'
  }

  try {
    const response = await API.get(url)

    if (!response || !response.data) {
      throw new Error('No data in response')
    }

    return response.data
  } catch (error) {
    handleError(error)
  }
}
