<template>
  <div 
    class="row align-items-center justify-content-center flex-nowrap rounded p-1 mx-1 mt-3"
    :style="{ backgroundColor, borderRadius: '10px' }"
  >
    <div
      :class="`col-${colWidths[0]} p-2 d-flex justify-content-center align-items-center`"
      :style="`font-size: 15px; opacity: ${iconOpacity}%;`"
    >
      <slot name="left" />
    </div>

    <div
      :class="`col-${colWidths[1]} p-2 d-flex justify-content-center align-items-center`"
      style="font-size: 15px;"
    >
      <slot name="center" />
    </div>
    <!-- Botão à direita -->
    <div :class="`col-${colWidths[2]} p-2 d-flex justify-content-center align-items-center`">
      <slot name="right" />
    </div>
  </div>
</template>

<script>
export default {
    name: 'ListElement',
    props: {
        colWidths: {
            type: Array,
            default: () => [2, 6, 4]
        },
        backgroundColor: {
            type: String,
            default: '#f8f9fa'
        },
        iconOpacity: {
            type: Number,
            default: 85
        }
    }
}
</script>
