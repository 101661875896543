<section
  id="login-back"
  class="inner-section"
>
  <img
    src="@/assets/images/logo.png"
    alt=""
    id="logo"
  />
  <div
    id="login-div"
    class="blue-background"
  >
    <div class="vue-template text-center">
      <div class="text-center">
        <div>
          <h3
            class="navbar-text mb-0"
            v-text="$t('login.sign_in')"
          ></h3>
        </div>

        <div class="error-container">
          <p
            v-if="error"
            class="errors"
          >
            <b>{{ error }} </b>
          </p>
        </div>

        <div class="validation-boxes">
          <div
            class="mb-0 gap"
            v-for="(_, index) in code"
            :key="index"
          >
            <input
              :id="'id_' + index"
              maxlength="VALIDATION_CODE_LENGTH"
              required
              v-model="code[index]"
              type="text"
              autocapitalize="none"
              @input="onInput(index, $event)"
              @keydown="onKeyDown(index, $event)"
              @focus="onFocus($event)"
              @click="onClick($event)"
              ref="inputs"
            />
          </div>
        </div>
        <h6
          class="mt-0 mb-5"
          v-text="$t('login.verify_mail')"
        ></h6>

        <div class="centered-div">
          <button
            id="validate_button"
            v-on:click="validateCode"
            class="btn btn-primary btn-lg btn-block"
          >
            Validar
          </button>
        </div>

        <div class="mt-3 m-auto width-250">
          <button
            v-on:click="routeSignUp"
            class="btn btn-primary btn-lg btn-block"
          >
            Reenviar email
          </button>
        </div>
      </div>

      <div
        class="mt-3 clearfix"
        id="account-options"
      >
        <p class="register-account text-center mt-2 mb-4">
          <span>
            <router-link
              class="link"
              to="/login-social"
              v-text="$t('login.register_social')"
            ></router-link>
          </span>
        </p>
      </div>
    </div>
  </div>
</section>
