<template>
  <div
    class="select-card d-flex flex-column"
    style="margin-bottom: 10px !important"
    :class="{ 'border-danger': errorValidation }"
  >
    <h3 class="page-title text-center pt-2">
      {{ $t('transaction_info.title') }}
    </h3>

    <section
      v-if="lastTransaction"
      class="text-center p-3 my-3"
    >
      <p class="mb-2">
        <strong v-text="$t('transaction_info.return_back_place')" />: {{ lastTransaction.rent_point.name }}
      </p>
      <p class="mb-2">
        <strong v-text="$t('transaction_info.usage_time')" />: {{ convertMinutesToHours(lastTransaction.elapsed_time) }}
      </p>
      <p
        v-if="lastTransaction?.payment?.refunded > 0"
        class="mb-2"
      >
        <strong
          class="text-strike"
          v-text="$t('transaction_info.charged_back_value')"
        />:
        <span class="text-decoration-line-through">{{ formatToBrazilianReal(lastTransaction.payment.refunded) }}</span>
      </p>
      <p
        v-if="lastTransaction?.payment?.amount > 0"
        class="mb-2"
      >
        <strong v-text="$t('transaction_info.payed_value')" />:
        {{ formatToBrazilianReal(lastTransaction.payment.amount) }}
      </p>
      <p
        v-else
        class="mb-2"
      >
        <strong v-text="$t('transaction_info.payed_value')" />: {{ formatToBrazilianReal(0) }}
      </p>
    </section>

    <div class="text-center my-3">
      <span v-text="$t('transaction_info.history_link')" />
      <span>
        <router-link to="/history">
          {{ $t('home.take.history') }}
        </router-link>
      </span>
    </div>

    <!-- Action Buttons -->
    <div
      class="tile-actions d-flex justify-content-end"
      :class="{ 'mb-4': errorValidation }"
    >
      <BButton
        class="button my-2"
        variant="primary"
        @click="confirmAction"
      >
        {{ $t('transaction_info.close') }}
      </BButton>
    </div>
    <BRow v-if="errorValidation">
      <BCol>
        <div class="message-fail">
          <h5>{{ messageErrorValidation }}</h5>
        </div>
      </BCol>
    </BRow>
  </div>
</template>

<script>
  import '@/main.scss'
  import { formatToBrazilianReal } from '@/utils/format'

  export default {
    name: 'LastTransactionInfo',
    props: {
      classification: {
        type: Boolean,
        default: true
      },
      lastTransaction: {
        type: Object,
        default: null
      }
    },
    emits: ['confirmAction'],
    data: function () {
      return {
        checkBoxInitValue: false,
        errorValidation: false,
        messageErrorValidation: '',
        rating: 0
      }
    },
    methods: {
      confirmAction() {
        this.$emit('confirmAction')
      }
    }
  }
</script>

<style scoped lang="scss">
  @use '@/main';

  .tile-actions {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }

  .flex-grow-1 {
    flex-grow: 1;
    display: flex;
    align-items: left;
    justify-content: center;
  }

  .page-text {
    text-align: justify;
    margin: 0;
    max-width: 90%;
  }

  #loading-spinner {
    display: block;
    color: #f58a48;
  }

  .message-fail {
    width: 100%;
    padding: 0.2rem 1rem;
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: left;
    line-height: 1;
    box-sizing: border-box;
    margin-top: 0.5rem;
    max-height: 2rem;
    overflow: hidden;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background-color: red;
  }
</style>
