import API from '@/services/api'
import handleError from '@/services/handleError'
import { RETURN_SUCCESS_LIST } from '@/constants.js'

export default async function getLastTransaction() {
  try {
    const response = await API.get('/transaction/last_rent')
    const STATUS_CODE = 200
    if (response.status !== STATUS_CODE) {
      console.log('Não foi possível obter a última transação')
      return
    }
    if (response && response.data) {
      const lastTransaction = response.data

      const now = new Date().getTime()
      const MILLISECONDS_PER_SECOND = 1000
      const past = now - lastTransaction.elapsed_time * MILLISECONDS_PER_SECOND
      const freeTime = lastTransaction.free_time * MILLISECONDS_PER_SECOND

      lastTransaction['on_free_time'] = false
      lastTransaction['next_payment'] = 0
      if (now - past < freeTime) {
        lastTransaction['on_free_time'] = true
        lastTransaction['next_payment'] = Math.floor((freeTime - (now - past)) / MILLISECONDS_PER_SECOND)
      }

      lastTransaction.batteryReturned = false
      if (lastTransaction.status === 'take_battery_success') {
        localStorage.setItem('lastTransaction', lastTransaction.id)
      }

      if (
        parseInt(localStorage.getItem('lastTransaction')) === lastTransaction.id &&
        RETURN_SUCCESS_LIST.includes(lastTransaction.status)
      ) {
        lastTransaction.batteryReturned = true
      }
      return lastTransaction
    }
  } catch (error) {
    handleError(error)
    return
  }
}
