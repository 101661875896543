// /* global fbq */
import API from '@/services/api'
import SelectCableType from '@/components/SelectCableType.vue'
import TokenSelection from '@/components/TokenSelection.vue'
import CreditCardRegistration from '@/components/CreditCardRegistration.vue'
import DefaultTile from '@/components/DefaultTile.vue'
import UserDefaults from '@/components/UserDefaults.vue'
import SideBar from '@/components/SideBar.vue'
import EvaluationBox from '@/components/EvaluationBox.vue'
import PriceTable from '@/components/PriceTable.vue'
import RentCheckout from '@/components/RentCheckout.vue'
import RentSuccess from '@/components/RentSuccess.vue'
import getPropsBigComponents from '@/views/home/service/bigBoxProps'
import getEventsBigComponents from '@/views/home/service/bigBoxEvents'
import getPropsMiniComponents from './service/miniBoxProps'
import getEventsMiniComponents from './service/miniBoxEvents'
import rentProcessingMethods from './service/rentProcessingMethods'
import CheckOutScreen from '@/views/checkout-screen/CheckOutScreen.vue'
import * as Sentry from '@sentry/vue'
import sendGtag from '@/services/sendGtags'
import fingerPrint from '@/services/fingerPrint.js'
import getLocation from '@/views/home/service/getLocation.js'
import getLastTransaction from '@/views/home/service/getLastTransaction.js'
import getUser from '@/views/home/service/getUser.js'
import getNearPointInARange from '@/views/home/service/getNearPoint.js'
import saveBatteryStatus from '@/views/home/service/saveBatteryStatus.js'
import SelectPaymentMethod from '@/components/SelectPaymentMethod.vue'
import CreditCardPayment from '@/components/CreditCardPayment.vue'
import setupOneSignal from './service/oneSignal.js'
import {
  TAKE_BATTERY_SUCCESS,
  TAKE_BATTERY_ERROR,
  BIG_BOX_COMPONENT_LIST,
  MINI_BOX_COMPONENT_LIST,
  RETURN_SUCCESS_LIST,
  MANAGE_PAYMENT,
  SELECT_CABLE_TYPE,
  TOKEN_SELECTION,
  PRICE_TABLE,
  PIX_PAYMENT,
  SELECT_PAYMENT_METHOD,
  CREDIT_CARD_PAYMENT,
  CABLE_TYPE,
  TOKEN,
  PIX_REGISTRATION,
  CREDIT_CARD_REGISTRATION
} from '@/constants.js'

import '@/main.scss'
import TakeDemonstration from '@/components/TakeDemonstration.vue'
import PixRegistration from '@/components/PixRegistration.vue'
import PixPayment from '@/components/PixPayment.vue'
import ManagePayment from '@/components/ManagePayment.vue'
import handleError from '@/services/handleError'
import sendDeviceInfo from '@/views/home/service/sendDeviceInfo'
import * as amplitude from '@amplitude/analytics-browser'
import getPointIdFromStorage from '@/services/getPointId'
import getPointByToken from '@/services/getPointName'
import { UserEvent, sessionRecorder } from '@/services/sessionRecorder'
import LastTransactionInfo from '@/components/LastTransactionInfo.vue'
import RatingTransaction from '@/components/RatingTransaction.vue'
import { EXPERIMENTS } from '@/constants'
import { experimentInstance } from '@/utils/experiments'

const USER_INFO_SHARING_PERMISSION_GRANTED = 2
const USER_INFO_SHARING_PERMISSION_REFUSED = 3

const CREDIT_CARD_RESERVATION_ALLOW = 2

const PAYMENT_TIMEOUTED = 17

const GTAG_PRICE_TABLE = 'open_price_table'
const GTAG_TAKE_BATTERY_SUCCESS = 'take_battery_success'

const PIX_PAYED = 2
const PIX_PENDING = 10

const CREDIT_CARD = 1
const PIX = 3

const NO_POSITION = -1
const SCROLL_TIME = 500
const TOKEN_LENGTH = 4
const ITEMS_TO_REMOVE = 1

export default {
  name: 'home',
  components: {
    SelectCableType,
    TokenSelection,
    TakeDemonstration,
    CreditCardRegistration,
    SideBar,
    DefaultTile,
    EvaluationBox,
    PriceTable,
    UserDefaults,
    RentCheckout,
    RentSuccess,
    CheckOutScreen,
    PixRegistration,
    PixPayment,
    ManagePayment,
    SelectPaymentMethod,
    CreditCardPayment,
    LastTransactionInfo,
    RatingTransaction
  },
  props: [],
  data() {
    return {
      MANAGE_PAYMENT,
      TOKEN_SELECTION,
      PRICE_TABLE,
      CABLE_TYPE,
      TOKEN,

      withPaymentScreen: experimentInstance.isExperimentEnabled(
        EXPERIMENTS.paymentMethod.experiment,
        EXPERIMENTS.paymentMethod.variants.defaultPaymentMethod
      ),
      interval: null,
      campaign: null,
      cableType: null,
      user: null,
      plans: null,
      lastTransaction: null,
      position: null,
      terminal: {
        campaign: false,
        far: false,
        noPayment: false
      },
      takeType: 'terminal',
      batteryLevel: null,
      batteryCharging: null,
      batteryTimeRemaining: null,
      transactionId: null,
      token: '',
      infoSharing: false,
      creditCardReservationAlways: false,
      infoSharingAlways: false,
      showCreditCardRegistration: false,
      intervalId: null,
      showPBox: false,
      pBoxClose: null,
      pBoxTitle: null,
      pBoxText: null,
      showPBoxCancelButton: null,
      pBoxCancelButtonText: null,
      showPBoxConfirmButton: null,
      pBoxConfirmButtonText: null,
      showCheckboxPBox: null,
      pBoxCheckBoxText: null,
      showPBoxSpinner: null,
      pBoxCheckBoxValue: null,
      pBoxButtonConfirmation: null,
      pBoxButtonCancel: null,
      pBoxSecondsToClose: null,
      editCableType: true,
      editCreditCardSelection: true,
      editTokenSelection: true,
      showPriceTable: false,
      pBoxShowLink: false,
      showEvaluationTags: false,
      pointName: null,
      pointId: null,
      isFirstRequest: true,
      showSpinner: false,
      showCreditCardDeleteBox: false,
      showBigBoxComponent: true,
      bigBoxComponentList: BIG_BOX_COMPONENT_LIST,
      miniBoxComponentList: MINI_BOX_COMPONENT_LIST,
      selectedBigBoxComponent: null,
      showMiniBoxComponentList: [],
      mapArrowDown: true,
      showDemonstration: false,
      totalSlots: 0,
      selectedSlot: null,
      fingerPrintUUID: null,
      showManagePayment: false,
      pixPayed: false, // para identificar se o pix já foi pago,
      allowEdit: true,
      persistentData: null,
      lastTransactionStatus: null,
      campaignOptInStatus: false,
      showRatingTransaction: false,
      showLastTransactionInfo: false,
      showBackPaymentButton: true,
    }
  },
  unmounted() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  },
  beforeRouteLeave(to) {
    if (!['/history', '/maps', '/home'].includes(to.path)) {
      return
    }
    this.persistentData = {
      token: this.token,
      pointName: this.pointName,
      cableType: this.cableType,
      transactionId: this.transactionId,
    }
    localStorage.setItem('persistentData', JSON.stringify(this.persistentData))
  },
  created() {
    Object.keys(rentProcessingMethods).forEach(method => {
      this[method] = rentProcessingMethods[method].bind(this)
    })
  },
  async mounted() {
    let campaign = null
    let user = null

    try {
      campaign = sessionStorage.getItem('campaign')
      this.token = localStorage.getItem('terminal_token') || ''
      this.pointName = localStorage.getItem('point_name')
      localStorage.removeItem('point_id')
    } catch (error) {
      Sentry.captureException(`Fail to access storage: ${error}`)
    }

    if (campaign) {
      this.campaign = campaign
    }

    if (this.$route.query.closeMap === 'close') {
      this.mapArrowDown = true
    }

    try {
      this.fingerPrintUUID = await fingerPrint()
      sendDeviceInfo(this.fingerPrintUUID)
    } catch (error) {
      console.error('Failed to get fingerprint:', error)
    }

    try {
      this.position = await getLocation()
    } catch (error) {
      console.error('Failed to get location:', error)
    }

    try {
      user = await getUser() //verificar se funciona
      this.user = user
      if (this.user.pix_price) {
        this.pixPrice = `${user.pix_price}`
      }
    } catch (error) {
      console.error('Failed to get user:', error)
      return
    }

    if (!user) return

    const device = this.user.devices.find((device) => device.uuid === this.fingerPrintUUID)
    if (device && device.cable_type) {
      this.cableType = device.cable_type
    }

    try {
      const identify = new amplitude.Identify()
      identify.set('email', user.email)
      identify.set('time_zone', 'America/Sao_Paulo')
      amplitude.identify(identify)
      amplitude.setUserId(user.email)
    } catch (error) {
      console.error('Failed to identify user:', error)
    }

    let OneSignalDeferred = []
    try {
      OneSignalDeferred.push(() => setupOneSignal(this.user.email))
    } catch (error) {
      console.error('Failed to setup OneSignal:', error)
      Sentry.captureException(`OneSignal setup failed: ${error}`)
    }

    this.loadPersistentDataFromRouting()
    this.verifyOpenPixPayment(user)

    try {
      if (this.position && this.position.lng && this.position.lat) {
        const point = await getNearPointInARange(this.position)
        const FIRST_ELEMENT = 0
        if (point && point[FIRST_ELEMENT]) {
          sessionStorage.setItem('point_id_geolocation', point[FIRST_ELEMENT].id)
        }
      }

      if (this.fingerPrintUUID) {
        saveBatteryStatus(user, this.position, this.fingerPrintUUID, this.cableType)
      }

      this.lastTransaction = await getLastTransaction()
      this.lastTransactionStatus = this.lastTransaction?.status

      if (this.lastTransaction.batteryReturned) {
        this.showLastTransactionInfo = true
        this.allowEdit = false
        this.handleShowRatingTransaction()
      }

      const POOLING_INTERVAL_TRANSACTION = 60000
      this.interval =
        setInterval(async () => {
          let lastTransaction = null
          try {
            lastTransaction = await getLastTransaction()
          } catch (error) {
            handleError('Failed to get last transaction:', error)
          }
          if (
            lastTransaction === null ||
            lastTransaction === undefined ||
            lastTransaction === '' ||
            lastTransaction === null || lastTransaction === undefined || typeof lastTransaction !== 'object'
          ) {
            this.interval = null
            return
          }
          this.lastTransaction = lastTransaction
          this.lastTransactionStatus = this.lastTransaction.status
        }, POOLING_INTERVAL_TRANSACTION) || null

      await this.getPointId()
    } catch (error) {
      Sentry.captureException(error)
      console.error('Failed during final data load:', error)
    }

    this.selectBigBoxComponent()
  },
  watch: {
    showPBox(newValue) {
      this.editCableType = !newValue
      this.editCreditCardSelection = !newValue
    },
    lastTransactionStatus(newValue, oldValue) {
      if (oldValue === TAKE_BATTERY_SUCCESS && RETURN_SUCCESS_LIST.includes(newValue)) {
        console.log('Returned battery')
        this.showLastTransactionInfo = true
        this.allowEdit = false
        this.handleShowRatingTransaction()
      }
      if (newValue === TAKE_BATTERY_SUCCESS) {
        this.showPBox = false
      }
    },
    cableType(newValue) {
      this.cableType = newValue
      if (this.user.devices.find(device => device.uuid === this.fingerPrintUUID) && this.fingerPrintUUID && this.cableType) {
        saveBatteryStatus(this.user, this.position, this.fingerPrintUUID, this.cableType)
      }
    }
  },

  methods: {
    getPropsBigComponents(component) {
      return getPropsBigComponents.call(this, component)
    },
    getEventsBigComponents(component) {
      return getEventsBigComponents.call(this, component)
    },
    getPropsMiniComponents(component) {
      return getPropsMiniComponents.call(this, component)
    },
    getEventsMiniComponents(component) {
      return getEventsMiniComponents.call(this, component)
    },
    async getPointId() {
      let point = null
      let pointId = null
      if (this.token.length >= TOKEN_LENGTH) {
        point = await getPointByToken(this.token)
        pointId = point.id
      } else {
        point = getPointIdFromStorage()
        if (point.id) {
          pointId = point.id
        }
      }
      this.pointId = pointId
    },
    addToMiniBoxComponentList(component) {
      const index = this.showMiniBoxComponentList.findIndex(c => c.reference === component)

      if (index === NO_POSITION) {
        const componentToAdd = this.miniBoxComponentList.find(c => c.reference === component)
        this.showMiniBoxComponentList.unshift(componentToAdd)
      }
    },
    removeFromMiniBoxComponentList(component) {
      const index = this.showMiniBoxComponentList.findIndex(c => c.reference === component)

      if (index !== NO_POSITION) {
        this.showMiniBoxComponentList.splice(index, ITEMS_TO_REMOVE)
      }
    },
    addOrRemoveToMiniBoxComponentList(component) {
      const index = this.showMiniBoxComponentList.findIndex(c => c.reference === component)

      if (index !== NO_POSITION) {
        this.showMiniBoxComponentList.splice(index, ITEMS_TO_REMOVE)
      } else {
        const componentToAdd = this.miniBoxComponentList.find(c => c.reference === component)
        this.showMiniBoxComponentList.unshift(componentToAdd)
      }
    },
    findBigBoxComponent(component) {
      return this.bigBoxComponentList.find(bigBoxComponent => bigBoxComponent.reference === component)
    },
    selectMiniBoxComponent() {
      if (this.cableType !== null && this.cableType !== undefined) {
        this.addToMiniBoxComponentList(CABLE_TYPE)
      } else {
        this.removeFromMiniBoxComponentList(CABLE_TYPE)
      }

      if (this.token && this.pointName && this.transactionId) {
        this.addToMiniBoxComponentList(TOKEN)
      } else {
        this.removeFromMiniBoxComponentList(TOKEN)
      }
    },
    async selectBigBoxComponent(component = null) {

      switch (component) {
        case SELECT_PAYMENT_METHOD:
          this.selectPaymentMethod()
          return
        case SELECT_CABLE_TYPE:
          this.selectCableType()
          return
        case TOKEN_SELECTION:
          this.selectTokenSelection()
          return
        case PIX_REGISTRATION:
          this.selectPixRegistration()
          return
        case CREDIT_CARD_PAYMENT:
          this.selectCreditCardRegistration()
          return
        default:
          null
      }

      this.token = localStorage.getItem('terminal_token') || ''
      const cableTypeSelected = this.cableType !== null && this.cableType !== undefined
      const noToken = !this.token || this.token?.length < TOKEN_LENGTH
      const paymentCreditCard = this.user.default_card
      const paymentNotApplied = !this.user.is_user_on_demand
      const paymentPix = this.user.pix_identification && this.pixPayed
      const tokenSelected = this.token && this.token.length >= TOKEN_LENGTH && this.pointName
      const transactionId = this.transactionId
      const payment = paymentCreditCard || paymentNotApplied || paymentPix
      // Não mostrar botão voltar se cadastro de pagamento abrir no fluxo
      this.showBackPaymentButton = true

      this.selectMiniBoxComponent()

      // bloquear o fluxo até que o último aluguel seja avaliado
      if (this.showRatingTransaction) {
        return
      }

      if (!cableTypeSelected) {
        this.selectCableType()
        return
      }

      if (!this.user.default_card && !this.user.pix_identification && this.withPaymentScreen) {
        this.selectCreditCardRegistration()
        return
      }

      if (!this.user.default_card && !this.user.pix_identification && !this.withPaymentScreen) {
        this.selectPaymentMethod()
        return
      }

      if (
        cableTypeSelected &&
        !this.transactionId
      ) {
        this.selectTokenSelection()
        return
      }

      if (!noToken && this.pointName) {
        saveBatteryStatus(this.user, this.position, this.fingerPrintUUID, this.cableType)
      }

      if (
        !this.pixPayed &&
        this.transactionId &&
        this.user.pix_identification &&
        !this.user.default_card &&
        this.pointId
      ) {
        this.selectPixPayment()
        return
      }

      if (payment && cableTypeSelected && tokenSelected && transactionId && this.pointId && paymentPix) {
        this.selectRentProcessingSelection()
        return
      }

      if (payment && cableTypeSelected && tokenSelected && transactionId && this.pointId) {
        this.selectCreditCardPayment()
        return
      }

      return

    },
    selectCableType() {
      this.allowEdit = true
      this.selectedBigBoxComponent = this.findBigBoxComponent(SELECT_CABLE_TYPE)
    },
    selectPaymentMethod() {
      this.allowEdit = true
      this.showBackPaymentButton = false
      if (!this.withPaymentScreen) {
        this.selectedBigBoxComponent = this.findBigBoxComponent(SELECT_PAYMENT_METHOD)
      }
    },
    selectCreditCardRegistration() {
      this.allowEdit = true
      this.showBackPaymentButton = false
      this.selectedBigBoxComponent = this.findBigBoxComponent(CREDIT_CARD_REGISTRATION)
    },
    selectTokenSelection() {
      this.editTokenSelection = true,
        this.allowEdit = true
      this.selectedBigBoxComponent = this.findBigBoxComponent(TOKEN_SELECTION)
    },
    selectPixPayment() {
      this.allowEdit = false
      this.selectedBigBoxComponent = this.findBigBoxComponent(PIX_PAYMENT)
    },
    selectPixRegistration() {
      this.allowEdit = true
      this.selectedBigBoxComponent = this.findBigBoxComponent(PIX_REGISTRATION)
    },
    selectCreditCardPayment() {
      this.allowEdit = false
      this.selectedBigBoxComponent = this.findBigBoxComponent(CREDIT_CARD_PAYMENT)
    },
    selectRentProcessingSelection() {
      this.allowEdit = false
      this.pixPayed = false
      this.selectedBigBoxComponent = null
      this.rentProcessingConfirmation()
      return
    },



    handleCloseLastTransactionInfo() {
      this.showLastTransactionInfo = false
    },
    handleShowRatingTransaction() {
      if (this.showRatingTransaction) {
        this.allowEdit = true
        localStorage.removeItem('lastTransaction')
      }
      this.showRatingTransaction = !this.showRatingTransaction
      this.selectBigBoxComponent()
    },
    handleClosePBox() {
      this.showPBox = false
      this.selectBigBoxComponent()
    },
    loadPersistentDataFromRouting() {
      let persistentData = localStorage.getItem('persistentData')
      localStorage.removeItem('persistentData')
      persistentData = persistentData ? JSON.parse(persistentData) : null
      if (persistentData) {
        this.token = persistentData.token
        this.pointName = persistentData.pointName
        this.cableType = persistentData.cableType
        this.transactionId = persistentData.transactionId
      }
    },
    verifyOpenPixPayment(user) {
      const isLastTransactionWaitingForPixPayment = Boolean(
        user.waiting_for_pix_payment &&
        'payed' in user.waiting_for_pix_payment &&
        [PIX_PAYED, PIX_PENDING].includes(user.waiting_for_pix_payment.payed) &&
        user.waiting_for_pix_payment.transaction_id &&
        user.waiting_for_pix_payment.token &&
        user.waiting_for_pix_payment.point_name &&
        user.waiting_for_pix_payment.cable_type !== null &&
        user.waiting_for_pix_payment.cable_type !== undefined
      )
      if (isLastTransactionWaitingForPixPayment) {
        // TODO: verificar de validade do pix em relação a data de criação
        const openPixTransaction = user.waiting_for_pix_payment
        this.pixPayed = openPixTransaction.payed == PIX_PAYED ? true : false
        this.transactionId = openPixTransaction.transaction_id
        this.token = openPixTransaction.token
        this.pointName = openPixTransaction.point_name
        this.cableType = openPixTransaction.cable_type
      }
    },
    async scrollToPriceTable(onlyClose = false) {
      if (onlyClose) {
        this.showPriceTable = false
        const element = this.$refs[PRICE_TABLE]
        if (element) {
          this.$scrollTo(element, SCROLL_TIME, {
            easing: 'ease'
          })
        }
        return
      }

      if (!this.showPriceTable) {
        await this.getPointId()
        let params = {}
        if (this.user.id) {
          params['user_id'] = this.user.id
        }
        if (this.transactionId) {
          params['transaction_id'] = this.transactionId
        }
        params['link_source'] = 'home'

        sessionRecorder.addUserEvent(
          new UserEvent(GTAG_PRICE_TABLE, this.transactionId, this.pointId)
        )
        sendGtag(GTAG_PRICE_TABLE, params, true)

        if (!this.pointId) {
          await this.getPointId()
        }
      } else {
        this.$scrollTo(0, SCROLL_TIME, {
          easing: 'ease'
        })
      }

      this.showPriceTable = !this.showPriceTable
      const element = this.$refs[PRICE_TABLE]
      if (element) {
        this.$scrollTo(element, SCROLL_TIME, {
          easing: 'ease'
        })
      }
    },
    scrollToRef(boxRef) {
      const element = this.$refs[boxRef]
      if (element) {
        this.$scrollTo(element, SCROLL_TIME, {
          easing: 'ease',
          offset: 200
        })
      } else {
        const SCROLL_POSITION = 0
        this.$scrollTo(SCROLL_POSITION, SCROLL_TIME, {
          easing: 'ease'
        })
      }
    },
    padTo2Digits(num) {
      const PADDING = 2
      return num.toString().padStart(PADDING, '0')
    },
    takeBattery: async function () {
      try {
        const response = await this.takeValidatedBattery()
        this.showPBox = false

        if (response && response.data.success) {
          this.selectedSlot = response.data.selected_slot
          this.totalSlots = response.data.terminal.total_slots
          this.showDemonstration = true
          this.transactionId = response.data.transaction_id
          await this.getTransactionStatus(this.transactionId)
          return
        } else {
          this.showDemonstration = false
          if (response && response.data && response.data.error) {
            if (response.data.status && response.data.status === PAYMENT_TIMEOUTED) {
              this.rentProcessingError(': ' + response.data.error)
              return
            }
            this.rentProcessingError(': ' + response.data.error)
            return
          } else {
            this.rentProcessingError(': ' + 'Falha ao retirar bateria')
            return
          }
        }
      } catch (error) {
        this.showDemonstration = false
        this.rentProcessingError(': ' + 'Falha ao retirar bateria')
        this.handleError(error)
      } finally {
        this.transactionId = null
      }
    },
    async takeValidatedBattery() {

      const params = {
        transaction_id: this.transactionId,
        uuid: this.UUID,
        cable_type: this.cableType,
        payment_method: this.paymentMethod()
      }


      try {
        const url = 'transaction/validation/take_battery'
        return await API.post(url, params)
      } catch (error) {
        handleError(error)
      }
    },

    async saveInvalidTransaction(
      cancelReason,
      transactionId = null,
      tokenS = null,
    ) {
      const MAX_CANCEL_REASON_LENGTH = 400
      const START_INDEX = 0
      let params = {
        token: this.token ? this.token : tokenS,
        cancel_reason:
          cancelReason.length > MAX_CANCEL_REASON_LENGTH
            ? cancelReason.substring(START_INDEX, MAX_CANCEL_REASON_LENGTH)
            : cancelReason,
        cable_type: this.cableType
      }

      params.transaction_id = transactionId || this.transactionId

      try {
        let endpoint = 'transaction/invalid'
        return await API.post(endpoint, params)
      } catch (error) {
        console.log(error)
      } finally {
        this.transactionId = null
      }
    },

    getTransactionStatus: async function (id) {
      try {
        const response = await API.get('transaction/status/' + id)

        if (!response || (response && !response.data)) {
          this.rentProcessingError(': Falha ao processar a retirada')
          return
        }

        if (response.data.timeouted) {
          this.showEvaluationTags = true
          this.showDemonstration = false
          return
        }

        if (!response.data.success && response.data.error) {
          this.rentProcessingError(': ' + response.data.error)
          return
        }
        const POOLING_INTERVAL_STATUS = 3000
        if (response.data.status === TAKE_BATTERY_SUCCESS) {
          this.showDemonstration = false
          this.rentProcessingSuccess(this.$t('home.return.success_2'))
          let params = {}
          if (this.user) {
            params['user_id'] = this.user.id
          }
          if (this.transactionId) {
            params['transaction_id'] = this.transactionId
          }
          sessionRecorder.addUserEvent(
            new UserEvent(GTAG_TAKE_BATTERY_SUCCESS, this.transactionId, this.pointId)
          )
          sendGtag(GTAG_TAKE_BATTERY_SUCCESS, params)
          return
        } else if (response.data.status === TAKE_BATTERY_ERROR) {
          this.rentProcessingError(': Falha ao processar a retirada')
          this.showDemonstration = false
          return
        } else if (response.data.status === 'firmware_communication_error') {
          this.rentProcessingError(': Ocorreu um erro ao tentar se comunicar com o terminal')
          this.showDemonstration = false
        } else {
          setTimeout(async () => {
            await this.getTransactionStatus(id)
          }, POOLING_INTERVAL_STATUS)
        }
      } catch (error) {
        console.log(error)
        this.rentProcessingError(': Falha ao processar a retirada')
        this.showDemonstration = false
        return
      }
    },
    async handleTakeBatterySuccess() {
      this.lastTransaction = await getLastTransaction()
      this.showPBox = false
      this.lastTransactionStatus = this.lastTransaction?.status
    },
    resetRentVariables: function () {
      this.terminal.campaign = false
      this.position = null
    },
    confirmRent() {
      this.resetRentVariables()

      const NO_CARD_LENGTH = 0
      if (this.campaign?.no_payment || this.user.credit_cards.length > NO_CARD_LENGTH || !this.user.is_user_on_demand) {
        this.verifyTerminal()
        return
      }
    },
    async verifyTerminal() {
      if (this.takeType !== 'terminal') {
        return this.campaignVerification()
      }

      if (!this.position) {
        try {
          const position = await getLocation()
          this.position = position
          console.log('geolocation: ', this.position)
        } catch (error) {
          console.error('Error on geolocation:', error)
        }
      }
      this.selectBigBoxComponent()
    },
    async completeVerifyTerminal() {
      let params = {
        token: this.token
      }
      try {
        if (this.position && this.position.lng && this.position.lat) {
          params['position'] = this.position
        }
        const response = await API.post('transaction/check_terminal', params)
        this.terminal.campaign = response.data.campaign
        this.terminal.noPayment = response.data.no_payment
        this.terminal.optIn = response.data.need_opt_in
      } catch (error) {
        handleError(error)
        this.rentProcessingError(': Falha ao verificar terminal')
      }
    },

    async sendPermission() {
      await this.completeVerifyTerminal()

      this.selectedBigBoxComponent = null
      this.rentProcessingVerifyTerminal()


      let params = {
        transaction_permission_granted: CREDIT_CARD_RESERVATION_ALLOW,
        transaction_id: this.transactionId
      }


      if (
        (this.terminal.campaign &&
          !this.terminal.noPayment &&
          this.campaignOptInStatus &&
          this.terminal.optIn) ||
        !this.terminal.optIn
      ) {
        params['info_sharing_permission_granted'] = USER_INFO_SHARING_PERMISSION_GRANTED
      } else {
        params['info_sharing_permission_granted'] = USER_INFO_SHARING_PERMISSION_REFUSED
      }

      try {
        await API.post('transaction/permission', params)

        this.rentProcessing
        await this.takeBattery()
      } catch (error) {
        handleError(error)
        this.rentProcessingError(': Falha ao se comunicar com o servidor')
      }
    },
    paymentMethod() {
      if (this.user.default_card) {
        return CREDIT_CARD
      }
      if (this.user.pix_identification) {
        return PIX
      }
      return null
    },
    permissionTagManager(reservationPermission = true, freeTimePermission = true) {
      let params = {}
      if (this.user) {
        params['user_id'] = this.user.id
      }
      if (this.transactionId) {
        params['transaction_id'] = this.transactionId
      }
      if (this.position && this.position.lat && this.position.lng) {
        params['geolocation_permission'] = true
      } else {
        params['geolocation_permission'] = false
      }

      if (!reservationPermission) {
        params['reservation_permission'] = false
      } else if (!freeTimePermission) {
        params['reservation_permission'] = true
        params['free_time_permission'] = false
      } else {
        params['reservation_permission'] = true
        params['free_time_permission'] = true
      }
      return params
    }
  }
}
