<section
  id="login-back"
  class="inner-section"
>
  <img
    src="@/assets/images/logo.png"
    alt=""
    id="logo"
  />
  <h3
    class="subtitle text-uppercase"
    v-text="$t('login.title_rent')"
  ></h3>
  <div
    id="login-div"
    class="py-5 blue-background"
  >
    <div class="vue-template">
      <h3
        class="navbar-text"
        v-text="$t('login.sign_in')"
      ></h3>
      <div class="social-login">
        <GoogleLogin
          :button-config="{ size: 'large', width: '270'}"
          :callback="callback"
          one-tap="true"
          prompt
        />
        <HFaceBookLogin
          class="btn-facebook mb-3"
          v-slot="fbLogin"
          app-id="{{ import.meta.env.VITE_FACEBOOK_ID }}"
          @onSuccess="onFacebookSignInSuccess"
          @onFailure="onFacebookError"
          scope="email,public_profile"
          fields="id,name,email"
        >
          <div class="floating-box inline-block">
            <img
              alt="facebook"
              class="btn-facebook-img"
              :src="fb_icon"
            />
          </div>
          <div class="floating-box inline-block-sb">
            <span
              @click="fbLogin.initFBLogin"
              id="facebook_registration_button"
              class="btn-facebook-text"
            >
              {{ $t('login.facebook_btn_text') }}
            </span>
          </div>
        </HFaceBookLogin>
      </div>
    </div>
    <div
      class="clearfix"
      id="account-options-email"
    >
      <p
        id="sign_up_link"
        class="register-account text-center mt-3"
      >
        <router-link
          to="/sign-up"
          v-text="$t('login.register_with_email')"
        ></router-link>
      </p>

      <div
        class="clearfix"
        id="account-options-code"
      >
        <p class="register-account text-center mt-0 mb-2">
          <span>
            <span v-text="$t('login.login_with_code')"></span>
            <router-link
              to="/code-validation"
              v-text="$t('login.click_here')"
            ></router-link>
          </span>
        </p>
      </div>
    </div>
  </div>

  <div class="link-text">
    <a
      href="#"
      class="no-underline"
      v-on:click.prevent="handleShowPriceTableModal(true)"
    >
      <span>
        <span
          class="link-map"
          v-text="$t('home.take.price_table')"
        ></span>
        <font-awesome-icon
          class="btn-icon-map margin-5 color-white"
          icon="fa-solid fa-money-bill-alt"
          size="lg"
        />
      </span>
    </a>
  </div>

  <div class="link-text">
    <router-link
      :to="{ path: `maps` }"
      class="no-underline"
    >
      <span class="">
        <span
          class="link-map"
          v-text="$t('login.find_us')"
        ></span>
        <font-awesome-icon
          class="btn-icon-map color-white"
          icon="fa-solid fa-map-location-dot"
        />
      </span>
    </router-link>
  </div>

  <PriceTableModal
    v-if="showPriceTableModal"
    :showPriceTableModal="showPriceTableModal"
    @hidePriceTableModal="handleShowPriceTableModal(false)"
  ></PriceTableModal>

  <AppVersion class="app-version" />
</section>
