import handleError from '@/services/handleError'
import API from '@/services/api'
import { toRaw } from 'vue'

export default async function deleteCard(user, cardId) {
  if (
    user.credit_cards.filter((card) => card.id === cardId).length === 0
  ) {
    const error = 'Default card not find for credit card delete operation'
    handleError(error)
    return
  }

  try {
    const response = await API.delete('credit_card/register/' + cardId)
    let modifiedUser = user
    if (response && response.data && response.data.success) {
      if (user && user.credit_cards) {
        modifiedUser.credit_cards = user.credit_cards.filter((card) => card.id !== cardId)
        if (user.default_card && user.default_card.id === cardId) {
          modifiedUser.default_card = null
        }
      } else {
        console.error("User or user's credit cards are not defined correctly.")
      }
      return toRaw(modifiedUser)
    } else {
      handleError('Error to delete card')
    }
  } catch (error) {
    handleError(error)
  }
}