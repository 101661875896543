<template>
  <div
    v-if="showDefaultTile"
    class="select-card d-flex flex-column"
    :class="{'border-danger' : errorValidation}"
  >
    <!-- Tile Title -->
    <h3
      v-if="title"
      class="page-title text-center pt-2"
    >
      {{ title }}
    </h3>

    <!-- Optional Text -->
    <div class="flex-grow-1 d-flex">
      <p
        class="page-text text-left my-2 mx-2"
      >
        <slot name="text" />
      </p>
    </div>

    <!-- Optional Link -->
    <p
      v-if="showLink"
      id="warning-price-tabela"
      style="margin: auto; text-align: center"
      @click="handleOpenTable"
      v-text="$t('home.return.price_table')"
    />

    <!-- Optional Checkbox -->
    <div class="d-flex">
      <div
        style="width: 5%;"
        class="d-flex "
      />
      <div
        style="width: 95%;"
        class="d-flex my-2"
      >
        <b-form-checkbox
          v-if="showCheckBox"
          class="auto mx-1"
          :checked="checkBoxInitValue"
          @change="handleCheckBox"
        >
          <span v-text="checkBoxText" />
        </b-form-checkbox>
      </div>  
    </div>

    <!-- Optional Spinner -->
    <div
      v-if="showSpinner"
      class="d-flex justify-content-center m-2"
    >
      <b-spinner id="loading-spinner" />
    </div>

    <!-- Action Buttons -->
    <div
      class="tile-actions d-flex justify-content-end"
      :class="{ 'mb-4': errorValidation }"
    >
      <BButton
        v-if="showCancelButton"
        class="cancel-button my-2"
        variant="secondary"
        @click="cancelAction"
      >
        {{ cancelButtonText }}
      </BButton>
      <BButton
        v-if="showConfirmButton"
        class="button my-2"
        variant="primary"
        @click="confirmAction"
      >
        {{ confirmButtonText }}
      </BButton>
    </div>
    <BRow v-if="errorValidation">
      <BCol>
        <div class="message-fail">
          <h5>{{ messageErrorValidation }}</h5>
        </div>
      </BCol>
    </BRow>
  </div>
</template>

<script>
import '@/main.scss'

export default {
  name: 'DefaultTile',
  props: {
    showDefaultTile: Boolean,
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    showCancelButton: {
      type: Boolean,
      default: true
    },
    cancelButtonText: {
      type: String,
      default: 'Cancel'
    },
    showConfirmButton: {
      type: Boolean,
      default: true
    },
    confirmButtonText: {
      type: String,
      default: 'Confirm'
    },
    showSpinner: {
      type: Boolean,
      default: false
    },
    showCheckBox: {
      type: Boolean,
      default: false
    },
    checkBoxText: {
      type: String,
      default: ''
    },
    secondsToClose: {
      type: Number,
      default: 0
    },
    showLink: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'cancel',
    'confirm',
    'close',
    'handleOpenTable',
    'checkBoxValue'
  ],
  data: function () {
    return {
      checkBoxInitValue: false,
      errorValidation: false,
      messageErrorValidation: ''
    }
  },
  watch: {
    secondsToClose(newVal) {
      if (newVal && newVal > 0) {
        this.timeoutToClose()
      }
    }
  },
  methods: {
    handleOpenTable() {
      this.$emit('handleOpenTable')
    },
    cancelAction() {
      this.$emit('cancel')
    },
    confirmAction() {
      this.$emit('confirm')
    },
    handleCheckBox() {
      this.$emit('checkBoxValue', !this.checkBoxInitValue)
    },
    timeoutToClose() {
      if (this.secondsToClose && this.secondsToClose > 0) {
        setTimeout(() => {
          this.$emit('close')
        }, this.secondsToClose * 1000)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@use '@/main';

.tile-actions {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.flex-grow-1 {
  flex-grow: 1;
  display: flex;
  align-items: left;
  justify-content: center;
}

.page-text {
  text-align: justify;
  margin: 0;
  max-width: 90%;
}

#loading-spinner {
  display: block;
  color: #f58a48;
}

.message-fail {
  width: 100%;
  padding: 0.2rem 1rem;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: left;
  line-height: 1;
  box-sizing: border-box;
  margin-top: 0.5rem;
  max-height: 2rem;
  overflow: hidden;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: red;
}
</style>
