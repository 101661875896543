import { v4 as uuidv4 } from 'uuid'
import API from '@/services/api'

class ClickTracker {
  constructor() {
    this.clickEvents = []
    this.handleClick = this.handleClick.bind(this)
  }

  start() {
    document.addEventListener('click', this.handleClick)
  }

  stop() {
    document.removeEventListener('click', this.handleClick)
  }

  handleClick(event) {
    const target = event.target
    const parentElement = target.parentElement
    let text = target.textContent.trim()

    if (target.tagName === 'input') {
      text = '***'
    }

    let id = target.id || null
    if (id !== '' && id !== null) {
      id = '#' + id
    }

    const clickData = {
      id: id,
      text: text,
      tag: `<${target.tagName.toLowerCase()}>`,
      parent: this.getElementText(parentElement, text),
      datetime: new Date().toISOString(),
      position: {
        x: event.clientX,
        y: event.clientY
      }
    }

    this.clickEvents.push(clickData)
  }

  getElementText(element, compareText) {
    let currentElement = element
    let depth = 0
    while (currentElement && depth < 10) {
      const dataText = currentElement.getAttribute('data-text') || currentElement.textContent.trim()
      if (dataText !== '' && dataText !== compareText) {
        return {
          text: dataText.trim(),
          tag: `<${currentElement.tagName.toLowerCase()}>`,
          depth: depth
        }
      }
      currentElement = currentElement.parentElement
      depth++
    }
    return ''
  }

  getClicks() {
    return this.clickEvents
  }

  clearClicks() {
    this.clickEvents = []
  }
}

class SessionRecorder {
  constructor() {
    if (!SessionRecorder.instance) {
      this.sessionId = null
      this.tracker = new ClickTracker()
      this.sendInterval = null
      this.userEvents = []
      this.hasClosedSessionEvent = false
      this.stopRecording = null
      SessionRecorder.instance = this
    }
    return SessionRecorder.instance
  }

  startSession() {
    this.sessionId = this.sessionId || sessionStorage.getItem('session_id') || uuidv4()
    sessionStorage.setItem('session_id', this.sessionId)
    console.log('Session ID:', this.sessionId)
  }

  async sendEventToBackend() {
    try {
      const payload = {
        session_id: this.sessionId,
        click_events: this.tracker.getClicks(),
        events: this.userEvents
      }

      if (payload.click_events.length === 0 && payload.events.length === 0) {
        return
      }

      await API.post('/customer/session', payload)

      this.tracker.clearClicks()
      this.userEvents = []
      return this
    } catch (error) {
      console.error('Error preparing session data:', error)
    }
  }

  handleBeforeUnload() {
    if (!this.hasClosedSessionEvent) {
      this.hasClosedSessionEvent = true
      this.addUserEvent(new UserEvent('close_session'))
    }
    this.sendEventToBackend()
  }

  async startRecording() {
    console.log('Recording started')
    this.tracker.start()
    this.sendInterval = setInterval(() => this.sendEventToBackend(), 3000)
    window.addEventListener('beforeunload', () => this.handleBeforeUnload())
    await this.sendEventToBackend()
  }

  stopRecordingSession() {
    if (this.stopRecording) {
      this.tracker.stop()
      this.stopRecording = null
    }
    window.removeEventListener('beforeunload', () => this.handleBeforeUnload())
    this.sessionId = null
    sessionStorage.removeItem('session_id')
    clearInterval(this.sendInterval)
  }

  addUserEvent(userEvent) {
    this.userEvents.push(userEvent)
  }
}

class UserEvent {
  static transaction_id = null
  static point_id = null
  static last_transaction_id = null
  static last_point_id = null

  constructor(event, transaction_id = null, point_id = null, extra = null) {
    const transactionChanged =
      transaction_id !== null && transaction_id !== UserEvent.transaction_id
    const pointChanged = point_id !== null && point_id !== UserEvent.point_id

    if (transactionChanged) {
      UserEvent.last_transaction_id = UserEvent.transaction_id
      UserEvent.transaction_id = transaction_id
    }

    if (pointChanged) {
      UserEvent.last_point_id = UserEvent.point_id
      UserEvent.point_id = point_id
    }

    this.transaction_id = UserEvent.transaction_id
    this.point_id = UserEvent.point_id
    this.event = event
    this.datetime = new Date().toISOString()

    if (extra !== null) {
      this.extra = extra
    }
  }
}

const sessionRecorder = new SessionRecorder()

export { sessionRecorder, UserEvent }
