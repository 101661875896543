
import axios from 'axios'

class Experiments {
    constructor() {
        if (!Experiments.instance) {
            this.experiments = null
            Experiments.instance = this
        }
        return Experiments.instance
    }

    async fetch(sessionId) {
        if (!this.experiments) {
            try {
                console.log('Fetching experiments')
                const response = await axios.get(import.meta.env.VITE_MOBILE_API + 'customer/experiments/' + sessionId)
                this.experiments = response.data.experiments
                console.log(this.experiments)
            } catch (error) {
                console.error('Error on search experiments:', error)
                this.experiments = {}
            }
        }
        return this.experiments
    }

    isExperimentEnabled(experimentName, expectedPossibility) {
        if (this.experiments === null) {
            console.warn('Experiments not loaded')
            return false
        }
        const experiment = this.experiments.find(exp => exp.name === experimentName)
        if (!experiment) {
            console.warn(`Experiment "${experimentName}" not found.`)
            return false
        }
        return experiment.possibility === expectedPossibility
    }

}

const instance = new Experiments()

export const experimentInstance = instance
