import axios from 'axios'
import API from '@/services/api'
import * as Sentry from '@sentry/vue'
import { VALIDATION_CODE_LENGTH } from '@/constants.js'
import sendGtag from '@/services/sendGtags'
import { sessionRecorder, UserEvent } from '@/services/sessionRecorder'

const GTAG_LOGIN_ACTIVE_MAIL = 'login_activate_mail'

export default {
  name: 'code-validation',
  props: [],
  data: function () {
    return {
      error: null,
      code: new Array(VALIDATION_CODE_LENGTH).fill('')
    }
  },
  computed: {},
  mounted: function () { },
  methods: {
    onInput(index, event) {
      if (!event) {
        return
      }

      if (event.target.value.length === 1) {
        if (index === VALIDATION_CODE_LENGTH - 1) {
          this.$refs.inputs[0].focus()
        } else {
          this.$refs.inputs[index + 1].focus()
        }
      } else {
        this.spreadString(event.target.value, index)
      }
    },
    onKeyDown(index, event) {
      if (event && event.keyCode === 8 && index > 0 && event.target.value === '') {
        this.code[index] = ''
        this.$refs.inputs[index - 1].focus()
      }

      if (
        event &&
        event.keyCode === 46 &&
        index < this.code.length - 1 &&
        event.target.value === ''
      ) {
        this.code[index] = ''
        this.$refs.inputs[index + 1].focus()
      }
    },
    onFocus(event) {
      event && event.target && event.target.select()
    },
    onClick(event) {
      event && event.target && event.target.select()
      if (this.error) {
        this.error = null
      }
    },
    fullCode(code) {
      code.forEach((element) => element.trim())
      return code.every((item) => item !== '')
    },
    spreadString(str, index) {
      const flatStr = str.split('').flat()
      for (let i = 0; i < flatStr.length; i++) {
        const element = flatStr[i]

        if (index + i < VALIDATION_CODE_LENGTH) {
          this.code[index + i] = element
          this.$refs.inputs[index + i].focus()
        }
      }
    },
    removeFocus() {
      for (let index = 0; index < VALIDATION_CODE_LENGTH; index++) {
        this.$refs.inputs[index].blur()
      }
    },
    validateCode: async function () {
      if (this.code.length !== VALIDATION_CODE_LENGTH) {
        return console.log('passe o código')
      }
      const validationCode = this.code.join('')

      try {
        const response = await API.get(`/customer/validate_code/${validationCode}`)

        if (response.data.success) {
          sessionRecorder.addUserEvent(new UserEvent(GTAG_LOGIN_ACTIVE_MAIL))
          sendGtag(GTAG_LOGIN_ACTIVE_MAIL)
          const token = response.data.token

          localStorage.setItem('token', token)
          axios.defaults.headers.common['Authorization'] = token
          return this.$router.push('/home')
        }

        this.error = response.data.error
      } catch (error) {
        this.error = error
        Sentry.captureException(error)
      } finally {
        if (this.fullCode(this.code)) {
          this.code = new Array(VALIDATION_CODE_LENGTH).fill('')
        }
      }
    },
    routeSignUp: function () {
      this.$router.push('/sign-up')
    }
  },
  watch: {
    code(newValue) {
      if (this.fullCode(newValue)) {
        this.removeFocus()
      }
    }
  }
}
